/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import MediaPlayer from './frames/MediaPlayer'
import DialogRequestCall from './Dialog/DialogRequestCall'
import DialogInCall from './Dialog/DialogInCall'

const CallDetail = (props) => {
	const {
		history,
		showPreCall,
		showInCall,
		callParams,
		closePreCallScreen,
		closeInCallScreen,
		pickUpACall,
		microphones,
		endACall,
		remoteUsers,
		mute,
		unMute,
		join,
		joinState,
		socket,
		declineACall,
		pendingACall,
		resetCallParams,
		remainingTime
	} = props

	return (
		<>
			<DialogRequestCall
				show={showPreCall}
				pendingACall={pendingACall}
				microphones={microphones}
				closePreCallScreen={closePreCallScreen}
				pickUp={pickUpACall}
				declineACall={declineACall}
			/>
			{ showInCall ? (
				// {1 == 1 ? (
				<DialogInCall
					history={history}
					endACall={endACall}
					show={showInCall}
					remoteUsers={remoteUsers}
					closeInCallScreen={closeInCallScreen}
					mute={mute}
					unMute={unMute}
					callParams={callParams}
					join={join}
					joinState={joinState}
					socket={socket}
					resetCallParams={resetCallParams}
					remainingTime={remainingTime}
				/>
			) : null}
		</>
	)
}

export default CallDetail
