import {
	ANSWER_MANAGEMENT_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL,
	POST_TELLER_ANSWER,
	BEST_ANSWER_ADD,
	OPEN_WELCOME_ANSWER,
	CLOSE_WELCOME_ANSWER,
	ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2,
	ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//対応履歴取得
export const getAllQuestion = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(glasConfig.url_base + glasConfig.path_answer + params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(
						resultSuccess(ANSWER_MANAGEMENT_ANSWER_GET_ALL, res)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + ANSWER_MANAGEMENT_ANSWER_GET_ALL,
					error
				)
				throw error
			})
	}
}

export const getAllQuestionV2 = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(glasConfig.url_base + glasConfig.path_answer_v2 + params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(
						resultSuccess(ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2, res)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + ANSWER_MANAGEMENT_ANSWER_GET_ALL,
					error
				)
				throw error
			})
	}
}

export const getAnswerLogsV2 = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(glasConfig.url_base + glasConfig.path_answer_v2 + params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						answerLogs: {
							data: response.data.data,
							page: page || 0,
						},
					}
					dispatch(
						resultSuccess(ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2, res)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2,
					error
				)
				throw error
			})
	}
}

export const getBestAnswers = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					'withmybestanswer' +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						bestAnswers: {
							data: response.data.data,
							page: page || 0,
						},
					}
					dispatch(
						resultSuccess(
							ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL,
							res
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL,
					error
				)
				throw error
			})
	}
}

export const getGoodAnswer = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_list_good_answer,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch({type:ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL,data:response.data.data})
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const getAnswerLogs = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					'withmyanswer/' +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						answerLogs: {
							data: response.data.data,
							page: page || 0,
						},
					}
					dispatch(
						resultSuccess(ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL, res)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL,
					error
				)
				throw error
			})
	}
}

export const postTellerAnswer = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		request
			.post(
				glasConfig.url_base + glasConfig.path_answer + 'answer',
				params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code == 0) {
					let res = {
						postAnswer: response.data.data,
					}
					dispatch(resultSuccess(POST_TELLER_ANSWER, res))
				}
			})
			.catch((error) => console.log(error))
	}
}

export const add = (numberUnreadBestAnswer, showPopUpBestAnswer) => {
	const res = {
		numberUnreadBestAnswer,
		showPopUpBestAnswer,
	}
	return (dispatch) => dispatch(resultSuccess(BEST_ANSWER_ADD, res))
}

export const openDialogWelComeAnswer = () => {
	const data = {
		isOpenWelComeAnswer: true,
	}
	return (dispatch) => {
		dispatch(resultSuccess(OPEN_WELCOME_ANSWER, data))
	}
}

export const closeDialogWelComeAnswer = () => {
	const data = {
		isOpenWelComeAnswer: false,
	}
	return (dispatch) => {
		dispatch(resultSuccess(CLOSE_WELCOME_ANSWER, data))
	}
}
