/**
 * 左メニューの対応中のユーザー一覧
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LeftRequestList from './LeftRequestList'
import LeftOffline from './LeftOffline'
import * as ChatRequestActions from '../actions/ChatRequestActions'

class LeftRequest extends Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { dispatch } = this.props
		if (localStorage.hasOwnProperty("draft-chat")) {
			let logChatDraft = JSON.parse(localStorage.getItem("draft-chat"))
			dispatch(ChatRequestActions.addChatDraft(logChatDraft))
		}
	}
	listUserListChat = (data, typeRequestChat = 'unread') => {
		const {
			chatUserId,
			history,
			openWarningFullChatDialog,
			closeNavLeft,
			MyProfile,
		} = this.props
		if (Object.keys(data).length === 0 && typeRequestChat === 'unread') {
		} else {
			return Object.values(data).map((res, i) => {
				return (
					<LeftRequestList
						key={`requestlist_${res.msgId}`}
						data={res}
						chatUserId={chatUserId}
						history={history}
						maxNumberChating={MyProfile.data.maxNumberUserChatting}
						openWarningFullChatDialog={(chatUserId) =>
							openWarningFullChatDialog(chatUserId)
						}
						no={i}
						numbers={Object.keys(data).length}
						closeNavLeft={closeNavLeft}
					/>
				)
			})
		}
	}

	
	renderRequestHistoryChat (requestUnRead, requestChat, ChatDraft) {
		let dataChat = requestChat.map((res, i) => {
			let chatDraftUser = ChatDraft.data.find((item) =>  {
				if (res.fromId == item.fromId || res.toId == item.fromId) {
					return item
				}
			})

			res.chatDraft = ""
			res.serverTimeChatDraft = res.serverTime
			
			if (chatDraftUser && (chatDraftUser.serverTime >= res.serverTime) ) {
				res.chatDraft = chatDraftUser.value
				if (chatDraftUser.value && chatDraftUser.serverTime >= res.serverTime) 
					res.serverTimeChatDraft = chatDraftUser.serverTime
			}

			return res
		})

		let dataChatDraft = dataChat.sort((a,b) => b.serverTimeChatDraft - a.serverTimeChatDraft)

		return [...requestUnRead, ...dataChatDraft]
	}

	render() {
		const {
			chatUserId,
			userNameOfflineRequest,
			RequestOffline,
			ChatRequestUnRead,
			ChatRequestRead,
			closeNavLeft,
			ChatDraft,
		} = this.props
		
		let requestUnRead = [];
		let requestRead = [];
		if (ChatRequestUnRead.data) {
			requestUnRead = Object.values(ChatRequestUnRead.data).map((res, i) => {
				res.check = "unread"
				return res
			})
		}

		if (ChatRequestRead.data) {
			requestRead = Object.values(ChatRequestRead.data).filter((res, i) => {
				if (Object.keys(ChatRequestUnRead.data).find(s=>s==res.fromId) === undefined) {
					res.check = "read"
					return res
				}
			})
		}

		let requestHistoryAll = this.renderRequestHistoryChat(requestUnRead, requestRead, ChatDraft)
		
		return (
			<div
				className="secondary-definition-wrap"
				style={style.listChatWrap}
			>
				<dl className="secondary-definition">
					<dt className="secondary-definition__title">
						<b>対応中</b>
					</dt>
					<dd className="secondary-definition__data">
						<div className="list column_1 accpet-user">
							{Object.keys(ChatRequestUnRead.data).length === 0 &&
							Object.keys(ChatRequestRead.data).length === 0 ? (
								<p className="color-content-secendary-nondata">
									対応中のお客様はいません。お客様を待ちましょう。
								</p>
							) : null}
							{this.listUserListChat(requestHistoryAll)}
							{/* {this.listUserListChat(requestHistoryUnRead)}
							<div></div>
							{this.listUserListChat(
								requestHistoryRead,
								'read'
							)} */}
						</div>
					</dd>
					<LeftOffline
						closeNavLeft={closeNavLeft}
						show={true}
						data={RequestOffline}
						chatUserId={chatUserId}
						userNameOfflineRequest={userNameOfflineRequest}
					/>
				</dl>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ChatRequestRead: state.ChatRequestRead,
		ChatRequestUnRead: state.ChatRequestUnRead,
		RequestOffline: state.RequestOffline,
		MyProfile: state.MyProfile,
		ChatDraft: state.ChatDraft
	}
}

export default connect(mapStateToProps)(LeftRequest)

const style = {
	listChatWrap: {
		height: window.innerHeight - 332,
	},
}
