/**
 * 通知ダイアログ
 *
 */
import React, { Component } from 'react'
import DialogChat from './DialogChat'
import DialogCall from './DialogCall'
import DialogAlert from './DialogAlert'
import DialogAgeAuth from './DialogAgeAuth'
import DialogRequirePhoneNumber from './DialogRequirePhoneNumber'

import DialogPointGift from './DialogPointGift'
import DialogChatRef from './Refs/DialogChatRef'
import DialogDetailAnswer from './ChatContent/ChatLogRightMenu/DialogDetailAnswer'

export default class DialogNotifi extends Component {
	static dialog

	constructor(props) {
		super(props)
		this.state = {
			kind: '',
			show: false,
			data: {},
			modal: false,
		}
		DialogChatRef.setModal(this)
	}

	static setDialog(dialog) {
		this.dialog = dialog
	}

	static getDialog() {
		return this.dialog
	}

	handleOpen(kind, data, modal = false) {
		// if(this.state.data.msgId && this.state.data.msgId == data.msgId) {
		//   return;
		// }
		if (kind == 'CHAT') {
			DialogChatRef.setShowModal(true)
		}

		this.setState({
			kind: kind,
			show: true,
			data: { ...data },
			modal: modal,
		})
	}

	handleClose() {
		this.setState(
			{
				kind: '',
				show: false,
				modal: false,
			},
			() => {
				DialogChatRef.setShowModal(false)
			}
		)
	}

	/**
	 * modal: Dialogのmodalに渡すboolan型の値。trueだと背景クリックで非表示にできないようになります。（現在：DialogChatのみ有効）
	 */
	render() {
		const kind = this.state.kind
		const data = this.state.data
		const modal = this.state.modal

		switch (kind) {
			case 'CHAT': {
				return (
					<DialogChat
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'CALL': {
				return (
					<DialogCall
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'ALERT': {
				return (
					<DialogAlert
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'REQUIRE_PHONE_NUMBER': {
				return (
					<DialogRequirePhoneNumber
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'AGEAUTH': {
				return (
					<DialogAgeAuth
						show={this.state.show}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'GIFT': {
				return (
					<DialogPointGift
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			case 'DETAIL_ANSWER': {
				return (
					<DialogDetailAnswer
						show={this.state.show}
						data={data}
						closeFunc={() => this.handleClose()}
					/>
				)
			}
			default: {
				return ''
			}
		}
	}
}
