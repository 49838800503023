import moment from 'moment'
import { TEXT_CHAT_DRAFT } from './Config'

export const msgTypeMap = {
	REQUEST_LIMIT_TIME: {
		msg: '【通知】[不在]チャット申し込みがありました',
		icon: 'sms',
		label: '',
	},
	CANCEL_REQUEST_CHAT: {
		msg: '【通知】チャット相談の申し込みがキャンセルされました',
		icon: 'sms',
		label: '',
	},
	CANCEL_REQUEST_CALL: {
		msg: '通話相談の申し込みがキャンセルされました',
		icon: 'call',
		label: '',
	},
	USER_REQUEST_CALL: {
		msg: '電話相談リクエストがありました',
		icon: 'call',
		label: '',
	},
	USER_REQUEST_CHAT: {
		msg: '【通知】チャット相談リクエストがありました',
		icon: 'sms',
		label: '',
	},
	AUTO_MESSAGE_PICK_BEST_ANSWER: {
		msg: '',
		icon: '',
		label: '自動メッセージ',
	},
	FREE_TEXT: {
		msg: '',
		icon: '',
		label: '無料',
	},
	FREE_TEXT_UNOFFICIAL: {
		msg: '',
		icon: '',
		label: 'オフラインメッセージ',
	},
	CHARGE_TEXT_UNOFFICIAL: {
		msg: '',
		icon: '',
		label: 'オフラインメッセージ',
	},
	SEND_FILE_UNOFFICIAL: {
		msg: '',
		icon: '',
		label: 'オフラインメッセージ',
	},
	CHARGE_TEXT: {
		msg: '',
		icon: '',
		label: '',
	},
	SENT_TEXT: {
		msg: '',
		icon: '',
		label: '',
	},
	CLOSE_CHAT: {
		msg: '【通知】チャットが終了しました。',
		icon: 'sms',
		label: '',
	},
	NO_CONNECT_AVAILABLE: {
		msg: '【通知】お客様が一時退出しています。再入室をお待ちください。無料メッセージは送信できます。',
		icon: 'sms',
		label: '',
	},
	NO_ACTION_LONG_TIME: {
		msg: '【通知】発言が無いため、チャットが終了しました。',
		icon: 'sms',
		label: '',
	},
	CALL: {
		msg: '',
		icon: 'call',
		label: '',
	},
	SENT_FILE: {
		msg: '',
		icon: '',
		label: '',
	},
	SENT_FILE_FREE: {
		msg: '',
		icon: '',
		label: '無料',
	},
	SEND_FILE_FREE: {
		msg: '',
		icon: '',
		label: '無料',
	},
	SEND_FILE_FREE_UNOFFICIAL: {
		msg: '',
		icon: '',
		label: 'オフラインメッセージ',
	},
	EXPECTED_REQUEST_CHAT: {
		msg: '★チャット相談リクエスト\n第一希望日時\n',
		icon: 'sms',
		label: '無料メッセージ',
	},
	CANCEL_EXPECTED_REQUEST_CHAT: {
		msg: 'チャット相談リクエストをキャンセルしました。',
		icon: 'sms',
		label: '無料メッセージ',
	},
	EXPECTED_REQUEST_CALL: {
		msg: '★ 電話相談リクエスト\n第一希望日時\n',
		icon: 'sms',
		label: '無料メッセージ',
	},
	CANCEL_EXPECTED_REQUEST_CALL: {
		msg: '電話相談リクエストをキャンセルしました。',
		icon: 'sms',
		label: '無料メッセージ',
	},
	SEND_MSG_ACCEPT_CHAT: {
		msg: '【通知】お客様が入室しました。',
		icon: 'sms',
		label: '',
	},
	USER_RECONNECT_CHAT_IN_BENCH_TIME: {
		msg: '【通知】お客様が一時退出から戻られました。',
		icon: 'sms',
		label: '',
	},
	/*SEND_STAMP: {
    msg: "電話相談リクエストをキャンセルしました。",
    icon: "sms",
    label: "",
  },*/

	//通知がくるみたいだが、表示については明確でないので保留
	// RESPONSE_REQUEST_CHAT: {
	//   msg: "リクエストを拒否しました",
	//   icon: "",
	//   label: "",
	// },
	// SEND_FILE: {
	//   msg: "",
	//   icon: "",
	//   label: "",
	// },
	// CALL: {
	//   msg: "",
	//   icon: "",
	//   label: "",
	// }
}

export const endTypeMap = {
	CANCEL_CALL: '通話相談の申し込みがキャンセルされました。',
	BUSY_CALL: '[不在]着信がありました。',
	LISTENED: '通話相談時間',
}

const mapTimeToDayOfWeek = (day) => {
	const dayOfWeek = ['日', '月', '火', '水', '木', '金', '土']

	return dayOfWeek[day]
}

export function getMessageData(messageObj, displayLeftHistory) {
	let msgTypeUpperCase = messageObj.msgType.toUpperCase()
	const msgMap =
		msgTypeMap[msgTypeUpperCase] === undefined
			? {}
			: msgTypeMap[msgTypeUpperCase]
	let msg = msgMap.msg ? msgMap.msg : messageObj.value
	if (messageObj.msgType === 'CALL') {
		msg = endTypeMap[messageObj.value]

		if (
			messageObj.value === 'LISTENED' &&
			typeof messageObj.duration !== 'undefined'
		) {
			let duration = parseInt(messageObj.duration)
			let hours = Math.floor(duration / 3600)
			let minutes = Math.floor((duration % 3600) / 60)
			let seconds = duration % 60

			hours = hours < 10 ? `0${hours}` : hours
			minutes = minutes < 10 ? `0${minutes}` : minutes
			seconds = seconds < 10 ? `0${seconds}` : seconds

			msg += ` ${hours}：${minutes}：${seconds}`
		}
	}
	if (messageObj.msgType === 'CANCEL_REQUEST_CHAT') {
		if (typeof messageObj.value == 'undefined') {
			msg = 'チャット相談の申し込みがお客様によりキャンセルされました'
		}
	}

	if (
		(messageObj.msgType === 'EXPECTED_REQUEST_CHAT' ||
			messageObj.msgType === 'EXPECTED_REQUEST_CALL') &&
		messageObj.value
	) {
		const expectedTimeArr = messageObj.value.split(',')
		for (let expectedTime of expectedTimeArr) {
			const datetime = moment(expectedTime, 'YYYYMMDDHHmmss')
			const day = mapTimeToDayOfWeek(datetime.day())
			msg += datetime.format(`YYYY/MM/DD (${day}) HH:mm`) + '\n'
		}
		msg += '\nよろしくお願いいたします。'
	}
	if (
		(messageObj.msgType === 'SENT_FILE' ||
			messageObj.msgType === 'SEND_FILE_FREE' ||
			messageObj.msgType === 'SEND_FILE' ||
			messageObj.msgType === 'SEND_FILE_UNOFFICIAL' ||
			messageObj.msgType === 'SEND_FILE_FREE_UNOFFICIAL') &&
		displayLeftHistory === 'displayTextLeftHistory'
	) {
		msg = '写真を送りました'
	}
	if (
		messageObj.msgType === 'SEND_GIFT' &&
		displayLeftHistory === 'displayTextLeftHistory'
	) {
		msg = 'ポイントギフトを受信しました'
	}
	if (
		(messageObj.msgType === 'SEND_STAMP' ||
			messageObj.msgType === 'SEND_STAMP_UNOFFICIAL') &&
		displayLeftHistory === 'displayTextLeftHistory'
	) {
		msg = 'ステッカー'
	}
	if (msgTypeUpperCase === 'CANCEL_EXPECTED_REQUEST_CHAT') {
		msg = msgTypeMap[msgTypeUpperCase].msg
	}

	if (messageObj.chatDraft && messageObj.serverTimeChatDraft >= messageObj.serverTime ) {
		msg = TEXT_CHAT_DRAFT + messageObj.chatDraft
	}
	
	return {
		msg: msg,
		icon: msgMap.icon ? msgMap.icon : '',
		label: msgMap.label ? msgMap.label : '',
	}
}

export const FREE_SEND_TEXT_FROM_TELLER_STATUS = -1
