import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as TimelineActions from '../actions/TimelineActions'
import TextField from 'material-ui/TextField'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import Snackbar from 'material-ui/Snackbar'

class TimelineCommentInputContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			disabled: 'disabled',
			formView: 'none',
			userInfo: {},
			valueComment: '',
		}
	}

	componentDidMount() {
		this.loadUserData(this.props.userId)
	}

	loadUserData = (userId) => {
		const url = glasConfig.url_base + glasConfig.path_user_info + userId
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						userInfo: response.data.data,
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	handleChange(event) {
		let disabled = 'disabled'
		if (event.target.value.length !== 0) {
			disabled = ''
		}

		this.setState({
			disabled: disabled,
			valueComment: event.target.value,
		})
	}

	handleSubmit(event) {
		if (this.state.userInfo.isBlocked) {
			event.preventDefault()
			this.setState({ isBlocked: true })
		} else {
			event.preventDefault()

			const { dispatch } = this.props
			let commentValue = event.target.timelineComment.value
			dispatch(
				TimelineActions.timelineCommentPost({
					postId: this.props.postId,
					commentId: this.props.commentId,
					subcommentValue: commentValue,
				})
			)
			this.toggleForm()
		}
		this.setState({
			valueComment: '',
		})
	}

	handleClick(event) {
		event.preventDefault()
		this.toggleForm()
	}

	handleCancel(event) {
		event.preventDefault()
		this.toggleForm()
	}

	toggleForm() {
		let display = ''
		if (this.state.formView === 'none') {
			display = 'block'
		} else {
			display = 'none'
		}

		this.setState({
			formView: display,
			valueComment: '',
		})
	}

	render() {
		return (
			<form
				name="timelineCommentForm"
				onSubmit={(e) => this.handleSubmit(e)}
			>
				<div className="timeline-comment__textfield">
					<button
						className="btn timeline-comment__btn-more"
						onClick={(e) => this.handleClick(e)}
					>
						返信する
					</button>
					<div style={{ display: this.state.formView }}>
						<TextField
							hintText="コメントを記入"
							type="text"
							name="timelineComment"
							onChange={(e) => this.handleChange(e)}
							fullWidth={true}
							value={this.state.valueComment}
						/>
						<div className="timeline-comment__buttons">
							<button
								className="btn"
								onClick={(e) => this.handleCancel(e)}
							>
								キャンセル
							</button>
							<button
								className="btn-raised color_default"
								disabled={this.state.disabled}
							>
								投稿
							</button>
						</div>
					</div>
				</div>
				<Snackbar
					open={this.state.isBlocked}
					message={
						'このユーザーをブロックしているので回答できません。'
					}
					autoHideDuration={1500}
				/>
			</form>
		)
	}
}

const mapStateToProps = (store) => {
	return store
}

export default connect(mapStateToProps)(TimelineCommentInputContent)
