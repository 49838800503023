import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import * as WebSocket from '../util/WebSocket'
import * as ReviewActions from '../actions/ReviewActions'
import * as MyProfiles from '../actions/MyProfileActions'
import * as Fetch from '../util/Fetch'
import {
	glasConfig,
	FILTER_STAR_OPTIONS,
	LOAD_MORE_SIZE,
} from '../constants/Config'
import * as moment from 'moment'
import {
	ContainerFilterBar,
	ContainerReviewList,
	ContentFilter,
	HorizontalRuler,
	LabelStarFilter,
	NumberOfPinnedReview,
	NumberOfPinnedReviewMobile,
	PinImage,
	PinReview,
	PlayTutorialIcon,
	ReviewItem,
	StarFilter,
	StyledContainerMainContent,
	TitleReviewPage,
	PlayTutorialForMobile,
} from './ReviewStyled/StyledReviewComponents'
import { SelectField, MenuItem, Checkbox } from 'material-ui'
import FilledPushPin from '../assets/push_pin.png'
import EmptyPushPin from '../assets/empty_push_pin.png'
import PlayTutorial from '../assets/icon_play_tutorial.png'
import { ModalTutorialReviewPage } from './Modals/ModalTutorialReviewPage/ModalTutorialReviewPage'
import { colors } from '../util/StellaConstant'
import InfiniteScroll from 'react-infinite-scroller'
import { func } from 'prop-types'
const debounce = require('lodash.debounce')

class Review extends Component {
	constructor(props) {
		super(props)
		this.state = {
			postSize: glasConfig.review.size,
			postPage: 0,
			reviews: [],
			autoload: true,
			dataloaded: false,
			starFilter: FILTER_STAR_OPTIONS[0].value,
			contentFilter: false,
			numberOfPinnedReview: 0,
			showTutorial: !this.props.MyProfile.data.isHiddenPinReviewTutorial,
			hasMoreReviews: true,
			page: 0,
		}

		// レビューリストDOMElement
		this.reviewList = null
	}

	async componentDidMount() {
		const { dispatch, MyProfile } = this.props
		const params = this.getReviewParams({
			page: this.state.page,
			initial: true,
		})
		const tellerId = MyProfile.data.fortuneTellerId

		dispatch(ReviewActions.getPinedReviews({ tellerId }))
		dispatch(ReviewActions.getReviewsWithFilters(params))
		this.calculatorOverflowReview()
		window.addEventListener('resize', this.calculatorOverflowReview, false);
	}

	componentDidUnmount() {
		window.removeEventListener('resize', this.calculatorOverflowReview, false);
	}

	calculatorOverflowReview() {
		const { innerWidth: width, innerHeight: height } = window;
		let maxHeight = (height - 394)
		if (width < 768) {
			maxHeight = (height - 317)
		}

		if (width > 1024) {
			maxHeight = (height - 356)
		}

		document.querySelector('#scroll-review').style.height = maxHeight + "px"
	}

	getNumberOfPinedReview = () => {
		const { Review } = this.props
		const result = Review.pinedReviews.data.filter(
			(review) => review.isPinned === true
		).length
		this.setState({
			numberOfPinnedReview: result,
		})
		return result
	}

	componentDidUpdate(prevProps, prevState) {
		const { dispatch } = this.props
		if (prevState.page !== this.state.page) {
			const nextPage = this.state.page
			if (nextPage !== 0) {
				const params = this.getReviewParams({
					page: nextPage,
					initial: false,
				})
				this.getReviewsDebounce(params)
			}
		}
		if (
			prevProps.Review.pinedReviews.data !==
			this.props.Review.pinedReviews.data
		) {
			this.getNumberOfPinedReview()
		}
		if (
			prevProps.Review.reviewsWithFilters.data.length !==
			this.props.Review.reviewsWithFilters.data.length
		) {
			this.setState({ hasMoreReviews: true })
		}
		if (
			prevProps.Review.pinData.isDone !== this.props.Review.pinData.isDone
		) {
			this.getNumberOfPinedReview()
			dispatch(
				ReviewActions.updatePinStatusOfReviewItem({
					reviewId: this.props.Review.pinData.reviewId,
					status: 'pined',
				})
			)
		}
		if (
			prevProps.Review.unpinData.isDone !==
			this.props.Review.unpinData.isDone
		) {
			this.getNumberOfPinedReview()
			dispatch(
				ReviewActions.updatePinStatusOfReviewItem({
					reviewId: this.props.Review.unpinData.reviewId,
					status: 'unpined',
				})
			)
		}
	}

	getReviewsDebounce = debounce((params) => {
		const { dispatch } = this.props
		dispatch(ReviewActions.getReviewsWithFilters(params))
	}, 500)

	getReviewParams = ({ page, initial }) => {
		return {
			contentFilter: this.state.contentFilter,
			page,
			size: LOAD_MORE_SIZE,
			initial,
		}
	}

	loadFunc = (page) => {
		this.setState({
			page: page,
			hasMoreReviews: false,
		})
	}

	resetPageOfInfiniteScroll = () => {
		if (this.scroll) {
			this.scroll.pageLoaded = 0
		}
	}

	handleMarkUpReview = (reviewValue) => {
		const { dispatch } = this.props
		const reviewId = reviewValue.reviewId
		const isPined = reviewValue.isPinned

		const params = {
			reviewId,
		}
		if (isPined) {
			// handle unpin
			dispatch(ReviewActions.unpinReview(params))
		} else {
			// handle pin
			if (this.state.numberOfPinnedReview < 2) {
				dispatch(ReviewActions.pinReview(params))
			}
		}
	}

	handleChangeStarFilter = (event, index, value) => {
		const { dispatch } = this.props
		this.resetPageOfInfiniteScroll()
	}

	handleChangeContentFilter = () => {
		const { dispatch } = this.props
		this.resetPageOfInfiniteScroll()
		this.setState(
			(oldState) => {
				return {
					page: 0,
					contentFilter: !oldState.contentFilter,
				}
			},
			() => {
				// handle call new list with params are the states
				dispatch(ReviewActions.resetReviewList())
				const params = this.getReviewParams({
					page: this.state.page,
					initial: true,
				})
				dispatch(ReviewActions.getReviewsWithFilters(params))
			}
		)
	}

	handlePlayTutorial = () => {
		this.setState({
			showTutorial: true,
		})
	}

	updateStatusOfReviewTutorial = () => {
		const { dispatch } = this.props
		dispatch(ReviewActions.updateReviewTutorial())
		dispatch(MyProfiles.fetch())
	}

	resetToggle = () => {
		this.setState({
			showTutorial: false,
		})
	}

	renderPinedReviewList = () => {
		const { Review } = this.props
		let result = null
		if (Review.pinedReviews.isLoaded) {
			result = Review.pinedReviews.data.map((item) => {
				const date = moment(item.reviewDate, 'YYYYMDHms')
				const star = 'star_' + item.star
				const isPinedReview = item.isPinned

				return (
					<ReviewItem
						isPinedReview={isPinedReview}
						key={item.reviewId}
					>
						<section className="review">
							<div className={`review__stars ${star}`}>
								<i className="material-icons review__icons">
									star
								</i>
								<i className="material-icons review__icons">
									star
								</i>
								<i className="material-icons review__icons">
									star
								</i>
								<i className="material-icons review__icons">
									star
								</i>
								<i className="material-icons review__icons">
									star
								</i>
							</div>
							<p className="review__time">
								{date.format('YY/MM/DD')}
							</p>
							<p
								className="review__main"
								style={{ paddingLeft: 0 }}
							>
								{item.reviewText}
							</p>
							{/* <p className="review__main"  style={{ paddingLeft: 0 }}>{item.reviewId}</p> */}
						</section>
						<PinReview>
							<PinImage
								src={
									isPinedReview ? FilledPushPin : EmptyPushPin
								}
								onClick={() => this.handleMarkUpReview(item)}
							/>
						</PinReview>
						<HorizontalRuler />
					</ReviewItem>
				)
			})
		} else {
			result = null
		}

		return result
	}

	renderReviewList() {
		const { Review } = this.props
		// console.log('%c  Review:', 'color: #0e93e0;background: #aaefe5;', Review.reviewsWithFilters);
		if (
			Review.reviewsWithFilters.isLoaded === true &&
			Review.reviewsWithFilters.data.length === 0 &&
			Review.pinedReviews.data.length === 0
		) {
			return (
				<li className="list__item">
					<section className="review">
						<p className="review__main">レビューはまだありません</p>
					</section>
				</li>
			)
		} else if (Review.reviewsWithFilters.isLoaded === false) {
			return (
				<li className="list__item">
					<section className="review">
						<p className="review__main">
							レビューを読みこんでいます...
						</p>
					</section>
				</li>
			)
		}
		return (
			<InfiniteScroll
				pageStart={0}
				loadMore={this.loadFunc}
				hasMore={this.state.hasMoreReviews}
				loader={
					<div className="loader" key={0}>
						Loading ...
					</div>
				}
				useWindow={false}
				getScrollParent={() => this.scrollParentRef}
			>
				{Review.reviewsWithFilters.data.map((item, index) => {
					let date = moment(item.reviewDate, 'YYYYMDHms')
					let star = 'star_' + item.star
					let isPinedReview = item.isPinned
					let isShowPin = this.checkTimeOfPostingReview(
						item.reviewDate
					)
					return (
						<ReviewItem
							isPinedReview={isPinedReview}
							key={item.reviewId}
						>
							<section className="review">
								<div className={`review__stars ${star}`}>
									<i className="material-icons review__icons">
										star
									</i>
									<i className="material-icons review__icons">
										star
									</i>
									<i className="material-icons review__icons">
										star
									</i>
									<i className="material-icons review__icons">
										star
									</i>
									<i className="material-icons review__icons">
										star
									</i>
								</div>
								<p className="review__time">
									{date.format('YY/MM/DD')}
								</p>
								<p
									className="review__main"
									style={{ paddingLeft: 0 }}
								>
									{item.reviewText}
								</p>
								{/* <p className="review__main"  style={{ paddingLeft: 0 }}>{item.reviewId}</p> */}
							</section>
							{isShowPin ? (
								<PinReview>
									<PinImage
										src={
											isPinedReview
												? FilledPushPin
												: EmptyPushPin
										}
										onClick={() =>
											this.handleMarkUpReview(item)
										}
									/>
								</PinReview>
							) : null}
							<HorizontalRuler />
						</ReviewItem>
					)
				})}
			</InfiniteScroll>
		)
	}

	checkTimeOfPostingReview = (postTime) => {
		const currentTime = moment()
		const reviewDate = moment(postTime, 'YYYYMMDDHHmmss')
		const diffTime = currentTime.diff(reviewDate, 'hours')

		return diffTime <= 90 * 24
	}

	render() {
		return (
			<>
				<StyledContainerMainContent>
					<div className="content__inner" >
						<TitleReviewPage>
							<h1 className="content__title hidden-sp">
								レビュー
							</h1>
							<PlayTutorialIcon
								src={PlayTutorial}
								onClick={this.handlePlayTutorial}
							/>
						</TitleReviewPage>
						<ContainerFilterBar>
							<div>
								<ContentFilter>
									<Checkbox
										id="content-filter"
										label="コメントありのみ表示"
										labelPosition="right"
										onCheck={this.handleChangeContentFilter}
										checked={this.state.contentFilter}
										iconStyle={{
											fill: this.state.contentFilter
												? 'rgba(17, 173, 157, 1)'
												: 'rgba(0, 0, 0, 0.38)',
											marginRight: 0,
										}}
										labelStyle={{
											fontFamily:
												'Hiragino Kaku Gothic Pro',
											fontSize: 15,
											color: 'rgba(0, 0, 0, 0.38)',
										}}
									/>
								</ContentFilter>
							</div>
							<NumberOfPinnedReview>
								<p>
									レビューのピン留めの数：
									<span
										id="counter"
										style={
											this.state.numberOfPinnedReview == 0
												? { color: colors.manatee }
												: { color: colors.pearlAqua }
										}
									>
										{this.state.numberOfPinnedReview}
									</span>
									<span id="constant">/2</span>
								</p>
							</NumberOfPinnedReview>
						</ContainerFilterBar>
						<NumberOfPinnedReviewMobile>
							<p>
								レビューのピン留めの数：
								<span
									id="counter"
									style={
										this.state.numberOfPinnedReview == 0
											? { color: colors.manatee }
											: { color: colors.pearlAqua }
									}
								>
									{this.state.numberOfPinnedReview}
								</span>
								<span id="constant">/2</span>
							</p>
							<PlayTutorialForMobile onClick={this.handlePlayTutorial}>
								レビューのピン留めについて
							</PlayTutorialForMobile>
						</NumberOfPinnedReviewMobile>
						<ContainerReviewList>
							<ul
								className="list column_1"
								id="scroll-review"
								ref={(ref) => this.scrollParentRef = ref}
							>
								{this.renderPinedReviewList()}
								{this.renderReviewList()}
							</ul>
						</ContainerReviewList>
					</div>
					<div className='review-note'>
						<span className='text-note-review'>※注意※</span>
						<p>
							ユーザーがつけたレビューについて<span className='text-note-review'>意見を言う、期待するレビューではなかったことを理由にブロックする、高評価レビューを強要する、</span>などの行為はおやめください。
						</p>
					</div>
				</StyledContainerMainContent>
				<ModalTutorialReviewPage
					show={this.state.showTutorial}
					updateStatusOfReviewTutorial={
						this.updateStatusOfReviewTutorial
					}
					resetToggle={this.resetToggle}
				/>
			</>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		Review: store.Review,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(Review)
