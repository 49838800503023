import { combineReducers } from 'redux'
import { AUTH_LOGOUT } from '../constants/ActionTypes'
import { Auth } from './Auth'
import { MyProfile } from './MyProfile'
import { NextLogin } from './NextLogin'
import { News } from './News'
import { ChatRequest } from './ChatRequest'
import { RequestOffline } from './RequestOffline'
import { UserInfo } from './UserInfo'
import { File } from './File'
import { Template } from './Template'
import { MyAccount } from './MyAccount'
import { MyAccountPassword } from './MyAccountPassword'
import { MyAccountAgeAuth } from './MyAccountAgeAuth'
import { Bank } from './Bank'
import { Follower } from './Follower'
import { RequestHistory } from './RequestHistory'
import { MyNotification } from './MyNotification'
import { Timeline } from './Timeline'
import { CustomerManagement } from './CustomerManagement'
import { Review } from './Review'
import { StaffBlog } from './StaffBlog'
import { Mission } from './Mission'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { AnswerManagement } from './AnswerManagement'
import { Contact } from './Contact'
import { IdleManagement } from './IdleManagement'
import { ChatNumberTotal } from './ChatNumberTotal'
import { RequestHistoryRead } from './RequestHistoryRead'
import { RequestHistoryUnRead } from './RequestHistoryUnRead'
import { ChatRequestRead } from './ChatRequestRead'
import { ChatRequestUnRead } from './ChatRequestUnRead'
import { DetailAnswerChatLog } from './DetailAswerChatLog'
import { SuspendRequestChat } from './SuspendRequestChat'
import { Call } from './Call'
import { PointReport } from './PointReport'
import { CategoryBlog } from './CategoryBlog'
import { ChatDraft } from './ChatDraft'
import { RequestHistoryPin } from './RequestHistoryPin'

const appReducer = combineReducers({
	loadingBar: loadingBarReducer,
	Auth,
	News,
	ChatRequest,
	RequestOffline,
	MyProfile,
	NextLogin,
	UserInfo,
	File,
	Template,
	MyAccount,
	MyAccountPassword,
	MyAccountAgeAuth,
	Bank,
	Follower,
	RequestHistory,
	MyNotification,
	Timeline,
	CustomerManagement,
	Review,
	StaffBlog,
	Mission,
	AnswerManagement,
	Contact: Contact,
	IdleManagement,
	ChatNumberTotal,
	RequestHistoryRead,
	RequestHistoryUnRead,
	ChatRequestRead,
	ChatRequestUnRead,
	DetailAnswerChatLog,
	SuspendRequestChat,
	Call,
	PointReport,
	CategoryBlog,
	ChatDraft,
	RequestHistoryPin,
})

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT) {
		state = undefined
		window.location.reload()
	}
	return appReducer(state, action)
}

export default rootReducer
