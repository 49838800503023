import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../util/StellaConstant'

export const StyledContainerMainContent = styled.div`
	@media ${DEVICE.mobileS} {
		padding: 65px 10px 48px 10px;
	}
	@media ${DEVICE.tablet} {
		padding: 100px 10px 48px 288px;
	}
	@media ${DEVICE.laptop} {
		padding: 100px 10px 48px 288px;
	}
`

export const PlayTutorialForMobile = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 10px;
		color: ${colors.pearlAqua} !important;
		letter-spacing: 0px;
		text-align: center;
		cursor: pointer;
		text-decoration: underline;
    	text-underline-offset: 4px;
	}
	@media ${DEVICE.tablet} {
		display: none;
	}
	@media ${DEVICE.laptop} {
		display: none;
	}
`

export const TitleReviewPage = styled.div`
	display: flex;
`

export const PlayTutorialIcon = styled.img`
	@media ${DEVICE.mobileS} {
		display: none;
		width: 20px;
		height: 20px;
		margin-left: 12px;
		cursor: pointer;
	}
	@media ${DEVICE.tablet} {
		display: block;
	}
	@media ${DEVICE.laptop} {
		display: block;
	}
`

export const ContainerFilterBar = styled.div`
	@media ${DEVICE.mobileS} {
		background-color: ${colors.white};
		padding: 5px 4px 5px 4px;
		margin-bottom: 9px;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		border: 1px solid rgba(0, 0, 0, 0.27);
	}
	@media ${DEVICE.tablet} {
		background-color: ${colors.white};
		padding: 17px 24px 19px 22px;
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;
		border: none;
		div {
			display: flex;
		}
	}
	@media ${DEVICE.laptop} {
		background-color: ${colors.white};
		padding: 17px 24px 19px 22px;
		margin-bottom: 24px;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		border: none;
		div {
			display: flex;
		}
	}
`

export const LabelStarFilter = styled.label`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.tablet} {
		display: flex;
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 14px;
		color: ${colors.taupeGray};
		letter-spacing: 0.02em;
		margin-right: 13px;
	}
	@media ${DEVICE.laptop} {
		display: flex;
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 14px;
		color: ${colors.taupeGray};
		letter-spacing: 0.02em;
		margin-right: 13px;
	}
`

export const StarFilter = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		align-items: center;

		#star-filter div {
			font-family: ${fonts.HiraKakuProW3};
			font-size: 16px;
			color: rgba(0, 0, 0, 0.38) !important;
			padding-left: 6px;
		}
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		display: flex;
		align-items: center;
		margin-right: 48px;

		#star-filter div {
			font-family: ${fonts.HiraKakuProW3};
			font-size: 16px;
			color: rgba(0, 0, 0, 0.38) !important;
		}
	}
`

export const ContentFilter = styled.div`
	@media ${DEVICE.mobileS} {
		margin-top: 10px;
		padding-left: 6px;
		label {
			font-family: ${fonts.HiraKakuProW3}!important;
			font-size: 15px !important;
			color: rgba(0, 0, 0, 0.38) !important;
		}
	}
	@media ${DEVICE.tablet} {
		margin-top: 10px;
		padding-left: 6px;

		label {
			width: 100% !important;
			font-family: ${fonts.HiraKakuProW3}!important;
			font-size: 15px !important;
			color: rgba(0, 0, 0, 0.38) !important;
		}
	}
	@media ${DEVICE.laptop} {
		width: 190px;
		display: flex;
		align-items: center;

		label {
			width: 100% !important;
			line-height: 27px !important;
		}
	}
`

export const NumberOfPinnedReview = styled.div`
	@media ${DEVICE.mobileS} {
		display: none !important;

		p {
			font-family: ${fonts.HiraginoKakuGothicPro};
			font-size: 14px;
			color: ${colors.darkJungleGreen};
		}
		span#constant {
			font-family: ${fonts.HiraginoKakuGothicPro};
			font-size: 14px;
			color: ${colors.pearlAqua};
		}
	}
	@media ${DEVICE.tablet} {
		display: none !important;
	}
	@media ${DEVICE.laptop} {
		display: flex !important;
		align-items: center;
	}
`
export const NumberOfPinnedReviewMobile = styled.div`
	display: flex;
	@media ${DEVICE.mobileS} {
		justify-content: space-between;
    	align-items: center;
		margin: 11px 0 7px 0;

		p {
			font-family: ${fonts.HiraKakuProW3};
			font-size: 12px;
			color: ${colors.darkJungleGreen};
			margin-left: 4px;
		}
		span#constant {
			font-family: ${fonts.HiraKakuProW3};
			font-size: 12px;
			color: ${colors.pearlAqua};
		}
	}
	@media ${DEVICE.tablet} {
		justify-content: flex-end;
		margin-bottom: 6px;
	}
	@media ${DEVICE.laptop} {
		display: none !important;
	}
`

export const ContainerReviewList = styled.div`
	@media ${DEVICE.mobileS} {
		display: block;
		padding: 0;
		margin: 0 0 16px;
		background: #fff;
		border-radius: 2px;
		box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 24%);
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
	}
`

export const ReviewItem = styled.li`
	@media ${DEVICE.mobileS} {
		padding: 24px 0 0;
		list-style-type: none;
		position: relative;
		background-color: ${(props) =>
			props.isPinedReview === true ? colors.mintCream : colors.white};

		section {
			border: none;
			padding-top: 0 !important;
			padding-bottom: 27px !important;
		}
	}
	@media ${DEVICE.tablet} {
		padding: 24px 24px 0 24px;
		list-style-type: none;
		position: relative;
		background-color: ${(props) =>
			props.isPinedReview === true ? colors.mintCream : colors.white};

		section {
			border: none;
		}
	}
	@media ${DEVICE.laptop} {
		padding: 24px 24px 0 24px;
		list-style-type: none;
		position: relative;
		background-color: ${(props) =>
			props.isPinedReview === true ? colors.mintCream : colors.white};

		section {
			border: none;
		}
	}
`

export const PinReview = styled.div`
	@media ${DEVICE.mobileS} {
		position: absolute;
		top: 20px;
		right: 15px;
	}
	@media ${DEVICE.tablet} {
		position: absolute;
		top: 25px;
		right: 33px;
	}
	@media ${DEVICE.laptop} {
		position: absolute;
		top: 32px;
		right: 32px;
	}
`

export const HorizontalRuler = styled.div`
	width: 100%;
	height: 1px;
	// background-color: rgba(0, 0, 0, 0.12);
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`

export const PinImage = styled.img`
	cursor: pointer;
`
