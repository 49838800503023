import React, { Component } from 'react'
import { connect } from 'react-redux'
import CustomerManagementMenu from './CustomerManagementMenu'
import * as CustomerManagementActions from '../actions/CustomerManagementActions'
import { glasConfig } from '../constants/Config'
import * as moment from 'moment'
import * as Fetch from '../util/Fetch'
import '../assets/scss/customermanagement.scss'
import InputUserName from './CustomerMangement/BoxSearchHistoryAndFollower/InputUserName'
import InputUserGenderCheckbox from './CustomerMangement/BoxSearchHistoryAndFollower/InputUserGenderCheckbox'
import DropdownListZodiac from './CustomerMangement/BoxSearchHistoryAndFollower/DropdownListZodiac'
import SelectBox from './CustomerMangement/BoxSearchHistoryAndFollower/SelectBox'
import DropdownListWorries from './CustomerMangement/BoxSearchHistoryAndFollower/DropdownListWorries'
import SelectBoxList from './CustomerMangement/BoxSearchHistoryAndFollower/SelectBoxList'
import DatePickerCM from './CustomerMangement/BoxSearchHistoryAndFollower/DatePickerCM'
import PopupWorries from './CustomerMangement/BoxSearchHistoryAndFollower/PopupWorries'
import PopupZodiac from './CustomerMangement/BoxSearchHistoryAndFollower/PopupZodiac'

const sortDefaulType = {
	asc: 1,
	desc: 2,
}

const orderByDefaultType = {
	SuccessRequestChat: 1,
	SuccessCall: 2,
	LastTimeCall: 3,
	LastTimeUserLogin: 4,
	UserIsPurchased: 5,
}

let selectDefaultSort = [
	{
		sort: sortDefaulType.asc,
		orderBy: orderByDefaultType.SuccessCall,
		title: '電話相談が多い順',
		value: 0,
	},
	{
		sort: sortDefaulType.desc,
		orderBy: orderByDefaultType.SuccessCall,
		title: '電話相談が少ない順',
		value: 1,
	},
	{
		sort: sortDefaulType.asc,
		orderBy: orderByDefaultType.SuccessRequestChat,
		title: 'チャット相談が多い順',
		value: 2,
	},
	{
		sort: sortDefaulType.desc,
		orderBy: orderByDefaultType.SuccessRequestChat,
		title: 'チャット相談が少ない順',
		value: 3,
	},
	{
		sort: sortDefaulType.asc,
		orderBy: orderByDefaultType.LastTimeCall,
		title: '最終対応日順 (降順) ',
		value: 4,
	},
	{
		sort: sortDefaulType.desc,
		orderBy: orderByDefaultType.LastTimeCall,
		title: '最終対応日順 (昇順)',
		value: 5,
	},
	{
		sort: sortDefaulType.asc,
		orderBy: orderByDefaultType.LastTimeUserLogin,
		title: '最終ログイン日順 (降順)',
		value: 6,
	},
	{
		sort: sortDefaulType.desc,
		orderBy: orderByDefaultType.LastTimeUserLogin,
		title: '最終ログイン日順 (昇順)',
		value: 7,
	},
	{
		sort: sortDefaulType.asc,
		orderBy: orderByDefaultType.UserIsPurchased,
		title: '購入履歴有り',
		value: 8,
	},
	{
		sort: sortDefaulType.desc,
		orderBy: orderByDefaultType.UserIsPurchased,
		title: '購入履歴なし',
		value: 9,
	},
]

const worriesData = {
	worries1: { title: '相性', value: '1' },
	worries2: { title: '結婚', value: '2' },
	worries3: { title: '離婚', value: '3' },
	worries4: { title: '夫婦仲', value: '4' },
	worries5: { title: '復縁', value: '5' },
	worries6: { title: '不倫', value: '6' },
	worries7: { title: '縁結び', value: '7' },
	worries8: { title: '縁切り', value: '8' },
	worries9: { title: '遠距離恋愛', value: '9' },
	worries10: { title: '同性愛', value: '10' },
	worries11: { title: '三角関係', value: '11' },
	worries12: { title: '金運', value: '12' },
	worries13: { title: '仕事', value: '13' },
	worries14: { title: '起業', value: '14' },
	worries15: { title: '転職', value: '15' },
	worries16: { title: '対人関係', value: '16' },
	worries17: { title: '自分の気持ち', value: '17' },
	worries18: { title: '相手の気持ち', value: '18' },
	worries19: { title: '家庭問題', value: '19' },
	worries20: { title: '運勢', value: '20' },
	worries21: { title: '開運方法', value: '21' },
}

class CustomerManagementFollower extends Component {
	constructor(props) {
		super(props)
		this.state = {
			totalRecords: 0,
			nowPage: 0,
			postSize: glasConfig.usermanagement.follower,
			followers: [],
			autoload: true,
			dataloaded: false,
			dropDownShow: false,
			dropDownShow_worries: false,
			boxShowFormSeachMobile: false,
			formSearchCM: {
				userName: '',
				userGender: [],
				birthdayFrom: '',
				birthdayTo: '',
				lastTimeCallingFrom: '',
				lastTimeCallingTo: '',
				lastLoginTimeFrom: '',
				lastLoginTimeTo: '',
				isPurchase: '',
				isMarked: false,
				zodiacs: [],
				worries: [],
				sort: 2,
				orderBy: 3,
			},
		}
	}

	getQueryParam(nowPage = null) {
		const listTime = ['birthdayFrom', 'birthdayTo']
		const listLongTimeFrom = ['lastTimeCallingFrom', 'lastLoginTimeFrom']
		const listLongTimeTo = ['lastTimeCallingTo', 'lastLoginTimeTo']
		const tellerId = Fetch.tellerId()
		let page = nowPage !== null ? nowPage : this.state.nowPage
		let params = [
			'tab=followers',
			`fortuneTellerId=${tellerId}`,
			`size=${this.state.postSize}`,
			`page=${page}`,
		]

		let listSearchValue = this.state.formSearchCM
		Object.keys(listSearchValue).forEach(function (item) {
			if (
				Array.isArray(listSearchValue[item]) &&
				listSearchValue[item].length > 0
			) {
				let arrayMapData = listSearchValue[item]
				let stringArray = ''
				if (listSearchValue[item].length > 0) {
					arrayMapData.forEach((value, key) => {
						if (item === 'worries') {
							stringArray =
								stringArray +
								worriesData['worries' + value].title +
								(key === listSearchValue[item].length - 1
									? ''
									: ',')
						} else {
							stringArray =
								stringArray +
								value +
								(key === listSearchValue[item].length - 1
									? ''
									: ',')
						}
					})
				}
				if (stringArray.trim() !== '') {
					params.push(`${item}=${stringArray}`)
				}
			} else if (
				listLongTimeFrom.indexOf(item) > -1 &&
				listSearchValue[item] !== ''
			) {
				let valueTime = moment(listSearchValue[item]).format('x')
				params.push(`${item}=${valueTime}`)
			} else if (
				listLongTimeTo.indexOf(item) > -1 &&
				listSearchValue[item] !== ''
			) {
				let valueTime = moment(listSearchValue[item])
					.add(23, 'hours')
					.add(59, 'minutes')
					.add(59, 'seconds')
					.format('x')
				params.push(`${item}=${valueTime}`)
			} else if (
				listTime.indexOf(item) > -1 &&
				listSearchValue[item] !== ''
			) {
				let valueTime = moment(listSearchValue[item]).format('YYYYMMDD')
				params.push(`${item}=${valueTime}`)
			} else {
				if (
					listSearchValue[item] !== '' &&
					!Array.isArray(listSearchValue[item])
				) {
					params.push(`${item}=${listSearchValue[item]}`)
				}
			}
		})

		return `?${params.join('&')}`
	}

	componentWillMount() {
		const { dispatch } = this.props
		dispatch(CustomerManagementActions.followerGet(this.getQueryParam()))
	}

	componentWillReceiveProps(nextProps) {
		let dataloaded = false
		if (
			nextProps.CustomerManagement.data.pageRecords &&
			nextProps.CustomerManagement.data.pageRecords.length >= 0
		) {
			dataloaded = true
		}
		let followers =
			nextProps.CustomerManagement.data.pageRecords !== undefined
				? nextProps.CustomerManagement.data.pageRecords
				: []
		let totalRecords =
			nextProps.CustomerManagement.data.totalRecords !== undefined
				? nextProps.CustomerManagement.data.totalRecords
				: 0
		this.setState({
			totalRecords,
			followers,
			dataloaded,
		})
	}

	handleClickRedirctToDetail = (userId, deActiveUser) => {
		if (!deActiveUser) {
			window.location.href = `/home/chat/${userId}`
		}
	}

	setRows() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords === undefined ||
				this.state.totalRecords === 0) &&
			(this.state.followers === undefined ||
				this.state.followers.length === 0)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan={6}>
						フォロワーがいません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan={6}>
						フォロワーを読み込んでいます...
					</td>
				</tr>
			)
		}
		return Array.prototype.map.call(
			this.state.followers,
			(current, key) => {
				return (
					<tr
						className={
							!current.isDeactivateUser ? 'point-click' : ''
						}
						key={key}
						onClick={() =>
							this.handleClickRedirctToDetail(
								current.userId,
								current.isDeactivateUser
							)
						}
					>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.userName}
							{current.isDeactivateUser ? ' (退会)' : ''}
						</td>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.numberChat}
						</td>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.numberCall}
						</td>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.lastCallTime
								? moment(current.lastCallTime, 'YYYYMDHm')
										.add(9, 'hours')
										.format('YYYY/MM/DD H:mm')
								: '-'}
						</td>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.lastLoginTime
								? moment(current.lastLoginTime, 'YYYYMDHm')
										.add(9, 'hours')
										.format('YYYY/MM/DD H:mm')
								: '-'}
						</td>
						<td
							className="table-align-center"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							{current.isPurchasedUser || false ? '有' : 'なし'}
						</td>
					</tr>
				)
			}
		)
	}

	setRowsSP() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords === undefined ||
				this.state.totalRecords === 0) &&
			(this.state.followers === undefined ||
				this.state.followers.length === 0)
		) {
			return (
				<div>
					<div className="table-align-center">
						フォロワーがいません
					</div>
				</div>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<div>
					<div className="table-align-center">
						フォロワーを読み込んでいます...
					</div>
				</div>
			)
		}
		return Array.prototype.map.call(
			this.state.followers,
			(current, key) => {
				return (
					<div
						className={
							!current.isDeactivateUser
								? 'warpper-item point-click'
								: 'warpper-item'
						}
						key={key}
					>
						<div
							className="top-item"
							onClick={() =>
								this.handleClickRedirctToDetail(
									current.userId,
									current.isDeactivateUser
								)
							}
						>
							<span className="userName">
								{current.userName}
								{current.isDeactivateUser ? ' (退会)' : ''}
							</span>
							<span className="pay">
								購入履歴：
								{current.isPurchasedUser || false
									? '有'
									: 'なし'}
							</span>
						</div>
						<div className="content-item">
							<ul className="list-info">
								<li
									className="info-user"
									onClick={() =>
										this.handleClickRedirctToDetail(
											current.userId,
											current.isDeactivateUser
										)
									}
								>
									チャット相談 : {current.numberChat}回
								</li>
								<li
									className="info-user"
									onClick={() =>
										this.handleClickRedirctToDetail(
											current.userId,
											current.isDeactivateUser
										)
									}
								>
									電話相談 : {current.numberCall}回
								</li>
								<li
									className="info-user"
									onClick={() =>
										this.handleClickRedirctToDetail(
											current.userId,
											current.isDeactivateUser
										)
									}
								>
									最終対応 :{' '}
									{current.lastCallTime
										? moment(
												current.lastCallTime,
												'YYYYMDHm'
										  )
												.add(9, 'hours')
												.format('YYYY/MM/DD HH:mm')
										: '-'}
								</li>
								<li className="info-user row-flex-box">
									<div className={'box-left'}>
										最終ログイン :{' '}
										{current.lastLoginTime
											? moment(
													current.lastLoginTime,
													'YYYYMDHm'
											  )
													.add(9, 'hours')
													.format('YYYY/MM/DD HH:mm')
											: '-'}
									</div>
								</li>
							</ul>
						</div>
					</div>
				)
			}
		)
	}

	handlePage(event, page) {
		const { dispatch } = this.props
		event.preventDefault()
		this.setState({
			nowPage: page,
		})
		dispatch(
			CustomerManagementActions.followerGet(this.getQueryParam(page))
		)
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}
		let nowPage = parseInt(this.state.nowPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.usermanagement.management
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0

		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1

		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = nowPage - showHalfPage
		let loopEnd = nowPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (nowPage !== 0) {
			firstPage = false
		}
		if (nowPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			lastPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item" key={'firstPage' + 1}>
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = nowPage > 0 ? nowPage - 1 : 0
			prevNav.push(
				<li className="list__item" key={'prev' + prev}>
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = nowPage !== endPage ? nowPage + 1 : endPage
			nextNav.push(
				<li className="list__item" key={'next' + next}>
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item" key={'endPage' + endPage}>
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === nowPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item" key={i}>
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className={'primary'}>
				{prevNav.length > 0 ||
				pageNav.length > 0 ||
				nextNav.length > 0 ? (
					<div className="primary__inner">
						<div className="pager">
							<ul className="list">
								{prevNav}
								{pageNav}
								{nextNav}
							</ul>
						</div>
					</div>
				) : null}
			</div>
		)
	}

	dropDownShow = (name, value) => {
		this.setState({
			[name]: !value,
		})
	}

	handleChangeValue = (value, name, type = 'text') => {
		let dataObject = {}
		if (type === 'text') {
			dataObject = { [name]: value }
		} else if (type === 'dateRange') {
			dataObject = { [name]: value }
		}
		Object.assign(this.state.formSearchCM, dataObject)
		this.setState({ formSearchCM: this.state.formSearchCM })
	}

	handleSortColumn = (name, valueSort) => {
		let dataObject
		if (this.state.formSearchCM.orderBy !== valueSort) {
			dataObject = { [name]: valueSort, sort: sortDefaulType.asc }
		} else {
			if (this.state.formSearchCM.sort === sortDefaulType.asc) {
				dataObject = { [name]: valueSort, sort: sortDefaulType.desc }
			} else {
				dataObject = { [name]: valueSort, sort: sortDefaulType.asc }
			}
		}
		Object.assign(this.state.formSearchCM, dataObject)
		this.setState({ formSearchCM: this.state.formSearchCM })
		this.searchWidthParam()
	}

	searchWidthParam = () => {
		const { dispatch } = this.props
		this.setState({
			nowPage: 0,
		})
		dispatch(CustomerManagementActions.followerGet(this.getQueryParam(0)))
	}

	boxClearSearch = () => {
		this.setState({
			formSearchCM: {
				userName: '',
				userGender: [],
				birthdayFrom: '',
				birthdayTo: '',
				lastTimeCallingFrom: '',
				lastTimeCallingTo: '',
				lastLoginTimeFrom: '',
				lastLoginTimeTo: '',
				isPurchase: '',
				isMarked: false,
				zodiacs: [],
				worries: [],
				sort: this.state.formSearchCM.sort,
				orderBy: this.state.formSearchCM.orderBy,
			},
		})
	}

	boxParamSearchForm = () => {
		let selectedValueSort = ''
		selectDefaultSort.map((item, key) => {
			if (
				item.sort === this.state.formSearchCM.sort &&
				item.orderBy === this.state.formSearchCM.orderBy
			) {
				selectedValueSort = item.value
			}
		})

		return (
			<div className="primary">
				<div className="frame-table hidden-sp p-5 padding-box-search">
					<div className="row-box-inline-CM">
						<InputUserName
							defaultValue={this.state.formSearchCM.userName}
							dataPlaceholder={'入力してください'}
							responsiveMobile={true}
							onData={(value) =>
								this.handleChangeValue(value, 'userName')
							}
							labelText={'名前'}
						/>
						<InputUserGenderCheckbox
							defaultValue={this.state.formSearchCM.userGender}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'userGender',
									'text'
								)
							}
						/>
						<DatePickerCM
							defaultValue={this.state.formSearchCM.birthdayFrom}
							labelText={'生年月日'}
							middleLine={true}
							classLabelTop={'label-margin-right'}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'birthdayFrom',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={this.state.formSearchCM.birthdayTo}
							iconRight={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'birthdayTo',
									'dateRange'
								)
							}
						/>
						<DropdownListZodiac
							defaultValue={this.state.formSearchCM.zodiacs}
							onData={(value) =>
								this.handleChangeValue(value, 'zodiacs', 'text')
							}
							labelText={''}
						/>
					</div>
					<div className="row-box-inline-CM">
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastTimeCallingFrom
							}
							labelText={'最終対応日時'}
							middleLine={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastTimeCallingFrom',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastTimeCallingTo
							}
							iconRight={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastTimeCallingTo',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastLoginTimeFrom
							}
							labelText={'最終ログイン日時'}
							middleLine={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastLoginTimeFrom',
									'dateRange'
								)
							}
							classLabelTop={'label-margin-right'}
						/>
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastLoginTimeTo
							}
							iconRight={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastLoginTimeTo',
									'dateRange'
								)
							}
						/>
					</div>
					<div className="row-box-inline-CM">
						<SelectBox
							defaultValue={this.state.formSearchCM.isPurchase}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'isPurchase',
									'text'
								)
							}
							labelText={'最終ログイン日時'}
						/>
						<DropdownListWorries
							defaultValue={this.state.formSearchCM.worries}
							onData={(value) =>
								this.handleChangeValue(value, 'worries', 'text')
							}
							labelText={''}
						/>
						<div className="row-box-inline-CM-child text-right-row">
							<div className="">
								<label className="label-text-92797">
									<button
										className="btn-search-green"
										onClick={() => this.searchWidthParam()}
									>
										絞り込む
									</button>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div
					className={
						this.state.boxShowFormSeachMobile
							? 'frame-table-mobile'
							: 'frame-table-mobile hidden'
					}
				>
					<div className="label-text-92797">名前</div>
					<div className="row-box-inline-CM p-box-form">
						<InputUserName
							defaultValue={this.state.formSearchCM.userName}
							dataPlaceholder={'入力してください'}
							responsiveMobile={false}
							onData={(value) =>
								this.handleChangeValue(value, 'userName')
							}
							labelText={'名前'}
						/>
					</div>
					<div className="row-box-inline-CM">
						<InputUserGenderCheckbox
							defaultValue={this.state.formSearchCM.userGender}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'userGender',
									'text'
								)
							}
						/>
					</div>
					<div className={'label-text-92797 label-text-mobile'}>
						生年月日
					</div>
					<div className="row-box-inline-CM row-1">
						<DatePickerCM
							defaultValue={this.state.formSearchCM.birthdayFrom}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							labelText={'生年月日'}
							middleLine={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'birthdayFrom',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={this.state.formSearchCM.birthdayTo}
							iconRight={true}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'birthdayTo',
									'dateRange'
								)
							}
						/>
					</div>
					<label className="label-text-92797 label-text-mobile">
						星座
					</label>
					<div className="row-box-inline-CM row-1">
						<PopupZodiac
							defaultValue={this.state.formSearchCM.zodiacs}
							onData={(value) =>
								this.handleChangeValue(value, 'zodiacs', 'text')
							}
						/>
					</div>
					<div className={'label-text-92797 label-text-mobile'}>
						最終対応日時
					</div>
					<div className="row-box-inline-CM row-1">
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastTimeCallingFrom
							}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							labelText={'最終対応日時'}
							middleLine={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastTimeCallingFrom',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastTimeCallingTo
							}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							iconRight={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastTimeCallingTo',
									'dateRange'
								)
							}
						/>
					</div>
					<div className={'label-text-92797 label-text-mobile'}>
						最終ログイン日時
					</div>
					<div className="row-box-inline-CM row-1">
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastLoginTimeFrom
							}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							labelText={'最終ログイン日時'}
							middleLine={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastLoginTimeFrom',
									'dateRange'
								)
							}
						/>
						<DatePickerCM
							defaultValue={
								this.state.formSearchCM.lastLoginTimeTo
							}
							iconRight={true}
							labelHidden={true}
							responsiveMobile={'rowMobile'}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'lastLoginTimeTo',
									'dateRange'
								)
							}
						/>
					</div>
					<div className="label-text-92797 label-text-mobile">
						最終ログイン日時
					</div>
					<div className="row-box-inline-CM row-1">
						<SelectBox
							defaultValue={this.state.formSearchCM.isPurchase}
							labelHidden={true}
							onData={(value) =>
								this.handleChangeValue(
									value,
									'isPurchase',
									'text'
								)
							}
							labelText={'最終ログイン日時'}
						/>
					</div>
					<div className="label-text-92797 label-text-mobile">
						今の悩み
					</div>
					<div className="row-box-inline-CM row-1">
						<PopupWorries
							defaultValue={this.state.formSearchCM.worries}
							onData={(value) =>
								this.handleChangeValue(value, 'worries', 'text')
							}
						/>
					</div>
					<div className={'box-worries-value'}>
						{this.state.formSearchCM.worries.length > 0 &&
						this.state.formSearchCM.worries.length < 21
							? this.state.formSearchCM.worries.map(
									(item, key) => {
										let stringValue = 'worries' + item
										return (
											<span key={key}>
												{worriesData[stringValue].title}
												{key <
												this.state.formSearchCM.worries
													.length -
													1
													? ', '
													: ''}
											</span>
										)
									}
							  )
							: null}
					</div>
				</div>
				<div className="frame-table-mobile">
					<div className="row-box-inline-CM">
						{this.state.boxShowFormSeachMobile ? (
							<div className={'box-button-search-cm-mobile'}>
								<button
									className="btn-search-green-mobile"
									onClick={() => this.searchWidthParam()}
								>
									検索
								</button>
								<div
									className={
										'frame-table box-clear-search-data point-click clear-data-search-mobile'
									}
									onClick={() => this.boxClearSearch()}
								>
									<span className={'tick-clear-search'}>
										x
									</span>
									<span className={'text-title-clear-search'}>
										検索条件をクリア
									</span>
								</div>
								<div
									className={'close-box-search point-click'}
									onClick={() =>
										this.setState({
											boxShowFormSeachMobile:
												!this.state
													.boxShowFormSeachMobile,
										})
									}
								>
									閉じる
								</div>
							</div>
						) : (
							<button
								className="btn-search-green-mobile"
								onClick={() =>
									this.setState({
										boxShowFormSeachMobile:
											!this.state.boxShowFormSeachMobile,
									})
								}
							>
								検索
							</button>
						)}
					</div>
				</div>
				<SelectBoxList
					listData={selectDefaultSort}
					selected={selectedValueSort}
					onData={(value) =>
						this.handleSortColumn(
							'orderBy',
							value !== '' ? selectDefaultSort[value].sort : '',
							value !== '' ? selectDefaultSort[value].orderBy : ''
						)
					}
				/>
			</div>
		)
	}

	boxTableData = () => {
		return (
			<div className="primary">
				<div className="primary-title hidden-sp">
					<h1 className="primary-title__main">対応履歴</h1>
				</div>
				<div className="frame-table hidden-sp">
					<table className="table-data">
						<thead>
							<tr>
								{/*<th></th>*/}
								<th className="table-align-center">名前</th>
								<th
									className="table-align-center point-click"
									onClick={() =>
										this.handleSortColumn(
											'orderBy',
											orderByDefaultType.SuccessRequestChat
										)
									}
								>
									チャット相談回数{' '}
									{this.state.formSearchCM.sort === 2 &&
									this.state.formSearchCM.orderBy ===
										orderByDefaultType.SuccessRequestChat
										? '▼'
										: this.state.formSearchCM.sort === 1 &&
										  this.state.formSearchCM.orderBy ===
												orderByDefaultType.SuccessRequestChat
										? '▲'
										: ''}
								</th>
								<th
									className="table-align-center point-click"
									onClick={() =>
										this.handleSortColumn(
											'orderBy',
											orderByDefaultType.SuccessCall
										)
									}
								>
									電話相談回数{' '}
									{this.state.formSearchCM.sort === 2 &&
									this.state.formSearchCM.orderBy ===
										orderByDefaultType.SuccessCall
										? '▼'
										: this.state.formSearchCM.sort === 1 &&
										  this.state.formSearchCM.orderBy ===
												orderByDefaultType.SuccessCall
										? '▲'
										: ''}
								</th>
								<th
									className="table-align-center point-click"
									onClick={() =>
										this.handleSortColumn(
											'orderBy',
											orderByDefaultType.LastTimeCall
										)
									}
								>
									最終対応日時{' '}
									{this.state.formSearchCM.sort === 2 &&
									this.state.formSearchCM.orderBy ===
										orderByDefaultType.LastTimeCall
										? '▼'
										: this.state.formSearchCM.sort === 1 &&
										  this.state.formSearchCM.orderBy ===
												orderByDefaultType.LastTimeCall
										? '▲'
										: ''}
								</th>
								<th
									className="table-align-center point-click"
									onClick={() =>
										this.handleSortColumn(
											'orderBy',
											orderByDefaultType.LastTimeUserLogin
										)
									}
								>
									最終ログイン日時{' '}
									{this.state.formSearchCM.sort === 2 &&
									this.state.formSearchCM.orderBy ===
										orderByDefaultType.LastTimeUserLogin
										? '▼'
										: this.state.formSearchCM.sort === 1 &&
										  this.state.formSearchCM.orderBy ===
												orderByDefaultType.LastTimeUserLogin
										? '▲'
										: ''}
								</th>
								<th
									className="table-align-center point-click"
									onClick={() =>
										this.handleSortColumn(
											'orderBy',
											orderByDefaultType.UserIsPurchased
										)
									}
								>
									購入履歴{' '}
									{this.state.formSearchCM.sort === 2 &&
									this.state.formSearchCM.orderBy ===
										orderByDefaultType.UserIsPurchased
										? '▼'
										: this.state.formSearchCM.sort === 1 &&
										  this.state.formSearchCM.orderBy ===
												orderByDefaultType.UserIsPurchased
										? '▲'
										: ''}
								</th>
							</tr>
						</thead>
						<tbody>{this.setRows()}</tbody>
					</table>
				</div>
				<div className="customer-list-sp display-mobile">
					{this.setRowsSP()}
				</div>
			</div>
		)
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title">顧客管理</h1>
				<CustomerManagementMenu />
				{this.boxParamSearchForm()}
				{this.boxTableData()}
				{this.setPager()}
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		CustomerManagement: store.CustomerManagement,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		dispatch,
	}
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	return Object.assign({}, ownProps, stateProps, dispatchProps)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps
)(CustomerManagementFollower)
