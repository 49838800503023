/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import {
	Title,
	BackgroundUserAvatar,
	UserAvatar,
	UserName,
	CountdownTimer,
	ButtonArea,
	Button,
	NoticeArea,
	Notice,
} from '../StyledComponents/StyledDialogRequestCallComponents'
import { makeStyles } from '@mui/styles'
import { DEVICE } from '../../../util/StellaConstant'
import { BUTTON_TYPE, CALL_GENDER } from '../../../constants/CallConstants'
import { connect } from 'react-redux'
import Timer from '../frames/Timer'
import DialogAlertMicro from '../Dialog/DialogAlertMicro'
import { withRouter } from 'react-router-dom'
import { TELLER_STATUS } from '../../../constants/Config'
import * as MyProfileActions from '../../../actions/MyProfileActions'


const DialogRequestCall = (props) => {
	const classes = useStyles()
	const {
		show,
		closePreCallScreen,
		pickUp,
		microphones,
		CallStoredData,
		declineACall,
		pendingACall,
		MyProfile
	} = props


	const [open, setOpen] = useState(false)
	const [openAlertMicro, setOpenAlertMicro] = useState(false)

	const callParams = CallStoredData.callParams
	const gender = CALL_GENDER[callParams.gender]
	const useName = callParams.callerName
	const timer = callParams.timeoutSettings.ringingExpire
	const userId = callParams.caller

	useEffect(() => {
		setOpen(show)
	}, [show])

	const pickUpACall = async () => {
		if (microphones.length === 0) {
			setOpenAlertMicro(true)
		} else {
			if (MyProfile.data.fortuneTellerStatus !== TELLER_STATUS.CHATTING && MyProfile.data.fortuneTellerStatus !== TELLER_STATUS.CHATTING_FULL) {
				closeDialog()
				pickUp()
				MyProfileActions.refreshNewToken()
				// props.history.push(`/home/chat/${userId}`)
			}

		}
	}

	const pendingARequest = () => {
		closeDialog()
		pendingACall()
	}

	const declineARequest = () => {
		declineACall()
		closeDialog()
	}
	const closeDialog = () => {
		setOpen(false)
		closePreCallScreen()
	}
	return (
		<>
			<Dialog
				open={open}
				classes={{
					root: classes.root,
					paper: classes.paper
				}}
			>
				<Title>電話相談の申し込みが届いています</Title>
				<BackgroundUserAvatar>
					<UserAvatar userGender={gender} />
				</BackgroundUserAvatar>
				<UserName>{useName}さん</UserName>
				<CountdownTimer>
					<Timer
						expiryTimestamp={timer}
						functions={{
							sendDecline: props.declineACall,
							closeDialog
						}}
					/>
					秒
				</CountdownTimer>
				<ButtonArea>
					<Button
						buttonType={BUTTON_TYPE.ACCEPT}
						onClick={pickUpACall}
					>
						通話を開始
					</Button>
					<Button
						buttonType={BUTTON_TYPE.PENDING}
						onClick={pendingARequest}
					>
						保留
					</Button>
					<Button
						buttonType={BUTTON_TYPE.DECLINE}
						onClick={declineARequest}
					>
						この依頼を受け取らない
					</Button>
				</ButtonArea>
				<NoticeArea>
					<Notice>※原則、キャンセルはしないようにしてください</Notice>
					<Notice>※保留で現在入力中のチャットに戻れます</Notice>
				</NoticeArea>
				{openAlertMicro && <DialogAlertMicro
					showConfirm={openAlertMicro}
					closeConfirm={() => setOpenAlertMicro(false)}
				/>}
			</Dialog>

		</>
	)
}

const useStyles = makeStyles({
	root: {
		[`@media ${DEVICE.mobileS}`]: {
			zIndex: '999999 !important;',
		},
		[`@media ${DEVICE.tablet}`]: {
			zIndex: '999999 !important;',
		},
		[`@media ${DEVICE.laptop}`]: {
			zIndex: '999999 !important;',
		},
	},
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			width: 'calc(90% - 14px - 14px)',
			paddingLeft: 14,
			paddingRight: 14,
			paddingTop: 19,
			paddingBottom: 32,
			borderRadius: 4,
			margin: '0 !important',
		},
		[`@media ${DEVICE.tablet}`]: {
			maxWidth: '494px !important',
			width: '48%',
			paddingLeft: 49,
			paddingRight: 48,
			paddingTop: 36,
			paddingBottom: 43,
			borderRadius: 4,
		},
		[`@media ${DEVICE.laptop}`]: {
			maxWidth: '426px !important',
			width: '33%',
			paddingLeft: 49,
			paddingRight: 48,
			paddingTop: 36,
			paddingBottom: 43,
			borderRadius: 4,
		},
	},
})

const mapStateToProps = (state) => {
	return {
		CallStoredData: state.Call,
		MyProfile: state.MyProfile
	}
}

export default withRouter(connect(mapStateToProps)(DialogRequestCall))
