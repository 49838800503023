import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import request from 'axios'
import {
	glasConfig,
	QuestionCategory,
	QuestionStatusResponse,
} from './../constants/Config'
import moment from 'moment'
import TextField from 'material-ui/TextField'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import AnswerUserInfo from './AnswerUserInfo'
import AnswerOtherTeller from './AnswerOtherTeller'
import DialogReplyConfirm from './DialogReplyConfirm'
import DialogOtherAnswers from './DialogOtherAnswers'
import $ from 'jquery'
import Checkbox from 'material-ui/Checkbox'
import AnswerUserInfoSP from './AnswerUserInfoSP'
import Snackbar from 'material-ui/Snackbar'
import DialogTooFewCharacter from './DialogTooFewCharacter'

const MIN_CHARACTERS = 80
const LIST_CHECK_BOX = [
	{ value: 'ユーザー様の「匿名性」が守られているか？', name: 'checked1' },
	{
		value: '営業中心の回答になっていないか？（営業文は1~2行に留められているか）',
		name: 'checked2',
	},
	{
		value: '占い記事など他人の文章をそのまま引用していないか？',
		name: 'checked3',
	},
	{ value: '誰に対しても同じ回答をしていないか？', name: 'checked4' },
]

class AnswerDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			questionId: this.props.match.params.id,
			questionData: {},
			postDisabled: true,
			firstAnswersOfOtherTeller: [],
			content: '',
			postAnswerSuccess: false,
			answerPosted: null,
			userInfo: {},
			isBlocked: false,
		}
	}

	componentWillMount() {
		this.getQuestionById()
		this.getMyAnswer()
		
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.AnswerManagement.postAnswer &&
			this.state.questionId ==
				nextProps.AnswerManagement.postAnswer.questionId
		) {
			this.setState({
				postAnswerSuccess: true,
				answerPosted: nextProps.AnswerManagement.postAnswer,
			})
		}
	}

	loadUserData = (userId) => {
		const url = glasConfig.url_base + glasConfig.path_user_info + userId
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						userInfo: response.data.data,
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	getQuestionById = () => {
		const token = localStorage.getItem('token')
		request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					this.state.questionId,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ questionData: response.data.data }, () => {
						this.loadUserData(this.state.questionData.userId)
					})
				}
			})
			.catch((error) => console.log(error))
	}

	getMyAnswer = () => {
		const token = localStorage.getItem('token')
		request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					`myanswerdetail?questionId=${this.state.questionId}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ answerPosted: response.data.data })
				}
			})
			.catch((error) => console.log(error))
	}

	getQuestionCategory(category) {
		let textCategory = ''
		switch (category) {
			case QuestionCategory.MARRIAGE_OR_DIVORCE: {
				textCategory = '結婚/離婚'
				break
			}
			case QuestionCategory.MARRIAGE: {
				textCategory = '縁結び'
				break
			}
			case QuestionCategory.DIVORCE: {
				textCategory = '縁切り'
				break
			}
			case QuestionCategory.REPUDIATION: {
				textCategory = '復縁'
				break
			}
			case QuestionCategory.INFIDELITY: {
				textCategory = '不倫'
				break
			}
			case QuestionCategory.LOVE: {
				textCategory = '恋愛 '
				break
			}
			case QuestionCategory.WORK: {
				textCategory = ' 仕事'
				break
			}
			case QuestionCategory.INTERPERSONAL: {
				textCategory = '対人関係'
				break
			}
			case QuestionCategory.FORTUNE: {
				textCategory = '運勢'
				break
			}
			case QuestionCategory.LUCKY_MONEY: {
				textCategory = '金運'
				break
			}
			case QuestionCategory.METAL: {
				textCategory = 'メンタル'
				break
			}
			case QuestionCategory.OTHER: {
				textCategory = 'その他'
				break
			}
		}

		return textCategory
	}

	handleContentChange = (event) => {
		if (event.target.value.length > 500) {
			return false
		}
		let content = event.target.value
		let contentFormated = content.replace(/\n|\r|\s/g, '').length
		let disabled = true

		if (content.length !== 0 && contentFormated > 0) {
			disabled = false
		}
		this.setState({
			postDisabled: disabled,
			content,
		})
	}

	handleContentChangeSP = (event) => {
		if (event.target.value.length > 500) {
			return false
		}
		let content = event.target.value

		let contentFormated = content.replace(/\n|\r|\s/g, '').length
		if (contentFormated === 501) return
		let disabled = true
		if (content.length !== 0 && contentFormated > 0) {
			disabled = false
		}
		this.setState({
			postDisabled: disabled,
			content,
		})
	}

	handleSubmit = (event) => {
		if (this.state.userInfo.isBlocked) {
			event.preventDefault()
			this.setState({ isBlocked: true })

			setTimeout(() => {
				this.setState({ isBlocked: false })
			}, 1000)
		} else {
			event.preventDefault()
			if (this.state.content.trim().length < MIN_CHARACTERS) {
				this.dialogTooFewCharacter.openDialog()
				return
			}

			this.confirmReplyDialog.openDialog(() => this.acceptReply())
		}
	}

	handleSubmitSP = (event) => {
		if (this.state.userInfo.isBlocked) {
			event.preventDefault()
			this.setState({ isBlocked: true })
			setTimeout(() => {
				this.setState({ isBlocked: false })
			}, 1000)
		} else {
			event.preventDefault()
			if (this.state.content.trim().length < MIN_CHARACTERS) {
				this.dialogTooFewCharacter.openDialog()
				return
			}
			this.confirmReplyDialog.openDialog(() => this.acceptReplySP())
		}
	}

	acceptReply() {
		const { dispatch } = this.props
		let params = {}
		params.questionId = this.state.questionId
		params.content = this.state.content
		dispatch(AnswerManagementActions.postTellerAnswer(params))
		this.setState({ content: '' }, () => {
			this.getQuestionById()
		})
	}

	acceptReplySP() {
		const { dispatch } = this.props
		let params = {}
		params.questionId = this.state.questionId
		params.content = this.state.content
		dispatch(AnswerManagementActions.postTellerAnswer(params))
		this.setState({ content: '' })
		this.closeInput()
	}

	showAllOtherAnswers = () => {
		this.otherAnswersDialog.openDialog()
	}

	getQuestionStatus = (item) => {
		if (item.status === QuestionStatusResponse.ANSWERING) {
			let amountMiliSecondsOfOneHour = 3600 * 1000
			let amountMiliSecondsOfOneMinute = 60 * 1000
			let hours = Math.floor(
				item.deadLineToAnswer / amountMiliSecondsOfOneHour
			)
			let minutes = Math.floor(
				(item.deadLineToAnswer - hours * amountMiliSecondsOfOneHour) /
					amountMiliSecondsOfOneMinute
			)
			return `回答受付期限: 残り${hours}時間${minutes}分`
		} else {
			return '回答は締め切られています'
		}
	}

	showInput = () => {
		$('.timeline-form-wrap-sp').removeClass('hiddenClass')
		$('.timeline-form div div').addClass('showText')
		$('.layout_timeline').addClass('hiddenClass')
		$('.navbar-fixed-bottom').addClass('hiddenClass')
		$('.content').addClass('hidden-sp')
	}

	closeInput = () => {
		$('.timeline-form-wrap-sp').addClass('hiddenClass')
		$('.timeline-form div div').removeClass('showText')
		$('.layout_timeline').removeClass('hiddenClass')
		$('.navbar-fixed-bottom').removeClass('hiddenClass')
		$('.content').removeClass('hidden-sp')
	}

	closeUserInfoSP = () => {
		const className = $('.formAnswer').attr('class')
		if (className.includes('hidden-sp')) {
			$('.formAnswer').removeClass('hidden-sp')
		}
		$('.answer-user-profile-sp').removeClass('show_answer-user-profile-sp')
	}

	handleClick = (e, target) => {
		this.setState({ [target]: e.target.checked })
	}

	render() {
		const {
			questionData,
			answerPosted,
			postAnswerSuccess,
			postDisabled,
			checked1,
			checked2,
			checked3,
			checked4,
		} = this.state
		const questionCategory = this.getQuestionCategory(questionData.category)
		const questionStatus = this.getQuestionStatus(questionData)
		const questionCreateTime = new Date(questionData.createTime)
		const questionCreateTimeFormated =
			moment(questionCreateTime).format('YYYY/MM/DD')
		const replyLength =
			glasConfig.answermanagement.answerReplyLength >
			this.state.content.length
				? glasConfig.answermanagement.answerReplyLength -
				  this.state.content.length +
				  ' / 500'
				: ''
		const isBtnDisable =
			!postDisabled && checked1 && checked2 && checked3 && checked4
				? false
				: true

		return (
			<div>
				<div className="timeline-form-wrap-sp hiddenClass">
					<form
						name="replyFormSP"
						onSubmit={(e) => this.handleSubmitSP(e)}
						className="formAnswer"
					>
						<div className="timeline-form-main timeline-form-main-answer">
							<div className="timeline-form timeline-answer">
								<TextField
									placeholder={
										'あなたの回答は、全ユーザー・占い師が閲覧可能です。あなたのプロフィール情報は回答が公開後すぐ、ユーザーのみに公開されます。カルテに書かれた相談者や相手の名前や個人情報は入力しないでください。'
									}
									multiLine={true}
									type="textarea"
									name="replyContentSP"
									onChange={(event) =>
										this.handleContentChangeSP(event)
									}
									value={this.state.content}
									fullWidth={true}
									underlineShow={false}
									maxLength={500}
									// floatingLabelFixed={true}
									// floatingLabelText={replyLength}
									// floatingLabelStyle={textfieldStyles.label}
									textareaStyle={{ fontSize: 14 }}
									className="replySP"
								/>
							</div>
						</div>
						<div className="timeline-form-actions timeline-answer-actions ">
							<div className="answer-detail-form__title">
								回答前にチェックしましょう
							</div>
							{LIST_CHECK_BOX.map((item, index) => {
								return (
									<Checkbox
										label={item.value}
										onCheck={(e) =>
											this.handleClick(e, item.name)
										}
										className={`answer-detail-form__checkbox ${
											this.state[item.name] &&
											`answer-detail-form__checked`
										}`}
									/>
								)
							})}
							<hr></hr>
							<div className="btn-wrap">
								<button
									className="btn-raised color_default spread_width answer-detail-form__btn-submit"
									disabled={isBtnDisable}
								>
									回答する
								</button>
							</div>
							<div className="answer-detail-form__text-footer">
								上手な回答方法は
								<Link
									to="/home/staffblog/detail/2588"
									style={{ color: 'rgba(94, 202, 189, 1)' }}
								>
									【お仕事マニュアル】「教えて！先生」を有効活用しよう！
								</Link>
								を参考にしてください
							</div>
						</div>
					</form>
					{this.state.verifyAge}
				</div>
				<div className="answer-user-profile-sp hidden-sp hiddenPC ">
					<i
						className="material-icons tab__icons closeInfo"
						onClick={this.closeUserInfoSP}
					>
						clear
					</i>
					<AnswerUserInfoSP
						userId={questionData.userId}
						isShowMyProfile={questionData.isShowMyProfile}
					/>
				</div>
				<div className="content">
					<div className="answer-detail-left">
						<h1 className="content__title content-title-answer">
							相談内容
						</h1>
						<h1
							className="content__title hidden-sp"
							style={{ fontSize: 16 }}
						>
							<span
								style={{ color: 'rgb(17,173,157)' }}
								onClick={() => window.history.back()}
							>
								教えて！先生TOP
							</span>{' '}
							> 相談内容
						</h1>
						<div className="detail-answer-item-content-box">
							<div className="detail-answer-item-top">
								<div className="detail-answer-item-category">
									{questionCategory}
								</div>
								<div className="detail-answer-item-username hidden-sp">
									{questionData.nickName}さん{' '}
									{questionData.age}歳
								</div>
								<div className="detail-answer-item-time hidden-sp">
									{questionCreateTimeFormated}
								</div>
								<div className="detail-answer-item-status">
									{questionStatus}
								</div>
							</div>
							<div className="infoSP hidden-pc">
								<div className="leftInfoSP">
									<div className="detail-answer-item-username-sp ">
										{questionData.nickName}さん{' '}
										{questionData.age}歳
									</div>
									<div className="detail-answer-item-time-sp">
										{questionCreateTimeFormated}
									</div>
								</div>
								{questionData.status !==
								QuestionStatusResponse.ANSWERING ? null : (
									<div
										className="total-answer-sp"
										onClick={this.showAllOtherAnswers}
									>
										<i className="material-icons btn__icons icon-amount-answer-other">
											comment
										</i>
										<span>
											{answerPosted && !postAnswerSuccess
												? questionData.totalAnswer - 1
												: questionData.totalAnswer}
										</span>
									</div>
								)}
							</div>
							<hr />
							<div className="detail-answer-item-content">
								<pre>{questionData.content}</pre>
							</div>
						</div>
						{questionData.status !==
						QuestionStatusResponse.ANSWERING ? (
							<div
								className="totalAnswerSP hidden-pc"
								onClick={this.showAllOtherAnswers}
							>
								<i className="material-icons btn__icons icon-amount-answer-other">
									comment
								</i>
								<span>
									その他の回答{' '}
									{answerPosted && !postAnswerSuccess
										? questionData.totalAnswer - 1
										: questionData.totalAnswer}
									件
								</span>
							</div>
						) : null}
						{this.state.answerPosted ? (
							<div className="wrapAnswerPosted">
								<div className="answerPostedTop">
									<span className="titleAnswerPosted">
										あなたの回答
									</span>
									{questionData.myAnswer ? (
										<div className="answerPostedLike">
											<i
												className={
													questionData.myAnswer
														.totalLike === 0
														? 'fas fa-heart fa-heart0'
														: 'fas fa-heart'
												}
											></i>
											<span>
												{
													questionData.myAnswer
														.totalLike
												}
											</span>
										</div>
									) : (
										<div className="answerPostedLike">
											<i className="fas fa-heart fa-heart0 "></i>
											<span>0</span>
										</div>
									)}
								</div>
								<div className="answerPosted hidden-sp">
									{answerPosted.isBestAnswer && (
										<div className="answerDetailLeft">
											<img src="/img/icBestAnswer.png" />
										</div>
									)}
									<div
										className="answerDetailRight"
										style={{
											width: answerPosted.isBestAnswer
												? '78%'
												: '100%',
										}}
									>
										<span className="answerPostedTime">
											<span className="hidden-sp">
												回答日
											</span>{' '}
											{moment(
												answerPosted.answerTime
											).format('YYYY/MM/DD hh:mm')}
										</span>
										<p
											style={{
												marginTop: 48,
												overflowWrap: 'break-word',
												whiteSpace: 'pre-wrap'
											}}
										>
											{answerPosted.answerContent}
										</p>
									</div>
								</div>
								<div className="answerPostedSP hiddenPC">
									<div className="answerPostedSPTop">
										{answerPosted.isBestAnswer && (
											<img
												src="/img/icBestAnswer.png"
												className="icBestAnswerSP"
											/>
										)}
										<span className="answerPostedTime">
											<span className="hidden-sp">
												回答日
											</span>{' '}
											{moment(
												answerPosted.answerTime
											).format('YYYY/MM/DD hh:mm')}
										</span>
									</div>
									<p
										style={{
											overflowWrap: 'break-word',
											marginTop: 16,
											whiteSpace: 'pre-wrap'
										}}
									>
										{answerPosted.answerContent}
									</p>
								</div>
							</div>
						) : questionData.deadLineToAnswer > 0 ? (
							<div className="detail-answer-item-reply-box">
								<form
									name="replyForm"
									onSubmit={(e) => this.handleSubmit(e)}
								>
									<div className="answer-form-main hidden-mobile">
										<div className="timeline-form">
											<TextField
												placeholder={
													'あなたの回答は、全ユーザー・占い師が閲覧可能です。あなたのプロフィール情報は回答が公開後すぐ、ユーザーのみに公開されます。カルテに書かれた相談者や相手の名前や個人情報は入力しないでください。'
												}
												textareaStyle={{
													marginBottom: 10,
													letterSpacing: 1,
													fontSize: 15,
												}}
												hintStyle={replyHintStyle}
												multiLine={true}
												type="textarea"
												name="replyContent"
												className="replyAnswer answer"
												onChange={(event) =>
													this.handleContentChange(
														event
													)
												}
												fullWidth={true}
												value={this.state.content}
												underlineShow={false}
												// ref={input => this.contentInput = input}
												maxLength="500"
												// floatingLabelText={replyLength}
												// floatingLabelFixed={true}
												// floatingLabelStyle={textfieldStyles.label}
												rows={3}
											/>
										</div>
									</div>
									<div className="timeline-form-actions answer-detail-form hidden-mobile">
										{/* <div className={'text-count-total'}>{this.state.content.length} / 500</div> */}
										<div className="answer-detail-form__title">
											回答前にチェックしましょう
										</div>
										{LIST_CHECK_BOX.map((item, index) => {
											return (
												<Checkbox
													label={item.value}
													onCheck={(e) =>
														this.handleClick(
															e,
															item.name
														)
													}
													className={`answer-detail-form__checkbox ${
														this.state[item.name] &&
														`answer-detail-form__checked`
													}`}
												/>
											)
										})}
										<hr></hr>
										<div className="btn-wrap">
											<button
												className="btn-raised color_default spread_width answer-detail-form__btn-submit"
												disabled={isBtnDisable}
											>
												回答する
											</button>
										</div>
										<div className="answer-detail-form__text-footer">
											上手な回答方法は{' '}
											<Link
												to="/home/staffblog/detail/2588"
												style={{
													color: 'rgba(94, 202, 189, 1)',
												}}
											>
												【お仕事マニュアル】「教えて！先生」を有効活用しよう！
											</Link>
											を参考にしてください
										</div>
									</div>
								</form>
								<div
									className="answer-write-down-sp hiddenPC"
									onClick={this.showInput}
								>
									<span>回答を送信しましょう</span>
									<img src="/img/pencil.png" />
								</div>
							</div>
						) : (
							<div></div>
						)}
					</div>
					<div
						className="answer-detail-right hidden-mobile"
						style={{ height: window.innerHeight - 30 }}
					>
						<div className="answer-user-profile">
							<AnswerUserInfo
								userId={questionData.userId}
								isShowMyProfile={questionData.isShowMyProfile}
							/>
						</div>
						<div className="answer-other-teller">
							<div className="answer-other-teller-top">
								<span className="answer-other-teller-title">
									他の先生の回答
								</span>
								<div className="answer-item-status-amount-answer-other">
									<i className="material-icons btn__icons icon-amount-answer-other">
										comment
									</i>
									<span>
										{answerPosted && !postAnswerSuccess
											? questionData.totalAnswer - 1
											: questionData.totalAnswer}
									</span>
								</div>
							</div>
							<AnswerOtherTeller
								questionId={this.state.questionId}
							/>
							<span
								className="see-all-answer-other"
								onClick={this.showAllOtherAnswers}
							>
								すべて見る
							</span>
						</div>
					</div>
					<DialogReplyConfirm
						ref={(ref) => (this.confirmReplyDialog = ref)}
						dispatch={this.props.dispatch}
					/>
					<DialogOtherAnswers
						ref={(ref) => (this.otherAnswersDialog = ref)}
						dispatch={this.props.dispatch}
						questionId={this.state.questionId}
						totalAnswer={
							answerPosted
								? questionData.totalAnswer == 0
									? 0
									: questionData.totalAnswer - 1
								: questionData.totalAnswer
						}
					/>
					<DialogTooFewCharacter
						ref={(ref) => (this.dialogTooFewCharacter = ref)}
					/>
				</div>
				<Snackbar
					open={this.state.isBlocked}
					message={
						'このユーザーをブロックしているので回答できません。'
					}
					autoHideDuration={1500}
				/>
			</div>
		)
	}
}

const textfieldStyles = {
	label: {
		bottom: -55,
		top: 'auto',
		right: -16,
	},
	// hint: {
	//   top: 0,
	//   bottom: "auto"
	// },
	// textarea: {
	//   marginTop: 0,
	//   marginBottom: 0,
	//   height: "100%"
	// },
	// block: {
	//   height: 44,
	//   width: '100%'
	// }
}

const replyHintStyle = {
	top: 12,
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}
export default connect(mapStateToProps)(AnswerDetail)
