/**
 * 通知ログ
 */
import React, { Component } from 'react'
import { glasConfig } from '../constants/Config'
import {
	notificationType,
	notificationTypeLink,
} from '../constants/ConfigNotifi'
import request from 'axios'
import moment from 'moment'
import ReactLoading from 'react-loading'
import $ from 'jquery'
import { Link } from 'react-router-dom'
require('moment/locale/ja')

export default class HeaderNotifi extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			page: 0,
			moreLink: true,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			this.loadNotifiCationLog({
				page: 0,
			})
		}
	}

	componentDidUpdate() {
		let dialog = this.dialog
		let nav = $('.secondary-header.navbar.navbar-default.navbar-fixed-top')
		if (typeof dialog !== 'undefined' && !nav.is(':visible')) {
			dialog = $(dialog)
			let menuItem = $('.global-header__notice').parent()
			let menuItemOffset = menuItem.offset()
			let menuItemWidth = menuItem.outerWidth()
			let dialogOffset = dialog.offset()
			let dialogWidth = dialog.outerWidth()
			let left = menuItemOffset.left + menuItemWidth / 2 - dialogWidth / 2
			dialog.css('left', left)
		}
	}

	/**
	 * 通知ログを取得
	 * @param {object} req
	 * @return {void}
	 */
	loadNotifiCationLog(req) {
		const params = {
			page: req.page,
			size: 5,
		}

		//this.loadTest(req)
		const url = glasConfig.url_base + glasConfig.path_jpns_notifications
		const token = localStorage.getItem('token')

		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0 && response.data.data.length) {
					const tmpdata = params.page
						? [...this.state.data, ...response.data.data]
						: response.data.data
					this.setState({
						data: tmpdata,
						page: params.page,
						moreLink:
							params.size > Object.keys(response.data.data).length
								? false
								: true,
					})
				}
			})
			.catch((error) => {})
	}

	render() {
		const { show, closeFunc } = this.props

		if (!show) {
			return null
		}

		return (
			<div>
				<div
					className="dialog-notice-wrap"
					onClick={() => closeFunc()}
				></div>
				<div
					className="dialog-notice"
					ref={(dialog) => {
						this.dialog = dialog
					}}
					style={{ display: 'flex', alignItems: 'center' }}
				>
					<NotifiList
						data={this.state.data}
						closeFunc={() => closeFunc()}
						loadFunc={() =>
							this.loadNotifiCationLog({
								page: this.state.page + 1,
							})
						}
						moreLink={this.state.moreLink}
					/>
				</div>
			</div>
		)
	}
}

class NotifiList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.data.length || nextProps.data.length) {
			this.setState({ loading: false })
		}
	}

	render() {
		const { data, closeFunc, loadFunc, moreLink } = this.props

		if (this.state.loading) {
			return (
				<ReactLoading
					type={'spin'}
					color={'rgb(17,173,157)'}
					style={{ marginLeft: '36%', width: 60 }}
					delay={0}
				/>
			)
		}

		if (!Object.keys(data).length && !this.state.loading) {
			return (
				<div
					className="dialog-notice__nocontent"
					onClick={() => closeFunc()}
				>
					<p>現在、通知はありません。</p>
				</div>
			)
		}

		return (
			<div>
				<ul
					className="list column_1"
					style={{
						maxHeight: '250px',
						overflowY: 'scroll',
					}}
					onClick={() => closeFunc()}
				>
					{Object.values(data).map((res, i) => {
						return (
							<li key={i} className="list__item">
								<Link
									to={notificationTypeLink(res)}
									style={{
										textDecoration: 'none',
										color: '#000',
									}}
								>
									<div className="dialog-notice__content">
										<p>
											{notificationType(
												res.notificationType,
												res.friendName
											)}
										</p>
										<p className="dialog-notice__time">
											{moment(res.time, 'YYYYMMDDHHmmss')
												.add(9, 'hours')
												.fromNow()}
										</p>
									</div>
								</Link>
							</li>
						)
					})}
				</ul>
				{moreLink ? (
					<a
						className="dialog-notice__anchor"
						onClick={() => loadFunc()}
					>
						もっと見る
					</a>
				) : (
					''
				)}
			</div>
		)
	}
}
