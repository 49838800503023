import {
	ANSWER_MANAGEMENT_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL,
	ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL,
	GET_QUESTION_BY_ID,
	POST_TELLER_ANSWER,
	GET_UNREAD_BEST_ANSWER,
	BEST_ANSWER_ADD,
	OPEN_WELCOME_ANSWER,
	CLOSE_WELCOME_ANSWER,
	ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2,
	ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2
} from '../constants/ActionTypes'

const initialState = {
	loading: false,
	data: {},
	isOpenWelComeAnswer: false,
	goodAnswer:[]
}

export const AnswerManagement = (state = initialState, action) => {
	switch (action.type) {
	case ANSWER_MANAGEMENT_ANSWER_GET_ALL:
		return Object.assign({}, state, action.data)
	case GET_QUESTION_BY_ID:
		return Object.assign({}, state, action.data)
	case ANSWER_MANAGEMENT_BEST_ANSWER_GET_ALL:
		return Object.assign({}, state, action.data)
	case ANSWER_MANAGEMENT_GOOD_ANSWER_GET_ALL:
		return {...state,goodAnswer:action.data}
	case ANSWER_MANAGEMENT_ANSWER_LOG_GET_ALL:
		return Object.assign({}, state, action.data)
	case POST_TELLER_ANSWER:
		return Object.assign({}, state, action.data)
	case GET_UNREAD_BEST_ANSWER:
		return Object.assign({}, state, action.data)
	case BEST_ANSWER_ADD:
		return Object.assign({}, state, action.data)
	case OPEN_WELCOME_ANSWER:
		return Object.assign({}, state, action.data)
	case CLOSE_WELCOME_ANSWER:
		return Object.assign({}, state, action.data)
	case ANSWER_MANAGEMENT_ANSWER_GET_ALL_V2:
		return Object.assign({}, state, action.data)
	case ANSWER_MANAGEMENT_ANSWER_GET_LOG_V2:
		return Object.assign({}, state, action.data)
	default:
		return state
	}
}
