import React, { Component } from 'react'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import * as Fetch from './../../util/Fetch'
import {
	ANSWER_TELLER,
	QuestionCategoryList,
} from './../../constants/Config'
import Checkbox from 'material-ui/Checkbox'

class AnswerCategoriesWeb extends Component {
	constructor(props) {
		super(props)
		this.state = {
			statusAnswer: localStorage.getItem('statusAnswer')
				? parseInt(localStorage.getItem('statusAnswer'))
				: 1,
			categoryAnswer: localStorage.getItem('categoryAnswer')
				? parseInt(localStorage.getItem('categoryAnswer'))
				: 0,
			sortByAnswer: localStorage.getItem('sortByAnswer')
				? parseInt(localStorage.getItem('sortByAnswer'))
				: 1,
				showTarget: false
		}
	}

	onSelectAnswerCategory = (event, index, value) => {
		this.setState({ categoryAnswer: value }, () =>
			this.props.changeConditionsSearchQuestionPC(this.state)
		)
	}

	handleClickCheckBox = (e) => {
		this.setState({ showTarget: e.target.checked }, () => 
			this.props.changeConditionsSearchQuestionPC(this.state)
		)
	}

	render() {
		const deviceType = Fetch.fetchUserAgent()
		return (
			<div className="answer-categories" id="category-answer_mangement">
				<ul className="list-answer-select">
					<span className="answer-category-title" id="title-category_web">絞り込み</span>
					<li className="answer-category-item">
						<SelectField
							onChange={this.onSelectAnswerCategory}
							value={this.state.categoryAnswer}
							style={{
								flex: 1,
								fontSize: '15px',
								width: 124,
								height: 32,
								top: '2px',
							}}
							selectedMenuItemStyle={{ color: 'rgb(17,173,157)' }}
							className="select-feild"
							id="category-answer_management"
							autoWidth={true}
							menuItemStyle={
								deviceType !== 3 ? { fontSize: 11 } : {}
							}
						>
							{QuestionCategoryList.map((item, index) => {
								return (
									<MenuItem
										value={item.value}
										primaryText={item.label}
										key={index}
									/>
								)
							})}
						</SelectField>
					</li>
					<label class="container-answer_checkbox" id="category-checkbox_management">
						{ANSWER_TELLER.LABEL_CHECKBOX_ANSWER_APPROVE}
						<input type="checkbox" onChange={(e) => 
							this.handleClickCheckBox(e)
						}/>
						<span class="checkmark"></span>
					</label>
				</ul>
			</div>
		)
	}
}

export default AnswerCategoriesWeb
