/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import useAgora from './hooks/useAgora'
import useAgoraRTC from './hooks/useAgoraRTC'
import CallDetail from './CallDetail'
import { connect } from 'react-redux'
import { OPEN_PRE_CALL_SCREEN, CLOSE_PRE_CALL_SCREEN, OPEN_IN_CALL_SCREEN, CLOSE_IN_CALL_SCREEN, UPDATE_CALL_PARAMS, SHOW_PENDING_BELL_CALL, HIDE_PENDING_BELL_CALL, RESET_CALL_PARAMS, CHANGE_JOINSTATE } from '../../constants/ActionTypes'
import { AGORA, CALLTYPE } from '../../constants/CallConstants'
import { updateCallParams } from '../../actions/CallActions'
import { SOUNDS } from '../../constants/Config'
import useSound from '../../hooks/useSound'
import IconPendingCall from './frames/IconPendingCall'
import { CallLogRef } from '../ChatUserLogCall'
import { requestChatReadFirstOpen, requestChatUnReadFirstOpen } from '../../actions/ChatRequestActions'
import User from '../../models/User'
import {
	CHANGE_STATUS
} from '../../constants/ActionTypes'
import { pauseSound } from '../../util/Sound'
const client = AgoraRTC.createClient({ codec: 'h264', mode: 'rtc' })

const CallController = props => {

	const {
		socket,
		CallStoredData,
		ChatRequestRead,
		openPreCallScreen,
		closePreCallScreen,
		openInCallScreen,
		closeInCallScreen,
		showPendingBellCall,
		hidePendingBellCall,
		dispatch,
		resetCallParams,
		history,
		pathname
	} = props
	let pongTimerInterval, pingInterval
	const [pongTimer, setPongTimer] = useState(0)
	const callParams = CallStoredData.callParams
	const [userID, setUserID] = useState('')
	const remainingTimeRef = useRef()

	const {
		leave,
		join,
		joinState,
		remoteUsers,
		mute,
		unMute
	} = useAgora(client)

	const {
		microphones,
		getMicrophones,
		getPlaybackDevices,
		onMicrophoneChanged,
		onPlaybackDeviceChanged
	} = useAgoraRTC()

	const { createSound, playSound, stopSound } = useSound()


	useEffect(() => {
		// CREATE A SOUND
		createSound({
			soundPath: SOUNDS.CALL.RINGING.PATH,
			soundName: SOUNDS.CALL.RINGING.NAME,
			attributes: {
				loop: true,
				autoplay: false
			}
		})
		// if (callParams.callee) {
		// 	playSound(SOUNDS.CALL.RINGING.NAME)
		// }
		// MICROPHONE AND PLAYBACK DEVICES
		// REQUEST PERMISSION FOR MICROPHONE AND PLAYBACK DEVICES
		const requestPermissions = async () => {
			await getMicrophones()
			await getPlaybackDevices()
		}
		if (microphones.length === 0) {
			requestPermissions()
		}

		checkDeviceListeners()

		window.addEventListener('beforeunload', onUnload)
		// HANDLE SOCKET RESPONSE


		return () => {
			window.removeEventListener('beforeunload', onUnload)
			stopSound(SOUNDS.CALL.RINGING.NAME)
			leave()
		}
	}, [])


	useEffect(() => {
		socket.on('CALL_MESSAGE', handleSocketCall)
	}, [callParams])

	useEffect(() => {

		const checkChatFirstOpen = (response) => {
			let params = {
				caller: response[0].fromId,
				callee: response[0].toId,
				owner: response[0].toId,
				gender: response[0].notifyMessage.gender,
				messageType: 'NOTIFY',
				sessionId: response[0].notifyMessage.sessionId,
				tokenId: response[0].notifyMessage.tokenId,
				socketId: socket.id,
				uid: response[0].notifyMessage.uid,
				callType: response[0].notifyMessage.callType,
				callerName: response[0].notifyMessage.callerName,
				timeStamp: null,
				timeoutSettings: {
					pong: response[0].notifyMessage.timeoutSettings.pongTimeOut,
					makeCall: response[0].notifyMessage.timeoutSettings.makeCallTimeOut,
					connect: response[0].notifyMessage.timeoutSettings.connectTimeOut,
					ringing: response[0].notifyMessage.timeoutSettings.ringingTimeOut,
					receiverNoAnswer: response[0].notifyMessage.timeoutSettings.receiverNoAnswer,
					pickup: response[0].notifyMessage.timeoutSettings.pickupTimeOut,
					ringingExpire: response[0].notifyMessage.timeoutSettings.ringingExpire,
				}
			}
			let paramsMessageToServer = {
				caller: response[0].fromId,
				callee: response[0].toId,
				owner: response[0].toId,
				messageType: CALLTYPE.NOTIFY_RECEIVED,
				sessionId: response[0].notifyMessage.sessionId,
				socketId: socket.id,
			}
			sendResponseMessageToServer(paramsMessageToServer)
			dispatch(updateCallParams(params))
			openPreCallScreen()
			playSound(SOUNDS.CALL.RINGING.NAME)
			setUserID(response[0].fromId)
		}


		dispatch(requestChatReadFirstOpen()).then(response => {
			if (response.length > 0 && response[0].notifyMessage !== null) {
				checkChatFirstOpen(response)
			}

		}).catch(e => {
			console.log('error', e)
		})

		dispatch(requestChatUnReadFirstOpen()).then(res => {
			if (res.length > 0 && res[0].notifyMessage !== null) {
				checkChatFirstOpen(res)
			}
		}).catch(e => {
			console.log('error', e)
		})

	}, [])

	// useEffect(() => {
	// 	// SHOW A CALL SCREEN WHEN ALL INFORMATION IS FILLED
	// 	if (callParams.callee) {
	// 		// openPreCallScreen()
	// 		playSound(SOUNDS.CALL.RINGING.NAME)
	// 	}
	// }, [callParams.callee])
	useEffect(() => {
		if (CallStoredData.inCall.status) {
			stopSound(SOUNDS.CALL.RINGING.NAME)

		}
	}, [CallStoredData.inCall.status])
	// HANDLE INCALL: PING TO SERVER
	useEffect(() => {
		dispatch({ type: CHANGE_JOINSTATE, data: joinState })
		if (joinState) {
			User.getSocket().on('disconnect', async (reason) => {
				console.log('%c [STELLA] DISCONNECTED DUE TO ', 'color: #0e93e0;background: #aaefe5;', reason)
				await User.connectToSocket()
				const params = {
					caller: callParams.caller,
					callee: callParams.callee,
					owner: callParams.owner,
					messageType: CALLTYPE.CALL_PING,
					sessionId: callParams.sessionId,
					socketId: callParams.socketId,
				}
				const dataReconnect = {
					...params,
					messageType: 'RECONNECT',
					socketId: User.getSocket().id
				}
				User.getSocket().emit('CALL_MESSAGE', JSON.stringify(dataReconnect))
			})
			// SEND A PING TO SERVER EVERY PING(MS)
			pingInterval = setInterval(() => {
				// PING TO SERVER
				sendPING()
			}, AGORA.PING_TIME)

			pongTimerInterval = setInterval(() => { setPongTimer(oldTimer => oldTimer + 1) }, 1000)
		}

		return () => {
			clearInterval(pingInterval)
			clearInterval(pongTimerInterval)
		}
	}, [joinState])

	// HANDLE INCALL: SEND ENDCALL IF CLIENT DID NOT RECEIVE PONG EVENT
	useEffect(() => {
		if (callParams.timeoutSettings.pong) {
			// IF CLIENT DIDN'T RECEIVE PONG EVENT IN PONGTIMEOUT SECOND, SEND ENDCALL TO SERVER
			if (pongTimer >= callParams.timeoutSettings.pong / 1000) {
				// SEND ENDCALL T0 SERVER
				// sendENDCALL(AGORA.ENDCALL_STATUS.TIMEOUT)
				endACall()
				dispatch({ type: CHANGE_STATUS, data: 1 })
			}
		}
	}, [pongTimer])


	const handleSocketCall = async (response = {}) => {
		pauseSound()
		const dataResponse = JSON.parse(response)
		console.log('%c [STELLA] RECEIVED A SOCKET FROM SERVER: ', 'color: red', dataResponse)
		setUserID(dataResponse.caller)
		// WHEN USER REQUEST A CALL, TELLER WILL BE RECEIVED TWO SOCKET MESSAGES IN PARALLEL (CONNECT & NOTIFY)
		if (dataResponse.messageType === CALLTYPE.NOTIFY) {
			// SAVE CALL PARAMS
			saveCallParams(dataResponse)
			// SEND NOTIFY_RECEIVED TO SERVER
			sendNOTIFYRECEIVED(dataResponse)
			openPreCallScreen()
			playSound(SOUNDS.CALL.RINGING.NAME)
		} else if (dataResponse.messageType === CALLTYPE.STREAMING_READY) {
			// SHOW INCALL SCREEN
			openInCallScreen()

		} else if (dataResponse.messageType === CALLTYPE.REMAIN_TIME_CALL) {
			remainingTimeRef.current = dataResponse.remainingMinute
		}
		// HANDLE RECEIVE A PONG EVENT
		handleReceivePong(dataResponse)

		// HANDLE RECEIVE A STATE_END EVENT
		// include (user cancel a call, out of money)
		handleStateEnd(dataResponse)

		// HANDLE RECEIVE A ENDCALL EVENT
		handleReceiveEndCall(dataResponse)

	}

	const handleReceivePong = response => {
		setPongTimer(0)
	}


	// console.log('client', client)

	const handleStateEnd = async response => {
		if (response.messageType === CALLTYPE.STATE_END) {
			closeInCallScreen()
			leave()
			closePreCallScreen()
			stopSound(SOUNDS.CALL.RINGING.NAME)
			hidePendingBellCall()

		}
	}
	// let localStream;

	// useEffect(() => {
	// 	// if (CallStoredData.inCall.status) {
	// 	navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(function (stream) {
	// 		console.log('streamstream', stream)
	// 		// recorder = new MediaRecorder(stream);
	// 		localStream = stream;
	// 		var recorder = new MediaRecorder(stream);
	// 		console.log('window', window)
	// 		localStream.getTracks().forEach((track) => {
	// 			track.stop();
	// 		});
	// 		localStream.getAudioTracks().forEach((track) => {
	// 			track.stop();
	// 		});
	// 		recorder.stream.getAudioTracks().forEach(function (track) {
	// 			console.log('tracktrack', track)
	// 			track.stop()
	// 		});
	// 		recorder.stream.getTracks().forEach(function (track2) {
	// 			console.log('tracktrack2', track2)
	// 			track2.stop()
	// 		});
	// 	});
	// 	// }
	// }, [CallStoredData.inCall.status])


	const handleReceiveEndCall = response => {
		if (response.messageType === CALLTYPE.END_CALL) {
			if (joinState) {
				leave()
			}
		}
	}

	const sendNOTIFYRECEIVED = (response) => {
		const params = {
			caller: response.caller,
			callee: response.callee,
			owner: response.callee,
			messageType: CALLTYPE.NOTIFY_RECEIVED,
			sessionId: response.sessionId,
			socketId: callParams.socketId,
		}
		sendResponseMessageToServer(params)

	}

	const sendPING = () => {
		const params = {
			caller: callParams.caller,
			callee: callParams.callee,
			owner: callParams.owner,
			messageType: CALLTYPE.CALL_PING,
			sessionId: callParams.sessionId,
			socketId: callParams.socketId,
		}
		sendResponseMessageToServer(params)
	}

	const sendENDCALL = (status = AGORA.ENDCALL_STATUS.ERROR_STREAM) => {
		const params = {
			caller: callParams.caller,
			callee: callParams.callee,
			owner: callParams.owner,
			messageType: CALLTYPE.END_CALL,
			sessionId: callParams.sessionId,
			socketId: callParams.socketId,
			code: AGORA.ENDCALL[status].CODE,
			reason: AGORA.ENDCALL[status].REASON
		}
		sendResponseMessageToServer(params)
		// CLEAR ALL INTERVALS AFTER ENDING CALL
		clearInterval(pingInterval)
		clearInterval(pongTimerInterval)
	}

	const sendPICKUP = () => {
		const params = {
			caller: callParams.caller,
			callee: callParams.callee,
			owner: callParams.owner,
			messageType: CALLTYPE.PICK_UP,
			sessionId: callParams.sessionId,
			socketId: callParams.socketId,
		}
		sendResponseMessageToServer(params)
	}

	const sendDECLINE = () => {
		const params = {
			caller: callParams.caller,
			callee: callParams.callee,
			owner: callParams.owner,
			messageType: CALLTYPE.DECLINE_CALL,
			sessionId: callParams.sessionId,
			socketId: callParams.socketId,
		}
		sendResponseMessageToServer(params)
	}

	const sendResponseMessageToServer = (params = {}) => {
		console.log('%c [STELLA] SENT A SOCKET TO SERVER: ', 'color: red', params.messageType)
		console.log('%c [STELLA] SENT A SOCKET TO SERVER: ', 'color: red', params)
		socket.emit('CALL_MESSAGE', JSON.stringify(params))
	}

	const saveCallParams = (response) => {
		const params = {
			caller: response.caller,
			callee: response.callee,
			owner: response.callee,
			gender: response.gender,
			messageType: response.messageType,
			sessionId: response.sessionId,
			tokenId: response.tokenId,
			socketId: socket.id,
			uid: response.uid,
			callType: response.callType,
			callerName: response.callerName,
			timeStamp: response.timeStamp,
			timeoutSettings: {
				pong: response.timeoutSettings.pongTimeOut,
				makeCall: response.timeoutSettings.makeCallTimeOut,
				connect: response.timeoutSettings.connectTimeOut,
				ringing: response.timeoutSettings.ringingTimeOut,
				receiverNoAnswer: response.timeoutSettings.receiverNoAnswer,
				pickup: response.timeoutSettings.pickupTimeOut,
				ringingExpire: response.timeoutSettings.ringingExpire
			}
		}
		dispatch(updateCallParams(params))
	}

	const checkDeviceListeners = () => {
		onMicrophoneChanged()
		onPlaybackDeviceChanged()
	}

	const pickUpACall = () => {
		history.push(`/home/chat/${userID}`)
		stopSound(SOUNDS.CALL.RINGING.NAME)
		// SEND "PICK_UP" TO SERVER
		sendPICKUP()
		// openPreCallScreen()

	}

	const declineACall = () => {
		// SEND DECLINE TO SERVER
		resetCallParams()
		sendDECLINE()
		stopSound(SOUNDS.CALL.RINGING.NAME)
	}

	const pendingACall = () => {
		stopSound(SOUNDS.CALL.RINGING.NAME)
		closePreCallScreen()
		showPendingBellCall()
	}

	const endACall = () => {
		sendENDCALL(AGORA.ENDCALL_STATUS.SUCCESS)
		leave()

		setTimeout(() => {
			const isInChatScreen = window.location.pathname.includes('/chat/')
			if (isInChatScreen) {
				const userId = window.location.pathname.split('/').pop()
				CallLogRef.getModal().loadChatHistory({ userId })
			}
		}, 2000)
	}

	const onUnload = event => {

	}

	return (
		<>
			<CallDetail
				history={pathname}
				showPreCall={CallStoredData.preCall.status}
				showInCall={CallStoredData.inCall.status}
				callParams={callParams}
				closePreCallScreen={closePreCallScreen}
				closeInCallScreen={closeInCallScreen}
				pickUpACall={pickUpACall}
				declineACall={declineACall}
				pendingACall={pendingACall}
				endACall={endACall}
				remoteUsers={remoteUsers}
				mute={mute}
				unMute={unMute}
				join={join}
				joinState={joinState}
				socket={socket}
				microphones={microphones}
				resetCallParams={resetCallParams}
				remainingTime = {remainingTimeRef.current}
			/>
			<IconPendingCall />
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		CallStoredData: state.Call,
		ChatRequestRead: state.ChatRequestRead
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openPreCallScreen: () => dispatch({ type: OPEN_PRE_CALL_SCREEN }),
		closePreCallScreen: () => dispatch({ type: CLOSE_PRE_CALL_SCREEN }),
		openInCallScreen: () => dispatch({ type: OPEN_IN_CALL_SCREEN }),
		closeInCallScreen: () => dispatch({ type: CLOSE_IN_CALL_SCREEN }),
		showPendingBellCall: () => dispatch({ type: SHOW_PENDING_BELL_CALL }),
		hidePendingBellCall: () => dispatch({ type: HIDE_PENDING_BELL_CALL }),
		resetCallParams: () => dispatch({ type: RESET_CALL_PARAMS }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CallController)
