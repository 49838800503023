import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as TimelineActions from '../actions/TimelineActions'
import {
	glasConfig,
	TimeLineTabDefine,
	TimeLineTab,
	TimeLineContentType,
	Gender,
} from '../constants/Config'
import * as Fetch from '../util/Fetch'
import * as moment from 'moment'
import TimelineLikeContent from './TimelineLikeContent'
import TimelineCommentContent from './TimelineCommentContent'
import { Link } from 'react-router-dom'
import SparkMD5 from 'spark-md5'
import TextField from 'material-ui/TextField'
import $ from 'jquery'
import { isMobile } from 'react-device-detect'

class TimelineContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			postTab: TimeLineTab[TimeLineTabDefine.GET_ID],
			postPage: null,
			postSize: glasConfig.timeline.getPostSize,
			attachFile: null,
			attachFileName: null,
			postDisabled: 'disabled',
			timelineContent: [],
			md5Sum: '',
			verifyAge: null,
			showAlls: {},
			showLikes: {},
			arrowLikes: {},
			showComments: {},
			arrowComments: {},
			dataloaded: false,
			deletedPostId: 0,
		}
		this.handleScroll = this.handleScroll.bind(this)
		this.scrollEvent = false
	}

	componentWillMount() {
		const { dispatch } = this.props
		dispatch(
			TimelineActions.timelineGet(
				this.createUrlQuery(
					Object.assign({}, this.state, { postPage: 0 })
				),
				0,
				0
			)
		)
	}

	componentWillReceiveProps(nextProps) {
		const { dispatch } = this.props

		// タイムライン投稿時は再取得
		if (nextProps.Timeline.postTimeline) {
			dispatch(
				TimelineActions.timelineGet(
					this.createUrlQuery({ ...this.state, postPage: 0 }),
					0,
					0
				)
			)
		}

		if (
			!this.props.Timeline.timelineDelSuccess &&
			nextProps.Timeline.timelineDelSuccess
		) {
			document.getElementById(
				'postId-' + this.state.deletedPostId
			).style.display = 'none'
		}

		this.scrollEvent = false

		let postPage = nextProps.Timeline.postPage || 0

		let verifyAge = null
		if (
			nextProps.MyProfile &&
			nextProps.MyProfile.data.verifyAge.toLowerCase() !== 'verified'
		) {
			verifyAge = (
				<div className="timeline-form-cover">
					<div className="timeline-form-cover__inner">
						<p>年齢認証を行なっていないため、投稿できません。</p>
						<div className="btn-wrap">
							<Link
								to="/home/setting/ageauth"
								className="btn-raised color_default"
							>
								年齢認証を行う
							</Link>
						</div>
					</div>
				</div>
			)
		}

		let dataloaded = true

		this.setState({
			verifyAge,
			postPage,
			dataloaded,
		})
	}

	checkPage(nextProps) {
		if (
			this.props.Timeline.postPage === null &&
			nextProps.Timeline.postPage === 0
		) {
			return true
		}
		if (this.props.Timeline.postPage < nextProps.Timeline.postPage) {
			return true
		}
		return false
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
		$('.timeline-form-wrap-sp').addClass('hiddenClass')
	}
	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	createUrlQuery(state) {
		const tellerId = Fetch.tellerId()
		return `?tab=${state.postTab}&page=${state.postPage}&size=${state.postSize}&fortuneTellerId=${tellerId}`
	}

	createTimelineContent(timelines) {
		let type = Object.prototype.toString.call(timelines)

		if (
			this.state.dataloaded === true &&
			type.indexOf('Object') !== -1 &&
			Object.keys(timelines).length === 0
		) {
			return (
				<li className="timeline-list__item">
					<section className="timeline">
						<div className="timeline__inner">
							まだ投稿がありません。投稿してみましょう！
						</div>
					</section>
				</li>
			)
		} else if (
			this.state.dataloaded === true &&
			type.indexOf('Array') !== -1 &&
			timelines.length === 0
		) {
			return (
				<li className="timeline-list__item">
					<section className="timeline">
						<div className="timeline__inner">
							まだ投稿がありません。投稿してみましょう！
						</div>
					</section>
				</li>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<li className="timeline-list__item">
					<section className="timeline">
						<div className="timeline__inner">
							タイムラインを読み込んでいます...
						</div>
					</section>
				</li>
			)
		} else if (type.indexOf('Array') !== -1 && timelines.length !== 0) {
			return Array.prototype.map.call(timelines, (current, index) => {
				let arrowComment = null
				if (this.state.showComments[current.postId]) {
					arrowComment = 'arrow_down'
				} else {
					arrowComment = 'arrow_up'
				}
				let arrowLike = null
				if (this.state.showLikes[current.postId]) {
					arrowLike = 'arrow_down'
				} else {
					arrowLike = 'arrow_up'
				}
				return (
					<li
						className="timeline-list__item"
						id={'postId-' + current.postId}
						key={current.postId}
					>
						<section
							className={`timeline ${!current.approved &&
								'timeline__background-pending'
								}`}
						>
							<div className="timeline__inner">
								<button
									className="btn timeline__btn-clear"
									onClick={(e) =>
										this.handleTimelineDelete(e, current)
									}
								>
									<i className="material-icons btn__icons">
										clear
									</i>
								</button>
								<p className="timeline__time">
									{this.checkDiffDate(current.modifiedTime)}
								</p>
								{this.getPostValue(
									current.postValues,
									current.postId
								)}
								<ul className="list">
									<li className="list__item">
										<button
											className={`btn timeline__btn ${arrowLike}`}
											onClick={(e) =>
												this.handleShowLike(
													e,
													current.postId,
													current.likeNumber
												)
											}
										>
											<i className="material-icons btn__icons">
												favorite
											</i>
											いいね {current.likeNumber}
										</button>
									</li>
									<li className="list__item">
										<button
											className={`btn timeline__btn ${arrowComment}`}
											onClick={(e) =>
												this.handleShowComment(
													e,
													current.postId,
													current.commentNumber
												)
											}
										>
											<i className="material-icons btn__icons">
												comment
											</i>
											コメント {current.commentNumber}
										</button>
									</li>
								</ul>
							</div>
							<TimelineLikeContent
								{...current}
								postId={current.postId}
								checkDiffDate={this.checkDiffDate}
								checkGender={this.checkGender}
								isView={this.state.showLikes[current.postId]}
							/>
							<TimelineCommentContent
								{...current}
								postId={current.postId}
								checkDiffDate={this.checkDiffDate}
								checkGender={this.checkGender}
								isView={this.state.showComments[current.postId]}
								confirmDelDialog={this.props.confirmDelDialog}
							/>
						</section>
					</li>
				)
			})
		}
	}

	checkDiffDate(fromDate) {
		let to = moment()
		let from = moment(fromDate, 'YYYYMMDDhhmmss')
		let diffDate = to.diff(from, 'days')
		let diffYear = to.diff(from, 'years')
		let offset =  -(new Date().getTimezoneOffset() / 60);
		let localFrom = from.add(offset, 'h')
		if (diffDate === 0) {
			return '今日' + localFrom.format('HH:mm')
		} else if (diffDate === 1) {
			return '昨日' + localFrom.format('HH:mm')
		} else if (diffDate > 1) {
			return localFrom.format('MM/DD HH:mm')
		} else if (diffYear > 0) {
			return localFrom.format('YYYY/MM/DD HH:mm')
		}
	}

	checkGender(gender) {
		return Gender[gender]
	}

	getPostValue(postValues, currentPostId) {
		let values = Array.prototype.map.call(postValues, (current, index) => {
			if (current.type === TimeLineContentType.CONTENT) {
				let ret = []
				ret.push(
					<div
						className="timeline__main"
						key={SparkMD5.hash(current.value)}
					>
						{this.messageLimit(current.value, currentPostId)}
					</div>
				)

				let show = this.state.showAlls[currentPostId] || false

				let lines = (current.value.match(/\n/g) || []).length
				if (lines >= 5 && show === false) {
					ret.push(
						<button
							className="btn timeline__btn-more"
							onClick={(e) =>
								this.showAllTimeline(e, currentPostId)
							}
						>
							すべて表示
						</button>
					)
				}
				return ret
			} else if (current.type === TimeLineContentType.IMAGE) {
				return (
					<div
						className="timeline__main"
						key={SparkMD5.hash(current.value)}
					>
						<img src={current.value} />
					</div>
				)
			} else if (current.type === TimeLineContentType.VIDEO) {
				return (
					<div
						className="timeline__main"
						key={SparkMD5.hash(current.value)}
					>
						<video controls>
							<source
								src={glasConfig.url_base + current.value}
							></source>
						</video>
					</div>
				)
			}
		})

		return <div>{values}</div>
	}

	messageLimit(message, postId) {
		if (this.state.showAlls[postId]) {
			return this.nl2br(message)
		} else {
			return this.nl2br(
				message
					.split(/\r\n|\r|\n/)
					.slice(0, 5)
					.join('\n')
			)
		}
	}

	nl2br(str) {
		let regex = /\r\n|\r|\n/g
		return str.split(regex).map((line) => {
			return [line, <br />]
		})
	}

	showAllTimeline(event, postId) {
		let state = this.state.showAlls[postId] ? true : false
		let data = {}
		data[postId] = this.toggle(state)

		let showAlls = Object.assign([], this.state.showAlls, data)

		this.setState({
			showAlls,
		})
	}

	// いいね欄開閉
	handleShowLike(event, currentPostId, likeCount) {
		// いいね0なら何もしない
		if (parseInt(likeCount, 10) === 0) {
			return
		}

		let state = this.state.showLikes[currentPostId] ? true : false

		let dataLike = {}
		dataLike[currentPostId] = this.toggle(state)
		let showLikes = Object.assign([], this.state.showLikes, dataLike)

		let dataComment = {}
		dataComment[currentPostId] = false
		let showComments = Object.assign(
			[],
			this.state.showComments,
			dataComment
		)

		this.setState({
			showLikes,
			showComments,
		})
	}

	// コメント欄開閉
	handleShowComment(event, currentPostId, commentCount) {
		// コメント0ならなにもしない
		if (parseInt(commentCount, 10) === 0) {
			return
		}

		let state = this.state.showComments[currentPostId] ? true : false

		let dataComment = {}
		dataComment[currentPostId] = this.toggle(state)
		let showComments = Object.assign(
			[],
			this.state.showComments,
			dataComment
		)

		let dataLike = {}
		dataLike[currentPostId] = false
		let showLikes = Object.assign([], this.state.showLikes, dataLike)

		this.setState({
			showComments,
			showLikes,
		})
	}

	toggle(state) {
		return !state
	}

	handleClick(event) {
		event.preventDefault()
		document.timelineForm.timelineFile.click()
	}

	handleClickSP(event) {
		event.preventDefault()
		document.timelineFormSP.timelineFile.click()
	}

	handleChange(event) {
		event.preventDefault()
		if (document.timelineForm.timelineFile.files.length === 0) {
			this.handleClear(event)
			return
		}
		let fileName = document.timelineForm.timelineFile.files[0].name

		let fileReader = new FileReader()
		fileReader.onload = (event) => {
			this.setState({
				attachFile: fileReader.result,
				attachFileName: fileName,
			})
		}
		fileReader.readAsDataURL(document.timelineForm.timelineFile.files[0])
	}

	handleChangeSP(event) {
		event.preventDefault()
		if (document.timelineFormSP.timelineFile.files.length === 0) {
			this.handleClear(event)
			return
		}
		let fileName = document.timelineFormSP.timelineFile.files[0].name

		let fileReader = new FileReader()
		fileReader.onload = (event) => {
			this.setState({
				attachFile: fileReader.result,
				attachFileName: fileName,
			})
		}
		fileReader.readAsDataURL(document.timelineFormSP.timelineFile.files[0])
	}

	handleClear(event) {
		event.preventDefault()
		event.stopPropagation()

		document.timelineForm.timelineFile.value = ''
		this.setState({
			attachFile: null,
			attachFileName: null,
		})
	}

	handleClearSP(event) {
		event.preventDefault()
		event.stopPropagation()

		document.timelineFormSP.timelineFile.value = ''
		this.setState({
			attachFile: null,
			attachFileName: null,
		})
	}

	handleContentChange(event) {
		let content = document.timelineForm.timelineContent.value
		let disabled = 'disabled'
		if (content.length !== 0) {
			disabled = ''
		}
		this.setState({
			postDisabled: disabled,
		})
	}

	handleContentChangeSP(event) {
		$('.timeline-form div div').removeClass('showText')
		let content = document.timelineFormSP.timelineContent.value
		let disabled = 'disabled'
		if (content.length !== 0) {
			disabled = ''
		}
		this.setState({
			postDisabled: disabled,
		})
	}

	handleScroll(event) {
		const { dispatch } = this.props
		let rectClient = document
			.getElementById('timelineList')
		let rect = null
		if (rectClient) {
			rect = rectClient.getBoundingClientRect()
		}
		if (
			this.props.Timeline.autoLoad && rect !== null && rect.height !== null &&
			rect.height - glasConfig.timeline.autoLoadPixel <
			window.innerHeight + Math.abs(rect.top) &&
			this.scrollEvent === false
		) {
			let state = {
				postTab: this.state.postTab,
				postSize: this.state.postSize,
				postPage: parseInt(this.state.postPage, 10) + 1,
			}
			this.scrollEvent = true
			dispatch(
				TimelineActions.timelineGet(
					this.createUrlQuery(state),
					this.state.postPage,
					parseInt(this.state.postPage, 10) + 1
				)
			)
		}
	}

	handleSubmit(event) {
		event.preventDefault()

		const { dispatch } = this.props

		let params = {}
		let formData = new FormData()
		if (document.timelineForm.timelineFile.files.length === 0) {
			params.timelineData = document.timelineForm.timelineContent.value
			document.timelineForm.timelineContent.value = ''
			this.handleContentChange()

			dispatch(TimelineActions.timelinePost(params, this.props.Timeline))
		} else {
			formData.append('file', document.timelineForm.timelineFile.files[0])
			let fileReader = new FileReader()
			let spark = new SparkMD5.ArrayBuffer()
			fileReader.onload = (event) => {
				let data = event.target.result
				spark.append(data)
				let hash = spark.end()
				formData.append('md5Sum', hash)

				params.fileData = formData
				params.timelineData =
					document.timelineForm.timelineContent.value
				document.timelineForm.timelineContent.value = ''
				this.handleClear(event)
				this.handleContentChange()

				dispatch(TimelineActions.timelineFilePost(params, this.state))
			}
			fileReader.readAsArrayBuffer(
				document.timelineForm.timelineFile.files[0]
			)
		}
	}

	handleSubmitSP(event) {
		event.preventDefault()

		const { dispatch } = this.props

		let params = {}
		let formData = new FormData()
		if (document.timelineFormSP.timelineFile.files.length === 0) {
			params.timelineData = document.timelineFormSP.timelineContent.value
			document.timelineFormSP.timelineContent.value = ''
			this.handleContentChangeSP()

			dispatch(TimelineActions.timelinePost(params, this.props.Timeline))
			this.closeInput()
		} else {
			formData.append(
				'file',
				document.timelineFormSP.timelineFile.files[0]
			)
			let fileReader = new FileReader()
			let spark = new SparkMD5.ArrayBuffer()
			fileReader.onload = (event) => {
				let data = event.target.result
				spark.append(data)
				let hash = spark.end()
				formData.append('md5Sum', hash)

				params.fileData = formData
				params.timelineData =
					document.timelineFormSP.timelineContent.value
				document.timelineFormSP.timelineContent.value = ''
				this.handleClearSP(event)
				this.handleContentChangeSP()

				dispatch(TimelineActions.timelineFilePost(params, this.state))
				this.closeInput()
			}
			fileReader.readAsArrayBuffer(
				document.timelineFormSP.timelineFile.files[0]
			)
		}
	}

	// handleTimelineDelete(event, postId) {
	//   const { dispatch } = this.props
	//   this.setState({ deletedPostId: postId }, () => {
	//     dispatch(TimelineActions.timelineDelete(postId))
	//   });
	// }

	acceptDeleteTimeline = () => {
		const { dispatch } = this.props
		dispatch(TimelineActions.timelineDelete(this.state.deletedPostId))
	}

	handleTimelineDelete(event, data) {
		const { confirmDelDialog } = this.props
		if (!data.approved) {
			return
		}
		this.setState({ deletedPostId: data.postId }, () => {
			confirmDelDialog.openDialog(() => this.acceptDeleteTimeline())
		})
	}

	showInput = () => {
		$('.timeline-form-wrap-sp').removeClass('hiddenClass')
		$('.timeline-form div div').addClass('showText')
		$('.layout_timeline').addClass('hiddenClass')
		$('.secondary-header').addClass('hiddenClass')
		$('.navbar-header').addClass('hiddenClass')
		$('.navbar-fixed-bottom').addClass('hiddenClass')
		$('.content').addClass('content-css')
		window.removeEventListener('scroll', this.handleScroll)
	}

	closeInput = () => {
		$('.timeline-form-wrap-sp').addClass('hiddenClass')
		$('.layout_timeline').removeClass('hiddenClass')
		$('.secondary-header').removeClass('hiddenClass')
		$('.navbar-header').removeClass('hiddenClass')
		$('.navbar-fixed-bottom').removeClass('hiddenClass')
		$('.menu-header').removeClass('hiddenClass')
		$('.content').removeClass('content-css')
		$('.timeline-form div div textarea').val('')
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		this.closeInput()
	}

	render() {
		let displayNone = {
			display: 'none',
		}

		let backgroundStyle = {
			backgroundImage: 'url(' + this.state.attachFile + ')',
		}

		let attach = null
		if (this.state.attachFileName) {
			attach = (
				<div
					className="seal timeline-form-actions__seal"
					onClick={(e) => this.handleClick(e)}
				>
					<figure className="seal-figure">
						<img
							className="seal-figure__image"
							src="/img/spacer.gif"
							style={backgroundStyle}
							alt=""
						/>
					</figure>
					<div className="seal-name">
						<p className="seal-name__text">
							{this.state.attachFileName}
						</p>
					</div>
					<button
						className="seal-btn"
						onClick={(e) => this.handleClear(e)}
					>
						<i className="material-icons seal-btn__icons">close</i>
					</button>
				</div>
			)
		}

		return (
			<div>
				<div className="timeline-form-wrap-sp">
					<div class="close-timeline">
						<button
							className="buttonClose"
							onClick={this.closeInput}
						>
							<i class="material-icons tab__icons icon-close">
								clear
							</i>
						</button>
					</div>
					<form
						name="timelineFormSP"
						onSubmit={(e) => this.handleSubmitSP(e)}
					>
						<div className="timeline-form-main">
							<div className="timeline-form">
								<TextField
									hintText="お客様へアピールするために、今日の運勢などを書いて投稿してみましょう"
									hintStyle={timelineHintStyle}
									rows="5"
									multiLine={true}
									type="textarea"
									name="timelineContent"
									onChange={(e) =>
										this.handleContentChangeSP(e)
									}
									fullWidth={true}
									underlineShow={false}
								/>
							</div>
						</div>
						<div className="timeline-form-actions">
							<button
								className="btn timeline-form-actions__file"
								onClick={(e) => this.handleClickSP(e)}
							>
								<i className="material-icons btn__icons">
									insert_photo
								</i>
							</button>
							<input
								type="file"
								style={displayNone}
								name="timelineFile"
								onChange={(e) => this.handleChangeSP(e)}
							/>
							{attach}
							<div className="btn-wrap timeline-form-actions__submit">
								<button
									className="btn-raised color_default spread_width"
									disabled={this.state.postDisabled}
								>
									送信
								</button>
							</div>
						</div>
					</form>
					{this.state.verifyAge}
				</div>
				<div className="content__inner layout_timeline">
					<h1 className="content__title">タイムライン</h1>
					<div className="timeline-form-wrap hidden-sp">
						<form
							name="timelineForm"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<div className="timeline-form-main">
								<div className="timeline-form">
									<TextField
										placeholder={isMobile ? "お客様へアピールするために、今日の運勢などを書いて投稿してみましょう" : "お客様へアピールするための情報、今後の待機状況などを投稿してみましょう。 \n※個人のホームページやLINE、SNS(TwitterやFacebook）などの宣伝・誘導は投稿できません。"}
										hintStyle={timelineHintStyle}
										className="timeline-placeholder"
										rows="5"
										multiLine={true}
										type="textarea"
										name="timelineContent"
										onChange={(e) =>
											this.handleContentChange(e)
										}
										fullWidth={true}
										underlineShow={false}
									/>
								</div>
							</div>
							<div className="timeline-form-actions">
								<button
									className="btn timeline-form-actions__file"
									onClick={(e) => this.handleClick(e)}
								>
									<i className="material-icons btn__icons">
										insert_photo
									</i>
								</button>
								<input
									type="file"
									style={displayNone}
									name="timelineFile"
									onChange={(e) => this.handleChange(e)}
								/>
								{attach}
								<div className="btn-wrap timeline-form-actions__submit">
									<button
										className="btn-raised color_default spread_width"
										disabled={this.state.postDisabled}
									>
										送信
									</button>
								</div>
							</div>
						</form>
						{this.state.verifyAge}
					</div>
					<div
						className="timeline-write-down-sp"
						onClick={this.showInput}
					>
						<span>
							お客様へアピールするために、今日の運勢などを書いて投稿してみましょう
						</span>
						<img src="/img/pencil.png" />
					</div>
					<ul id="timelineList" className="timeline-list">
						{this.createTimelineContent(this.props.Timeline.data)}
					</ul>
				</div>
			</div>
		)
	}
}

const timelineHintStyle = {
	top: 12,
}

const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
		Timeline: store.Timeline,
	}
}

export default connect(mapStateToProps)(TimelineContent)
