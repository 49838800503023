import React, { Component } from 'react'
import * as Fetch from '../../util/Fetch'

class RewardInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
		}
	}

	render() {
		return (
            <div className="primary__inner">
			<div className="primary-content">
				<div className="primary-content__body">
					<div className="primary-title lv_2">
						<h1 className="primary-title__main">
							<span className="hidden-mobile">
								報酬確認・精算
							</span>
							<span className="display-mobile">精算について</span>
						</h1>
					</div>
					<div className="frame-table first-table">
						<table className="table-th-left margin_bottom">
							<tbody>
								<tr>
									<th className="table-align-left">
										最低精算額
									</th>
									{Fetch.checkChangeFeeDate() ? (
										<td className="table-align-left">
											5,550円 （初回は3,550円）
										</td>
									) : (
										<td className="table-align-left">
											5,540円 （初回は3,540円）
										</td>
									)}
								</tr>
								<tr>
									<th className="table-align-left">支払日</th>
									<td className="table-align-left">
										精算申請日から2営業日
									</td>
								</tr>
								<tr>
									<th className="table-align-left">
										振込名義
									</th>
									<td className="table-align-left">
										カ）ステラ
									</td>
								</tr>
								<tr>
									<th className="table-align-left">
										振込手数料
									</th>
									{Fetch.checkChangeFeeDate() ? (
										<td className="table-align-left">
											{this.props.commission}円
										</td>
									) : (
										<td className="table-align-left">
											1,080円→540円(オープン記念で手数料半額)
										</td>
									)}
								</tr>
							</tbody>
						</table>
					</div>
					<div className="primary-title lv_2">
						<h1 className="primary-title__main">支払日について</h1>
					</div>
					<div className="frame-table second-table">
						<table className="table-th-head">
							<thead>
								<tr>
									<th className="table-align-center">
										精算ボタンを押した日
									</th>
									<th className="table-align-center">
										口座への入金日時
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="table-align-center">
										月曜日
									</td>
									<td className="table-align-center">
										水曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										火曜日
									</td>
									<td className="table-align-center">
										木曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										水曜日
									</td>
									<td className="table-align-center">
										金曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										木曜日
									</td>
									<td className="table-align-center">
										翌週月曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										金曜日
									</td>
									<td className="table-align-center">
										翌週火曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										土曜日
									</td>
									<td className="table-align-center">
										翌週火曜日の9時〜15時の間
									</td>
								</tr>
								<tr>
									<td className="table-align-center">
										日曜日
									</td>
									<td className="table-align-center">
										翌週火曜日の9時〜15時の間
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		    </div>
		)
	}
}

export default RewardInfo
