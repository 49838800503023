/**
 * チャット画面 メッセージ履歴と入力フォーム
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  glasConfig,
  fortuneTellerStatus,
  LIMIT_SEND_PICTURES,
} from '../constants/Config'
import * as Fetch from '../util/Fetch'
import * as TemplateActions from '../actions/TemplateActions'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import * as RequestHistoryActions from '../actions/RequestHistoryActions'
import DialogNotifi from './Dialog'
import ChatContentMessage from './ChatContentMessage'
import moment from 'moment'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import ReactLoading from 'react-loading'
import request from 'axios'
import { FREE_SEND_TEXT_FROM_TELLER_STATUS } from '../constants/ConfigMessage'
import $ from 'jquery'
import ReactTooltip from 'react-tooltip'
import SparkMD5 from 'spark-md5'
import Snackbar from 'material-ui/Snackbar'
import { isMobile, isAndroid } from 'react-device-detect'
import DialogMessageOffline from './DialogMessageOffline'
import DialogConfirmSendTemplate from './DialogConfirmSendTemplate'
import DialogUnBlock from './DialogUnBlock'
import { removeItemFromList } from '../actions/ChatRequestActions'
import { addItemToList } from '../actions/ChatRequestActions'
import {
  CHAT_NUMBER_TOTAL,
  CHAT_REQUEST_MERGE_READ,
  CHAT_REQUEST_MERGE_UNREAD,
  CHAT_REQUEST_NEW,
  REQUEST_HISTORY_UNREAD_CHECK,
  CHAT_REQUEST_READ_REFRESH,
  CHAT_REQUEST_UNREAD_REFRESH,
  REQUEST_HISTORY_READ_REFRESH,
  REQUEST_HISTORY_UNREAD_REFRESH,
  REQUEST_PIN_CHAT,
} from '../constants/ActionTypes'
import * as ChatNumberTotalAction from '../actions/ChatNumberTotalAction'
import User from '../models/User'
import * as UserInfoActions from '../actions/UserInfoActions'
import { isPWA } from '../helper/helpFunction'
import { UserInfo } from '../reducers/UserInfo'

export class ChatContentRef {
  static modal;
  static setModal(modal) {
    this.modal = modal;
  }
  static getModal() {
    return this.modal;
  }
}
class ChatContent extends Component {

  constructor(props) {
    super(props)
    /**
     * maxlength: 残り入力可能文字数
     * message: 入力されたメッセージ本文
     * submitEnter: true:Enterで送信, false:Enter + Shiftで送信、localStorageに設定を保存
     * canToll: true: 有料へ切り替えが可、false:不可
     * fee: true: 有料, false: 無料
     * freeDialog: true:無料メッセージダイアログ表示
     * chatlog: チャット履歴
     * typing: true:相手が入力中
     */

    this.state = {
      isSameWindowChat: false,
      userId: null,
      maxlength: this.props.numberFeasibleWord,
      message: '',
      submitEnter: this.getSubmitEnterSetting(),
      canToll: false,
      fee: false,
      feeSendTemplate: false,
      freeDialog: false,
      freeDialogSP: false,
      chatlog: [],
      readlog: {},
      typing: false,
      hasEmitTyping: false,
      showLoading: true,
      requestingUserId: '',
      pictures: [],
      openDialog: false,
      openDropPicture: false,
      count: 0,
      filePath: [],
      errorFile: false,
      errorShortPoint: false,
      messageFile: '',
      errorSendFile: false,
      errorSendLimitFile: false,
      isShowDialogMessageOffline: false,
      idMessageOfflineOpened: '',
      showDialogConfirmSendTemplate: false,
      dataTemplate: {},
      disableInput: false,
      chatDraft: "",
    }
    this.socket = User.getSocket()
    ChatContentRef.setModal(this)
    this.maxFile = 0;
    this.isSameWindowChat = false;
    this.firstTime = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sendFilePrice === 0) {
      this.maxFile = 5
    } else {
      let tmpFile = Math.floor(nextProps.pointUser / (-nextProps.sendFilePrice))
      this.maxFile = tmpFile > 0 ? tmpFile : - tmpFile
    }
    return true;
  }

  /**
   * localStorageに設定が保存されている送信のキーボード設定を取得
   * @return {boolean} true: Enterで送信、false: Enter + Shiftで送信
   */
  getSubmitEnterSetting() {
    const submitEnter = localStorage.getItem('message.submitEnter')
    return (submitEnter === 'false') ? false : Boolean(submitEnter)
  }

  componentWillMount() {
    const { dispatch, Template, UserInfo } = this.props
    const tellerId = Fetch.tellerId()
    if (!Template.data.length) {
      dispatch(TemplateActions.getTemplate(tellerId))
    }
  }

  componentDidMount() {
    this.receiveNotification();
    $('.menu-header').addClass('hidden-mobile');
    $('.noti_icon').addClass('hiddenClass');
    $('.pencil').removeClass('pencil').addClass('show-pencil');
    $('.user').removeClass('user').addClass('show-user');
    $('.link-to-home').removeClass('link-to-home').addClass('show-link-to-home');
    $('.message_text').addClass('hiddenClass');
    $('.user_name_hidden').removeClass('user_name_hidden').addClass('user_name_show');
    this.socket.on('connect', () => {
      console.log(
        '1233:',
        'color: #0e93e0;background: #aaefe5;',
        this.socket
      )
    })
  }

  componentWillUnmount() {
    this.props.dispatch({ type: REQUEST_HISTORY_UNREAD_CHECK, data: '' })
    $('.menu-header').removeClass('hiddenClass');
    $('.menu-header').removeClass('hidden-mobile');
    $('.noti_icon').removeClass('hidden-mobile');
    $('.noti_icon').removeClass('hiddenClass');
    $('.show-pencil').addClass('pencil').removeClass('show-pencil');
    $('.show-user').addClass('user').removeClass('show-user');
    $('.show-link-to-home').removeClass('show-link-to-home').addClass('link-to-home');
    $('.message_text').addClass('message_text').removeClass('hiddenClass');
    $('.user_name_show').addClass('user_name_hidden').removeClass('user_name_show');
  }

  /**
   * チャットログを取得する
   * 取得したと同時にサーバ側で既読に勝手になる
   * @param {object} req
   * @return {void}
   */
  loadChatHistory(req) {
    const { dispatch, ChatLog, MyProfile, userId } = this.props
    if (!req.userId) {
      this.setState({ showLoading: false });
      return null
    }
    this.setState({ showLoading: true });
    const chatlog = this.state.chatlog
    let params = {
      friendId: req.userId,
      size: (req.size) ? req.size : 15
    }
    if (req.fromTime) {
      params['fromTime'] = req.fromTime
    } else {
      //チャットログがすでに読まれていた場合は最後の日付からロードする
      if (chatlog.length && this.state.requestingUserId == req.userId) {
        params['fromTime'] = chatlog[0].serverTime
      }
    }
    if (req.toTime) {
      params['toTime'] = req.toTime
    }

    this.appendInputChat(req.userId)

    const url = glasConfig.url_base + glasConfig.path_chat_history
    const options = {
      method: 'GET',
      url: url,
      params,
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      json: true
    }

    return request(options)
      .then(response => {
        if (response.data.code === 0) {
          // this.updateSettingTellerFreeChat(req.userId)
          this.setState({
            showLoading: false,
            requestingUserId: req.userId
          })
          if (response.data.data.length) {

            let tmpChatlog = []
            if (this.state.chatlog.length) {
              if (isMobile) {
                tmpChatlog = response.data.data.reverse()
              } else {
                tmpChatlog = [...response.data.data.reverse(), ...this.state.chatlog]
              }
            } else {
              tmpChatlog = response.data.data.reverse()
            }
            this.setState({
              chatlog: tmpChatlog
            }, () => {
              setTimeout(() => {
                let elem = document.getElementsByClassName('chat-list-area')[0];
                if (isMobile) {
                  if (typeof elem != 'undefined' && this.firstTime) {
                    this.firstTime = false;
                    window.scrollTo(0, elem.scrollHeight)
                  }
                } else {
                  if (typeof elem != 'undefined' && this.firstTime) {
                    this.firstTime = false;
                    elem.scrollTop = elem.scrollHeight;
                  }
                }

              }, 1000);
            })

            if ((response.data.data[0].fromId !== req.userId && response.data.data[0].fromId !== MyProfile.data.fortuneTellerId)) {
              this.loadChatHistory(this.props.userId)
            }
          } else {
            //this.setSessionStorage('curScrollTop', 0)
          }
        } else {
          this.setSessionStorage('curScrollTop', 0)
        }
        return new Promise(function (resolve, reject) {
          resolve({ status: 200 })
        })
      })
      .catch(error => {
        this.setState({
          showLoading: false
        });
        this.setSessionStorage('curScrollTop', 0)
        return new Promise(function (resolve, reject) {
          resolve({ status: 200 })
        })
        //throw error
      })
  }

  loadChatHistoryMobile = (req) => {
    const { MyProfile, userId } = this.props
    if (!req.userId) {
      this.setState({ showLoading: false });
      return null
    }
    this.setState({ showLoading: true });
    const chatlog = this.state.chatlog

    let params = {
      friendId: req.userId,
      size: (req.size) ? req.size : 15
    };

    if (req.fromTime) {
      params['fromTime'] = req.fromTime
    } else {
      //チャットログがすでに読まれていた場合は最後の日付からロードする
      if (chatlog.length && this.state.requestingUserId == req.userId) {
        params['fromTime'] = chatlog[0].serverTime
      }
    }
    this.appendInputChat(req.userId)

    const url = glasConfig.url_base + glasConfig.path_chat_history
    const options = {
      method: 'GET',
      url: url,
      params,
      headers: {
        'Authorization': localStorage.getItem('token'),
        'Content-Type': 'application/json'
      },
      json: true
    }

    request(options)
      .then(response => {
        if (response.data.code === 0) {
          this.setState({
            showLoading: false,
            requestingUserId: req.userId
          })
          if (response.data.data.length) {

            let tmpChatlog = []
            if (this.state.chatlog.length) {
              tmpChatlog = [...response.data.data.reverse(), ...this.state.chatlog]
            }
            this.setState({
              chatlog: tmpChatlog
            }, () => {
              setTimeout(() => {
                let elem = document.getElementsByClassName('chat-list-area')[0];
                if (typeof elem != 'undefined' && this.firstTime) {
                  this.firstTime = false;
                  elem.scrollTop = elem.scrollHeight;
                }
              }, 1000);
            })
            if ((response.data.data[0].fromId !== req.userId && response.data.data[0].fromId !== MyProfile.data.fortuneTellerId)) {
              this.loadChatHistoryMobile(this.props.userId)
            }
          } else {
            //this.setSessionStorage('curScrollTop', 0)
          }
        } else {
          this.setSessionStorage('curScrollTop', 0)
        }
      })
      .catch(error => {
        this.setState({
          showLoading: false
        });
        this.setSessionStorage('curScrollTop', 0)
        //throw error
      })

  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.userId === this.state.userId && !this.isSameWindowChat) {
      //相談申請があり、申請承認をしていない場合はダイアログ表示
      this.isSameWindowChat = true;
      let ChatRequestUnReadOld = prevProps.ChatRequestUnRead.data;
      let ChatRequestReadOld = prevProps.ChatRequestRead.data;
      let ChatRequest = {};
      Object.assign(ChatRequest, ChatRequestUnReadOld);
      Object.assign(ChatRequest, ChatRequestReadOld);
      this.showDialog(prevProps.UserInfo, ChatRequest)
    }
    if (prevProps.userId !== this.props.userId) {
      if (this.props.UserInfo.isBlocked) {
        this.showPopUpUnBlock()
      }
      this.setState({
        fee: false
      })
    }
  }

  showPopUpUnBlock = () => {
    this.dialogUnBlock.openDialog(() => this.acceptUnBlock())
  }

  acceptUnBlock() {
    const friendId = this.props.userId
    request.delete(glasConfig.url_base + glasConfig.path_block + `?friendId=${friendId}`, {
      headers: {
        Authorization: localStorage.getItem('token')
      }
    })
      .then(response => {
        if (response.data.code === 0) {
          this.props.reload(friendId)
        }

        this.enableChatDraft(this.props.userId)
      })
      .catch(error => {
        throw error
      })
  }

  enableChatDraft(userId) {
    const { dispatch } = this.props
    let data = {
      'userId': userId,
      'serverTime': moment().utc().format("YYYYMMDDHHmmss")
    }
    dispatch(ChatRequestActions.changeStatusChatDraft(data))
  }

  /**
   * ユーザー情報の更新を受け取った場合、stateを更新する
   * @param {*} nextProps
   */
  componentWillReceiveProps(nextProps) {
    const { dispatch, userId } = this.props
    let params = {}
    let ChatRequestUnReadOld = nextProps.ChatRequestUnRead.data;
    let ChatRequestReadOld = nextProps.ChatRequestRead.data;
    let ChatRequest = {};
    Object.assign(ChatRequest, ChatRequestUnReadOld);
    Object.assign(ChatRequest, ChatRequestReadOld);
    const canToll = nextProps.numberFeasibleWord == FREE_SEND_TEXT_FROM_TELLER_STATUS ? false : this.canToll(nextProps.UserInfo, ChatRequest);
    if (nextProps.userId !== this.props.userId || this.state.userId === null) {
      this.isSameWindowChat = false;
      this.firstTime = true;
      params.userId = nextProps.userId
      params.maxlength = nextProps.numberFeasibleWord
      params.message = ''
      params.chatlog = []
      params.typing = false
      params.canToll = canToll
      // params.fee = canToll
      params.readlog = {}
      this.loadChatHistory({
        userId: nextProps.userId,
      })
      if (nextProps.userId !== undefined) {
        dispatch({ type: REQUEST_HISTORY_UNREAD_CHECK, data: nextProps.userId })
      }
      dispatch(RequestHistoryActions.merge({
        userId: nextProps.userId,
        data: {
          unreadMessageBadge: 0
        }
      }))
      if (canToll && ChatRequest[nextProps.userId]) {
        dispatch(ChatRequestActions.merge({
          userId: nextProps.userId,
          data: {
            unreadMessageBadge: 0
          }
        }))
      }

      let chatDraftOld = nextProps.ChatDraftData.data.find((item) => item.fromId == nextProps.userId || item.fromId == nextProps.toId)
      if (chatDraftOld && nextProps.RequestHistoryRead.data) {
        let serverTimeOld = Object.values(nextProps.RequestHistoryRead.data).find((item) => item.fromId == nextProps.userId || item.fromId == nextProps.toId)
        if (serverTimeOld) chatDraftOld.serverTime = serverTimeOld.serverTime
      }

    } else {
      if (ChatRequest[nextProps.userId] !== undefined) {
        // In case refresh broswer, type of message is not "RESPONSE_REQUEST_CHAT", so can not set state of fee and canToll
        // if ( nextProps.ChatRequest.data[nextProps.UserInfo.userId].msgType === "RESPONSE_REQUEST_CHAT" ) {
        if (this.state.canToll !== canToll) {
          params.canToll = canToll
          // params.fee = canToll
          params.maxlength = nextProps.numberFeasibleWord
        } else {
          params.maxlength = nextProps.numberFeasibleWord
        }
        // }
      } else {
        params.canToll = canToll
        // params.fee = this.state.fee
        params.maxlength = nextProps.numberFeasibleWord
      }

    }

    this.readMessageDownUnRead(nextProps.userId);
    this.setState(params)
    this.appendInputChat(nextProps.userId)
    // this.updateSettingTellerFreeChat(nextProps.userId)
  }


  /**
   * メッセージが入力されたイベント
   * @param {*} e
   * @return {void}
   */
  handleChange(e, typeSend) {
    if (!this.state.disableInput) {
      const { socket, UserInfo, userId } = this.props
      const value = e.target.value
      const length = value.replace(/\s/g, "").length
      const tellerId = Fetch.tellerId()
      const sendTimeUtc = moment().utc()
      //ダイアログが表示中は変更不可
      if (this.state.freeDialog) {
        return
      }

      this.setState({
        chatDraft: e.target.value
      })
      if (this.state.chatDraft) {
        this.setState({
          messageFile: e.target.value,
          message: e.target.value
        })
      }

      let chatDraft = {
        'fromId': userId,
        'value': value,
        'serverTime': moment().utc().format("YYYYMMDDHHmmss")
      }

      this.dataDraftMessageTellerChatUser(chatDraft)

      //有料かつ文字数オーバー
      if (length > this.state.maxlength && this.state.fee) {
        return
      }

      if (length > 0) {
        //文字入力中をwsへ
        if (length <= this.state.maxlength && !this.state.hasEmitTyping) {
          const wsbody = {
            msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
            fromId: tellerId,
            toId: userId,
            msgType: "TYPING"
          }
          socket.emit('typing', JSON.stringify(wsbody))
          this.setState({ hasEmitTyping: true });
        }
      } else if (length === 0) {
        const wsbody = {
          msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
          fromId: tellerId,
          toId: userId,
          msgType: "STOP_TYPING"
        }
        this.setState({ hasEmitTyping: false });
        socket.emit('stopTyping', JSON.stringify(wsbody))
      }

      //残り入力文字数と本文を保村
      if (typeSend === "sendMessageFile") {
        this.setState({
          messageFile: value
        })
      } else {
        this.setState({
          message: value,
        })
      }
    }
  }

  dataDraftMessageTellerChatUser(chatDraft) {
    const { userId, dispatch } = this.props

    if (localStorage.hasOwnProperty("draft-chat")) {
      let logChatDraft = JSON.parse(localStorage.getItem("draft-chat"))
      let checkDataOld = logChatDraft.find((item) => {
        if (chatDraft.fromId && item.fromId == chatDraft.fromId || item.fromId == userId) {
          return item
        }
      })

      if (checkDataOld) {
        this.setState({ chatDraft: "" })
        let data = logChatDraft.map((item) => {
          if (item.fromId == checkDataOld.fromId) {
            if (chatDraft.value != undefined) item.value = chatDraft.value
            if (chatDraft.serverTime) item.serverTime = chatDraft.serverTime
            if (item.status != undefined) item.status = chatDraft.status
            this.setState({ chatDraft: item.value })
          }
          return item
        })

        dispatch(ChatRequestActions.addChatDraft(data.filter((item) => item.value)))
      } else {
        dispatch(ChatRequestActions.addChatDraft([...logChatDraft, chatDraft]))
      }
    } else {
      dispatch(ChatRequestActions.addChatDraft([chatDraft]))
    }
  }

  removeDataDraft(userId) {
    const { dispatch, ChatDraftData } = this.props
    let data = ChatDraftData.data.filter((item) => {
      if (item.fromId != userId) return item
    })
    this.setState({ chatDraft: "" })
    dispatch(ChatRequestActions.addChatDraft(data))
  }


  appendInputChat(userId) {
    if (localStorage.hasOwnProperty("draft-chat")) {
      let ChatDrafts = JSON.parse(localStorage.getItem("draft-chat"))
      let stateMessage = ChatDrafts.find((item) => {
        if (userId === item.fromId) {
          return item
        }
      })

      if (stateMessage) {
        this.setState({
          chatDraft: stateMessage.value,
          messageFile: stateMessage.value,
          message: stateMessage.value
        })
      } else {
        this.setState({
          chatDraft: "",
          messageFile: "",
          message: ""
        })
      }
    }
  }

  updateLastMessage(message) {
    if (localStorage.hasOwnProperty('draft-chat')) {
      let ChatDrafts = JSON.parse(localStorage.getItem('draft-chat'))
      if (this.state.userId == message.toId) {
        let chatDraftOld = ChatDrafts.find(
          (item) => item.fromId == this.state.userId 
          )

        if (chatDraftOld) {
          chatDraftOld.serverTime = message.serverTime
          this.dataDraftMessageTellerChatUser(chatDraftOld)
        }
      }
    }
  }

  updateSettingTellerFreeChat(userId) {
    const { UserInfoData } = this.props

    if (UserInfoData.data.userId == userId) {
       this.setState({
        fee: !this.state.fee
      })
    }
  }


  //「Enterで送信」or「Enter+Shiftで送信」の切り替え
  handleChangeSubmit(e) {
    const submitEnter = !this.state.submitEnter
    localStorage.setItem('message.submitEnter', submitEnter)

    this.setState({
      submitEnter: submitEnter
    })
  }

  /**
   * 「有料」「無料」の切り替え
   * @param {*} e イベント
   * @return {void}
   */
  handleChangeFee(e) {
    const { dispatch, userId } = this.props
    //ダイアログ表示中は変更不可
    if (this.state.freeDialog) {
      return
    }
    const value = e.target.checked
    if (!value) {
      this.setState({
        disableInput: false
      })
    }
    this.setState({
      fee: value
    })
    // if (!isPWA()) {
    //   dispatch(UserInfoActions.updateTellerFreeChatSetting({
    //     isSettingFreeChat: !value,
    //     userId: userId
    //   }))
    // }
  }

  /**
   * WebSocketから送られてくるデータから、ユーザーの所持ポイントと入力文字数を更新
   * @param {object} data
   */
  updateUserCurPoint = data => {
    if (data.numberFeasibleWord == -1) {
      this.setState({ canToll: false, fee: false });
      return null;
    }
    if (data.numberFeasibleWord === undefined || !data.numberFeasibleWord) {
      return null
    }
    this.setState({
      maxlength: data.numberFeasibleWord
    })
  }

  /**
   * 通知を受信する
   */
  receiveNotification() {

    const { socket, userId } = this.props

    socket.on('response', (res) => {
      const message = JSON.parse(res)
      console.log('%c MESSAGE: ', 'color: red', message);
      if (message.msgType === "READ") {
        let readlog = this.state.readlog
        const readmsgs = message.value && (message.value).split(',')
        for (let i in readmsgs) {
          readlog[readmsgs[i]] = message.serverTime
        }
        this.setState({
          readlog: readlog
        })

        this.updateLastMessage(message)

      }
      else if (message.msgType === "AUTO_MESSAGE_PICK_BEST_ANSWER") {
        if (this.state.userId === message.toId || this.state.userId === message.fromId) {
          this.concatChatlog(message)
        }
      }
      else if (message.msgType === "SHOW_CHAT_UNOFFICIAL") {
        this.updateChatLog(message)
      }
      else if (message.msgType === "CHARGE_TEXT_UNOFFICIAL" || message.msgType === "SEND_FILE_UNOFFICIAL" || message.msgType === "SEND_STAMP_UNOFFICIAL") {
        if (this.state.userId === message.toId || this.state.userId === message.fromId) {
          this.updateUserCurPoint(message)
          this.concatChatlog(message)
          this.doReadMessages(message.fromId, [message.msgId]);
        }
      }
      else if (message.msgType === "FREE_TEXT_UNOFFICIAL") {
        if (this.state.userId === message.toId) {
          this.updateUserCurPoint(message)
          this.concatChatlog(message)
        }
      }

      else if (message.msgType === "SENT_TEXT") {
        message.msgType = (message.numberFeasibleWord === 0 && message.msgTypeSender == 'FREE_TEXT') ? "FREE_TEXT" : "CHARGE_TEXT"
        //所持ポイントと残り最大文字数の変更
        if (this.state.userId === message.toId) {
          this.updateUserCurPoint(message)
          this.concatChatlog(message)
        }
      } else if (message.msgType === "SENT_FILE" || message.msgType === "SENT_FILE_FREE" && this.state.userId === message.toId) {
        //所持ポイントと残り最大文字数の変更
        if (this.state.userId === message.toId) {
          this.updateUserCurPoint(message)
          this.concatChatlog(message)
        }
      }
      else if (message.msgType === "SENT_FAIL") {
        //メッセージの送信に失敗した時の送信文字数によるポイント変更が不明確なため強制リロード
        if (this.state.userId === message.toId) {
          DialogNotifi.getDialog().handleOpen('ALERT', {
            message: "メッセージの送信に失敗しました"
          })
          window.location.reload()
          return
        }
      } else if (message.msgType === "NOT_ENOUGH_POINT") {
        //メッセージ送信したが、ユーザーの所持ポイントが不足していた場合
        if (this.state.userId !== message.fromId) {
          DialogNotifi.getDialog().handleOpen('ALERT', {
            message: "ユーザーがメッセージを受信するためのポイントが不足しています",
            redirect: ""
          })
          return
        }
      } else if (message.msgType === "FREE_TEXT" || message.msgType === "CHARGE_TEXT"
        || message.msgType === "SEND_FILE" || message.msgType === "CHARGE_TEXT"
        || message.msgType === 'EXPECTED_REQUEST_CHAT' || message.msgType === 'CANCEL_EXPECTED_REQUEST_CHAT'
        || message.msgType === 'EXPECTED_REQUEST_CALL' || message.msgType === 'CANCEL_EXPECTED_REQUEST_CALL'
        || message.msgType === 'SEND_GIFT' || message.msgType === 'SEND_STAMP'
      ) {
        //有料・無料メッセージ受信
        if (this.state.userId === message.fromId) {
          this.concatChatlog(message)
          //既読にする
          this.doReadMessages(message.fromId, [message.msgId]);
          if (message.msgType === "CHARGE_TEXT" || message.msgType === "SEND_FILE" || message.msgType === "SEND_STAMP") {
            //所持ポイントと残り最大文字数の変更
            this.updateUserCurPoint(message)
          }
        }
      } else if (message.msgType === "USER_RECONNECT_CHAT_IN_BENCH_TIME") {
        this.concatChatlog(message)
      }
    })

    socket.on('userRequest', (res) => {
      const message = JSON.parse(res);
      console.log('userRequest', message);
      if (message.msgType === "CANCEL_REQUEST_CHAT" || message.msgType === "REQUEST_LIMIT_TIME") {
        //ダイアログ非表示
        if (message.fromId == this.state.userId) {
          this.concatChatlog(message);
        }

        DialogNotifi.getDialog().handleClose()
      } else if (message.msgType === "ACCEPT_CHAT") {
        if (!this.state.canToll) {
          const userId = this.state.userId;
          let listRequest = this.props.ChatRequest.data;
          if (!listRequest[userId]) {
            DialogNotifi.getDialog().handleOpen('CHAT', message)
          }
        }

        // }
      } else if (message.msgType === "CLOSE_CHAT"
        || message.msgType === "NO_CONNECT_AVAILABLE" || message.msgType === "USER_REQUEST_CHAT"
        || message.msgType === "USER_REQUEST_CALL"
      ) {
        if (message.fromId == this.state.userId) {
          // this.setState({ fee: true })
          this.concatChatlog(message);
          this.doReadMessages(message.fromId, [message.msgId]);
        }
      } else if (message.msgType === "NO_ACTION_LONG_TIME" && message.toId == this.state.userId) {
        this.concatChatlog(message);
      } else if (message.msgType === "SEND_MSG_ACCEPT_CHAT") {
        this.concatChatlog(message)
      } else if (message.msgType === 'CALL') {
        if (message.duration >= 0) {
          this.concatChatlog(message)
        }
      }
    })

    //相手が入力中・停止
    socket.on('typing', (res) => {
      const message = JSON.parse(res);
      if (message.fromId === this.state.userId) {
        this.setState({
          typing: true
        })
      }
    })

    socket.on('stopTyping', (res) => {
      const message = JSON.parse(res);
      if (message.fromId === this.state.userId) {
        this.setState({
          typing: false
        })
      }
    })
  }

  /**
   * @return {void}
   * @param toId
   * @param msgIds
   */
  doReadMessages(toId, msgIds) {
    const { socket, UserInfo, userId } = this.props;

    let urlParam = window.location.href;

    if (!msgIds.length || urlParam.indexOf(userId) === -1) {
      return
    }
    const tellerId = Fetch.tellerId()
    const wsbody = {
      msgId: `${tellerId}&${toId}&${moment.utc().format('YYYYMMDDhhmmssSSS')}`,
      fromId: tellerId,
      toId: toId,
      msgType: 'READ',
      value: msgIds.join(',')
    }
    socket.emit('newMessage', JSON.stringify(wsbody))
  }

  updateChatLog(message) {
    let newChatLog = this.state.chatlog
    this.state.chatlog.forEach((item, index) => {
      if (message.msgId === item.msgId) {
        newChatLog[index].isShow = true
      }
    })
    this.setState({ idMessageOfflineOpened: message.msgId, chatlog: newChatLog })
  }


  /**
   * チャットログを結合させる（受信、送信)
   * @param {object} message
   * @return {void}
   */
  concatChatlog(message) {
    let chatlog = []
    if (this.state.chatlog.length) {
      chatlog = [...this.state.chatlog, message]
      setTimeout(() => {
        let elem = document.getElementsByClassName('chat-list-area')[0];
        if (typeof elem != 'undefined') {
          elem.scrollTop = elem.scrollHeight;
        }
      }, 800);
    } else {
      chatlog[0] = message
    }
    this.setState({
      chatlog: chatlog
    })
  }

  /**
   * メッセージの送信（websocketで送信します。APIは使わないみたい)
   * @param {boolean} template テンプレートを送信する場合はテンプレート本文が渡される
   * @return {void}
   */
  async sendMessage(device, templateMsg = '', typeSend = '', submitted) {
    const { MyProfile, UserInfo, ChatRequestUnRead, ChatRequestRead, userId } = this.props
    let flagMessageOffline = await localStorage.getItem('flagMessageOffline')
    flagMessageOffline = (!flagMessageOffline || flagMessageOffline === null || flagMessageOffline === 'null') ? {} : JSON.parse(flagMessageOffline);

    let ChatRequestUnReadOld = ChatRequestUnRead.data;
    let ChatRequestReadOld = ChatRequestRead.data;
    let ChatRequest = {};
    Object.assign(ChatRequest, ChatRequestUnReadOld);
    Object.assign(ChatRequest, ChatRequestReadOld);
    if (!this.hasRequest(UserInfo, ChatRequest) && (this.state.message.length > 0 || this.state.messageFile.length > 0 || this.state.pictures.length > 0) && !submitted) {
      if ((flagMessageOffline[Fetch.tellerId()] !== moment().format("YYYYMMDD")) && this.state.fee) {
        this.setState({ isShowDialogMessageOffline: true })
        return
      }
    }
    let msgType = (this.state.fee) ? "CHARGE_TEXT" : "FREE_TEXT"
    let pictures = this.state.pictures || []
    let picturesError = this.state.picturesError || []
    let body = ''

    // if (device === 'sp' && templateMsg === '') {
    //   if (!this.state.fee) {
    //     this.setState({freeDialog:true});
    //     return;
    //   }
    // }
    if (device === "sp" && pictures.length > this.maxFile && this.state.fee) {
      this.setState({
        errorShortPoint: true
      })
      return;
    }

    //通話中はメッセージの送信ができない
    if (MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.calling) {
      DialogNotifi.getDialog().handleOpen('ALERT', {
        message: "現在通話中のためメッセージの送信はできません"
      })
      return
    }

    //年齢認証がまだの場合はダイアログ表示
    if (!Fetch.isAgeVerify(MyProfile.data.verifyAge)) {
      DialogNotifi.getDialog().handleOpen('AGEAUTH')
      return
    }

    if (templateMsg) {
      //テンプレートでの送信は無料メッセージに強制
      msgType = "FREE_TEXT"
      body = templateMsg
    } else {
      body = typeSend === "sendMessageFile" ? this.state.messageFile : this.state.message
    }
    if (pictures.length === 0 && body.replace(/\s/g, "").length === 0) {
      return
    }

    if (msgType === "CHARGE_TEXT" && this.state.maxlength < body.replace(/\s/g, "").length) {
      DialogNotifi.getDialog().handleOpen('ALERT', {
        message: "ユーザーがメッセージを受信するためのポイントが不足しています",
        redirect: ""
      })
      return
    }
    if (msgType === "CHARGE_TEXT" && picturesError.length) {
      this.setState({
        errorShortPoint: true
      })
      return
    }
    if (pictures.length > LIMIT_SEND_PICTURES) {
      this.setState({
        errorSendLimitFile: true
      })
      return
    }

    const pointUser = this.props.pointUser
    const sendTextPrice = - this.props.sendTextPrice
    const sendFilePrice = - this.props.sendFilePrice

    if (typeSend === "sendMessageFile") {
      if (pictures.length) {
        pictures.forEach(picture => this.sendFileMessage(picture))
      }
      if (msgType === "CHARGE_TEXT" && sendTextPrice && sendFilePrice && body.length) {
        let lengthTextFile = Math.floor((pointUser - sendFilePrice * pictures.length) / sendTextPrice)
        this.setState({
          maxlength: lengthTextFile
        })
        if (body.length < lengthTextFile) {
          this.sendTextMessage(body, msgType)
        }
      }
      if (msgType === "FREE_TEXT" && body.length) {
        this.sendTextMessage(body, msgType)
      }
      this.setState({
        message: '',
        messageFile: '',
      })
    } else {
      this.sendTextMessage(body, msgType)
    }
    this.setState({
      disableInput: false
    })
    this.handleClose()
    this.removeDataDraft(UserInfo.userId)
  }

  sendTextMessage(body, msgType) {
    const { dispatch, socket, UserInfo, MyProfile, ChatRequest, userId } = this.props
    const tellerId = Fetch.tellerId()
    const sendTimeUtc = moment().utc()

    const message = {
      msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
      fromId: tellerId,
      toId: userId,
      msgType: msgType,
      value: body
    }
    socket.emit('newMessage', JSON.stringify(message))

    const wsbody = {
      msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
      fromId: tellerId,
      toId: userId,
      msgType: "STOP_TYPING"
    }
    socket.emit('stopTyping', JSON.stringify(wsbody))
    this.setState({ hasEmitTyping: false });
    message.serverTime = sendTimeUtc.format('YYYYMMDDHHmmss')
    message.readTime = ''

    //送信後の残り入力可能文字数
    const restLength = (msgType === "FREE_TEXT" || !this.hasRequest(UserInfo, ChatRequest)) ? this.state.maxlength : this.state.maxlength - body.replace(/\s/g, "").length
    this.setState({
      message: '',
      maxlength: restLength
    })

    if (this.hasRequest(UserInfo, ChatRequest) === true) {
      //左カラムの対応中一覧を更新
      dispatch(ChatRequestActions.merge({
        userId: userId,
        data: {
          msgId: message.msgId,
          fromId: tellerId,
          toId: userId,
          value: message.value,
          msgType: "SENT_TEXT",
          serverTime: message.serverTime,
          unreadMessageBadge: 0,
        }
      }))
    } else {
      //左カラムの対応履歴一覧を更新
      dispatch(RequestHistoryActions.merge({
        userId: userId,
        data: {
          msgId: message.msgId,
          fromId: tellerId,
          toId: userId,
          value: message.value,
          msgType: "SENT_TEXT",
          serverTime: message.serverTime,
          unreadMessageBadge: 0,
        }
      }))
    }
  }

  sendFileMessage(picture) {
    const { dispatch, socket, UserInfo, ChatRequest, userId } = this.props
    const tellerId = Fetch.tellerId()
    const sendTimeUtc = moment().utc()
    if (picture) {
      let type = 'image/jpeg'
      let bin = atob(picture.URL.split(',')[1])
      let buffer = new Uint8Array(bin.length)
      let filePicture = picture.file
      for (let i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i)
      }
      let blob = new Blob([buffer.buffer], { type: type })
      let fileReader = new FileReader()
      fileReader.onload = (event) => {
        let spark = new SparkMD5.ArrayBuffer()
        spark.append(event.target.result)
        let hash = spark.end()
        let imgFormData = new FormData()
        imgFormData.append('file', blob, 'profile.jpg')
        imgFormData.append('md5Sum', hash)
        const data = {
          fileData: imgFormData,
        }
        request
          .post(glasConfig.url_base + glasConfig.path_file, data.fileData, {
            headers: {
              Authorization: localStorage.getItem('token')
            }
          })
          .then(response => {
            const prepareSendFile = {
              toId: userId,
              fileType: 'image',
              md5: hash,
              isFree: !this.state.fee
            }
            request
              .post(glasConfig.url_base + glasConfig.path_chat_file, prepareSendFile, {
                headers: {
                  Authorization: localStorage.getItem('token')
                }
              }).then(res => {
                if (res.data.code === 0) {
                  let filePath = response.data.data.filePath
                  let message = {
                    msgId: res.data.data.prepareMsgId,
                    fromId: tellerId,
                    toId: userId,
                    value: filePath,
                    prepareMsgId: res.data.data.prepareMsgId
                  }
                  message = Object.assign(this.state.fee ? { msgType: "SEND_FILE" } : { msgType: "SEND_FILE_FREE" }, message)
                  socket.emit('newMessage', JSON.stringify(message))
                  const wsbody = {
                    msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
                    fromId: tellerId,
                    toId: userId,
                    msgType: "STOP_TYPING"
                  }
                  socket.emit('stopTyping', JSON.stringify(wsbody))
                  this.setState({ hasEmitTyping: false });
                  message.serverTime = sendTimeUtc.format('YYYYMMDDHHmmss')
                  message.readTime = ''
                  if (!this.hasRequest(UserInfo, ChatRequest)) {
                    //左カラムの対応履歴一覧を更新
                    dispatch(RequestHistoryActions.merge({
                      userId: userId,
                      data: {
                        msgId: message.msgId,
                        fromId: tellerId,
                        toId: userId,
                        value: message.value,
                        msgType: "SENT_FILE",
                        serverTime: message.serverTime,
                        unreadMessageBadge: 0,
                      }
                    }))
                  }
                  else {
                    dispatch(ChatRequestActions.merge({
                      userId: userId,
                      data: {
                        msgId: message.msgId,
                        fromId: tellerId,
                        toId: userId,
                        value: message.value,
                        msgType: "SENT_FILE",
                        serverTime: message.serverTime,
                        unreadMessageBadge: 0,
                      }
                    }))
                  }
                } else {
                  this.setState({
                    errorSendFile: true
                  })
                }
              }
              )
          })
      }
      fileReader.readAsArrayBuffer(blob)
    }
  }

  /**
   * Enterキーが押されたときのイベント
   * @param {*} e イベント
   * @return {void}
   */
  handleSubmit(e) {
    const value = e.target.value
    const length = value.replace(/\s/g, "").length
    if (!this.state.message) {
      return
    }
    if (length >= this.state.maxlength && e.keyCode !== 8 && this.state.fee) {
      this.setState({
        disableInput: true
      })
      e.preventDefault();
    }
    if (e.keyCode === 8) {
      this.setState({
        disableInput: false
      })
      this.handleChange(e)
    }

    let send = false
    if (this.state.submitEnter) {
      if (!e.shiftKey && e.keyCode === 13) {
        send = true
      }
    } else {
      if (e.shiftKey && e.keyCode === 13) {
        send = true
      }
    }

    if (send) {
      e.preventDefault()
      // if (!this.state.fee) {
      //   //無料メッセージを送信しようとした場合はダイアログを表示
      //   this.handleShowDialogFreeMessage()
      //   return
      // }

      this.sendMessage('', '')
      return
    }

    // this.handleChange(e)

  }

  handleSubmitFile(e) {
    let send = false
    if (this.state.submitEnter) {
      if (!e.shiftKey && e.keyCode === 13) {
        send = true
      }
    } else {
      if (e.shiftKey && e.keyCode === 13) {
        send = true
      }
    }

    if (send) {
      e.preventDefault()
      this.sendMessage('', '', 'sendMessageFile')
      return
    }
    this.handleChange(e, "sendMessageFile")
  }
  /**
   * 有料無料の切り替えtoggleの表記
   * @return {string} 有料 or 無料
   */
  feeToString() {
    if (this.state.feeSendTemplate) {
      return '無料';
    }
    return this.state.fee ? "有料" : "無料"
  }

  /**
   * textareaに表示するplaceholder
   * @return {string} 有料 or 無料
   */
  submitToString() {
    const text = this.state.submitEnter ? "（Shift + Enterキーで改行）" : "（Shift + Enterキーで送信）"
    return this.feeToString() + 'メッセージを入力してください' + text
  }

  /**
   * 該当のユーザーが相談依頼リストに存在するかどうかの判定
   * @return {boolean}
   */
  hasRequest(UserInfo, ChatRequest) {
    return ChatRequest[UserInfo.userId];
  }

  /**
   * 有料メッセージ送信可(対応中ユーザー)かどうかの判定
   * @return {boolean}
   */
  canToll(UserInfo, ChatRequest) {
    // if (this.hasRequest(UserInfo, ChatRequest)) {
    //   if (ChatRequest[UserInfo.userId].msgType === 'ACCEPT_CHAT') {
    //     //申請中のため有料メッセージ送信不可
    //     return false
    //   }
    //   return true
    // }
    return true
  }

  /**
   * チャット相談申し込みダイアログの表示判定
   * @return {void}
   */
  showDialog(UserInfo, ChatRequest) {
    if (this.hasRequest(UserInfo, ChatRequest)) {
      if (ChatRequest[UserInfo.userId].msgType === 'ACCEPT_CHAT' && DialogNotifi.getDialog()) {
        DialogNotifi.getDialog().handleOpen('CHAT', ChatRequest[UserInfo.userId], true)
      }
    }
  }

  submittedDialogMessageOffline = () => {
    if (isMobile) {
      this.sendMessage('sp', '', 'sendMessageFile', true)
    } else {
      this.sendMessage('', '', this.state.pictures.length > 0 ? 'sendMessageFile' : '', true)
    }

    this.setState({ isShowDialogMessageOffline: false })
  }

  close = () => {
    this.setState({ isShowDialogMessageOffline: false, showDialogConfirmSendTemplate: false })
  }

  /**
   * 無料メッセージのダイアログの非表示
   * @param {boolean} doSend true:メッセージ送信
   * @return {void}
   */
  handleHideDialogFreeMessage(doSend) {
    this.setState({
      freeDialog: false
    })
    if (doSend) {
      this.sendMessage('', '', 'sendMessageFile')
      this.sendMessage()
    }
  }

  /**
   * 無料メッセージのダイアログの表示
   * @return {void}
   */
  handleShowDialogFreeMessage() {
    this.setState({
      freeDialog: true
    })
  }

  /**
   * テンプレートを送信する
   * @param {string} templateMsg
   */
  handleTemplateSubmit = (dataTemplate) => {

    if (!dataTemplate.templateContent) {
      DialogNotifi.getDialog().handleOpen('ALERT', {
        message: "テンプレートの本文が見つかりませんでした"
      })
      return
    }

    this.hideTemplates();
    this.handleShowDialogConfirmSendTemplate(dataTemplate);

    return;
  }

  handleShowDialogConfirmSendTemplate = async (dataTemplate) => {
    let flagConfirmSendTemplate = await localStorage.getItem('flagConfirmSendTemplate');
    flagConfirmSendTemplate = (!flagConfirmSendTemplate || flagConfirmSendTemplate === null || flagConfirmSendTemplate === 'null') ? {} : JSON.parse(flagConfirmSendTemplate);

    if (flagConfirmSendTemplate[Fetch.tellerId()] !== moment().format("YYYYMMDD")) {
      this.setState({ showDialogConfirmSendTemplate: true, dataTemplate: dataTemplate })
    }
    else {
      this.sendMessage('', dataTemplate.templateContent, '', true);
    }

  }

  handleBtnClick(event, device = '') {
    event.preventDefault()
    device === 'sp' ? document.sendPicturesSp.filePicturesSp.click() : document.sendPictures.filePictures.click()
  }

  handleDrop(e, device = '') {
    const files = e.target.files
    for (let index = 0; index < files.length; index++) {
      let file = files[index]
      if (!/(\.png|\.gif|\.jpg|\.jpeg)$/i.test(file.name)) {
        file = ''
        this.setState({
          errorFile: true,
        })
      } else {
        this.handleLoadImage(file, device)
      }
    }

    e.target.value = '';
  }

  handleLoadImage(file, device) {
    if (file) {
      let msgType = (this.state.fee) ? "CHARGE_TEXT" : "FREE_TEXT"
      const reader = new FileReader()
      reader.onloadend = () => {
        let pictures = this.state.pictures ? this.state.pictures : []
        pictures.push({
          URL: reader.result,
          name: file.name,
          file: file
        })
        if (device === 'sp') {
          this.setState({
            pictures: pictures
          })
        } else {
          let picturesError = this.state.picturesError ? this.state.picturesError : []
          let maxFile = this.maxFile
          if (pictures.length > maxFile && msgType === "CHARGE_TEXT") {
            picturesError.push(
              pictures.splice(maxFile, pictures.length - maxFile),
            )
          }
          this.setState(() => ({
            pictures: pictures,
            openDialog: true,
            picturesError: picturesError,
          }));
        };
      }
      reader.readAsDataURL(file);
    }
  }

  handleClose() {
    this.setState({
      pictures: [],
      openDialog: false,
      picturesError: [],
      openDropPicture: false,
      count: 0,
      messageFile: '',
      message: ''
    });

    if (this.state.chatDraft) {
      this.setState({
        message: this.state.chatDraft,
        messageFile: this.state.chatDraft
      })
    }
  }

  deletePicture(key) {
    let pictures = this.state.pictures
    pictures.splice(key, 1)
    this.setState({
      pictures: pictures
    })
  }

  deletePictureError(key) {
    let picturesError = this.state.picturesError
    picturesError.splice(key, 1)
    this.setState({
      picturesError: picturesError
    })
  }

  allowDrop(e) {
    e.preventDefault()
    this.setState({ count: this.state.count + 1 }, () => {
      if (this.state.count < 2 && !this.state.openDialog) {
        this.setState({
          openDropPicture: true
        })
      }
    });
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'copy';
  }

  dropPicture(e) {
    e.preventDefault()
    this.setState({
      openDialog: true,
      openDropPicture: false
    })
    e.stopPropagation();
    let files = e.dataTransfer.files;
    for (let index = 0; index < files.length; index++) {
      let file = files[index]
      if (!/(\.png|\.gif|\.jpg|\.jpeg)$/i.test(file.name)) {
        file = ''
        this.setState({
          errorFile: true,
          openDialog: this.state.openDialog,
        })
        this.state.openDialog ? this.setState({ count: 2 }) : this.setState({ count: 0 })
      }
      this.handleLoadImage(file)
    }
  }

  handleErrorFileClose() {
    this.setState({
      errorFile: false,
      errorShortPoint: false,
      errorSendFile: false,
      errorSendLimitFile: false
    })
  }

  showTemplates = () => {
    this.setState({ feeSendTemplate: true })
    $('.chat-form-custom-definition__data_sp').addClass('chat-form-custom-definition__data_sp-show');
    $('.hide_template_sp').removeClass('hiddenClass').addClass('hide_template_sp-show');
    $('.show_template_sp').addClass('hiddenClass');
  }

  hideTemplates = () => {
    this.setState({ feeSendTemplate: false })
    $('.hide_template_sp').removeClass('hide_template_sp-show').addClass('hiddenClass');
    $('.show_template_sp').removeClass('hiddenClass');
    $('.chat-form-custom-definition__data_sp').removeClass('chat-form-custom-definition__data_sp-show');
    $('.show_template_sp').removeClass('hiddenClass');
  }

  setSessionStorage(item, value) {
    localStorage.setItem(item, value);
  }

  getSessionStorage(item) {
    let value = localStorage.getItem(item);
    return typeof value === 'undefined' ? '' : value;
  }

  hasSessionStorage(item) {
    return typeof localStorage.getItem(item) !== 'undefined'
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  stopTyping() {
    const { socket, userId } = this.props
    const tellerId = Fetch.tellerId()
    const sendTimeUtc = moment().utc()
    const wsbody = {
      msgId: `${tellerId}&${userId}&${sendTimeUtc.format('YYYYMMDDhhmmssSSS')}`,
      fromId: tellerId,
      toId: userId,
      msgType: "STOP_TYPING"
    }
    socket.emit('stopTyping', JSON.stringify(wsbody))
    this.setState({ hasEmitTyping: false });
  }

  readMessageDownUnRead = async (userId) => {
    const { dispatch, RequestHistoryUnRead, RequestHistoryRead, ChatRequestUnRead, ChatRequestRead, ChatNumberTotal, ChatPin } = this.props;
    const tellerId = Fetch.tellerId();
    let ChatRequestUnReadOld = ChatRequestUnRead.data[userId];
    let RequestHistoryUnReadOld = RequestHistoryUnRead.data[userId];
    let chattingUnreadNumber = ChatNumberTotal.data.chattingUnreadNumber;
    let chatedUnreadNumber = ChatNumberTotal.data.chatedUnreadNumber;
    let totalChatRequestUnReadOld = 0;
    let totalRequestHistoryUnReadOld = 0;


    if (RequestHistoryUnReadOld === undefined && ChatRequestUnReadOld !== undefined && ChatRequestUnReadOld.msgType !== 'ACCEPT_CHAT') {

      totalChatRequestUnReadOld = totalChatRequestUnReadOld + ChatRequestUnReadOld.unreadMsgBadge;
      dispatch(removeItemFromList(ChatRequestUnRead.data, userId, CHAT_REQUEST_UNREAD_REFRESH));
      ChatRequestUnReadOld.unreadMsgBadge = 0;
      dispatch(addItemToList(ChatRequestRead.data, ChatRequestUnReadOld, userId, CHAT_REQUEST_READ_REFRESH));
      dispatch(ChatNumberTotalAction.fetch())
      // dispatch({type: CHAT_NUMBER_TOTAL, data: {chattingUnreadNumber: chattingUnreadNumber,
      //     chatedUnreadNumber: chatedUnreadNumber - totalRequestHistoryUnReadOld - totalChatRequestUnReadOld }});
    }

    if (ChatRequestUnReadOld === undefined && RequestHistoryUnReadOld !== undefined) {

      totalRequestHistoryUnReadOld = totalRequestHistoryUnReadOld + RequestHistoryUnReadOld.unreadMsgBadge;
      dispatch(removeItemFromList(RequestHistoryUnRead.data, userId, REQUEST_HISTORY_UNREAD_REFRESH));
      RequestHistoryUnReadOld.unreadMsgBadge = 0;

      dispatch(addItemToList(RequestHistoryRead.data, RequestHistoryUnReadOld, userId, REQUEST_HISTORY_READ_REFRESH));

      dispatch(ChatNumberTotalAction.fetch())
      // dispatch({type: CHAT_NUMBER_TOTAL, data: {chattingUnreadNumber: chattingUnreadNumber,
      //     chatedUnreadNumber: chatedUnreadNumber - totalRequestHistoryUnReadOld - totalChatRequestUnReadOld }});
    }
  };

  render() {
    const { UserInfo, Template, ChatRequest, MyProfile, userId } = this.props;
    const pictures = this.state.pictures;
    const picturesError = this.state.picturesError;
    let msgType = (this.state.fee) ? "CHARGE_TEXT" : "FREE_TEXT";
    const maxFile = msgType === "CHARGE_TEXT" ? `送信可能数：${this.maxFile}枚` : "";
    const displayPictureError = picturesError ? picturesError.map((picture, key) => {
      return (<li key={key} className="picture-item">
        <div className="content-picture">
          <div className="picture-error">
            <i className="material-icons icon-info">info</i>
            <span className="text-picture-error">ポイント不足のため 送信不可</span>
            <button className="btn-picture-item" onClick={() => this.deletePictureError(key)}> &times; </button>
          </div>
          <p className="name-picture"> {picture[0].name} </p>
        </div>
      </li>)
    }) : ''
    const picture = pictures.map((picture, key) => {
      return (<li key={key} className="picture-item">
        <div className="content-picture">
          <img src={picture.URL} height="90px" width="100%" />
          <button className="btn-picture-item" onClick={() => this.deletePicture(key)}> &times; </button>
        </div>
        <p className="name-picture"> {picture.name} </p>
      </li>)
    })
    const pictureSp = pictures.map((picture, key) => {
      return (<li key={key} className="picture-item-sp">
        <div className="content-picture">
          <img src={picture.URL} height="48px" width="100%" />
          <button className="btn-picture-item-sp" onClick={() => this.deletePicture(key)}> &times; </button>
        </div>
      </li>)
    })
    // if (!(userId && ChatRequest.loaded)) {
    //   return ''
    // }

    //残り入力可能文字数

    const pointUser = this.props.pointUser
    const sendTextPrice = - this.props.sendTextPrice
    const sendFilePrice = - this.props.sendFilePrice
    const bodyMessage = this.state.messageFile ? this.state.messageFile : this.state.message
    const textLength = pictures.length
      ? this.state.maxlength - Math.floor(sendFilePrice / sendTextPrice) * pictures.length - bodyMessage.replace(/\s/g, "").length
      : this.state.maxlength - bodyMessage.replace(/\s/g, "").length
    const textLengthSp = pictures.length
      ? this.state.maxlength - Math.floor(sendFilePrice / sendTextPrice) * pictures.length - bodyMessage.replace(/\s/g, "").length
      : this.state.maxlength - bodyMessage.replace(/\s/g, "").length
    const restlength = textLength < 0 ? 0 : textLength
    const restlengthSp = textLengthSp < 0 ? 0 : textLengthSp
    let TemplateList = ''
    if (Template.data.length) {
      TemplateList = Object.values(Template.data).map((res, i) => {
        return (
          <TemplateSelecter
            key={res.templateId}
            data={res}
            clickFunc={() => this.handleTemplateSubmit(res)}
          />
        )
      })
    }

    const FreeMessageDialogActions = [
      <FlatButton
        label="キャンセル"
        primary={true}
        onClick={e => this.handleHideDialogFreeMessage(false)}
      />,
      <FlatButton
        label="はい"
        primary={true}
        keyboardFocused={true}
        onClick={e => this.handleHideDialogFreeMessage(true)}
      />,
    ]
    return (
      <div>
        <Snackbar
          open={this.state.errorSendFile}
          message={'メッセージを送信できません。このユーザーが存在していません。'}
          autoHideDuration={2500}
          onRequestClose={e => this.handleErrorFileClose(e)}
        />
        <Snackbar
          open={this.state.errorSendLimitFile}
          message={'一度に5枚以上の画像は送信できません'}
          autoHideDuration={2500}
          onRequestClose={e => this.handleErrorFileClose(e)}
        />
        <Dialog
          title="無料メッセージ送信"
          modal={true}
          actions={FreeMessageDialogActions}
          open={this.state.freeDialog}
        >
          無料メッセージが送信されます。よろしいですか？
        </Dialog>
        <DialogUnBlock ref={dialog => this.dialogUnBlock = dialog} userData={this.props.UserInfo} />
        <div className="content layout_chat">
          {
            this.state.showLoading &&
            <ReactLoading type={'bubbles'} style={style.loadingIcon} delay={0} />
          }

          {
            !this.state.showLoading &&
            <div style={UserInfo.isBlocked ? { height: '115%' } : { height: '97%' }} className={UserInfo.isBlocked ? 'chat-list-area-wrap chat-list-area-wrap-block' : this.state.typing ? "chat-list-area-wrap user_typing" : "chat-list-area-wrap"}>
              <ChatContentMessage
                ChatLog={this.getUniqueListBy(this.state.chatlog, 'msgId')}
                ReadLog={this.state.readlog}
                UserInfo={UserInfo}
                MyProfile={MyProfile}
                loadFunc={isMobile ? () => this.loadChatHistoryMobile({ userId: this.state.userId }) : () => this.loadChatHistory({ userId: userId })}
                setSessionStorage={(item, value) => this.setSessionStorage(item, value)}
                getSessionStorage={(item) => this.getSessionStorage(item)}
                hasSessionStorage={(item) => this.hasSessionStorage(item)}
                idMessageOfflineOpened={this.state.idMessageOfflineOpened}
              />
            </div>
          }

          <div className={UserInfo.isBlocked ? "chat-box-form-hidden" : "chat-box-form"} >
            <Typing show={this.state.typing} UserInfo={UserInfo} />
            <div className="chat-form-custom" style={{ boxSizing: 'content-box' }}>
              <div className="chat-form-custom__pay">
                <label className="checkbox-toggle" htmlFor="chat_form_toggle_pay">
                  <input
                    disabled={!this.state.canToll}
                    checked={this.state.fee}
                    onChange={e => this.handleChangeFee(e)}
                    className="checkbox-toggle__input"
                    type="checkbox"
                    id="chat_form_toggle_pay" />
                  <div className="checkbox-toggle-text">
                    <div className="checkbox-toggle-text__track">
                      <div className="checkbox-toggle-text__off">{this.feeToString()}</div>
                      <div className="checkbox-toggle-text__on">{this.feeToString()}</div>
                    </div>
                    <div className="checkbox-toggle-text__thumb">
                      <div className="checkbox-toggle-text__helper"></div>
                    </div>
                  </div>
                </label>
              </div>
              <dl className="chat-form-custom-definition">
                <dt className="chat-form-custom-definition__title">
                  {/* Data-picture */}
                  <form name="sendPictures" >
                    <ReactTooltip globalEventOff='click' />
                    <Dialog
                      open={this.state.openDropPicture}
                      onClose={() => this.handleClose()}
                    >
                      <div>
                        <div id="myDropPicture" className="drop-picture" onDrop={(e) => this.dropPicture(e)} onDragOver={(e) => this.allowDrop(e)}>
                          <p className="text-drop-picture"> ファイルをドラッグ＆ドロップしてください </p>
                          <p style={style.textDropPicture}> 複数ファイル送信可能 </p>
                        </div>
                      </div>
                    </Dialog>
                    {
                      (this.state.fee && restlength === 0)
                        ?
                        <div className="btn-file">
                          <i className="material-icons" style={{ color: 'rgba(155, 155, 155, 0.7)' }}>attach_file</i>
                        </div>
                        :
                        <button className="btn-file" onClick={(e) => this.handleBtnClick(e)} onDrop={(e) => this.dropPicture(e)} onDragOver={(e) => this.allowDrop(e)} data-event-off='click' data-tip={maxFile} >
                          <i className="material-icons">attach_file</i>
                        </button>
                    }

                    <Dialog
                      open={this.state.openDialog}
                      onClose={() => this.handleClose()}
                    >
                      <h3 className="title-form-send-picture" >ファイル添付</h3>
                      <button className="btn-chat-close" onClick={() => this.handleClose()}><i className="material-icons">close</i></button>
                      <div className="pictures-wap" onDrop={(e) => this.dropPicture(e)} onDragOver={(e) => this.allowDrop(e)}>
                        <ul className="list-pictures">
                          {picture}
                          {displayPictureError}
                        </ul>
                      </div>
                      <div className="chat-form-main" style={{ minHeight: 140 }}>
                        <div className="chat-form-wrap-picture">
                          <div className="chat-form">
                            <textarea className="chat-form__input" rows="3"
                              placeholder={this.submitToString()}
                              value={this.state.messageFile}
                              onKeyDown={e => this.handleSubmitFile(e)}
                              onChange={e => this.handleChange(e, "sendMessageFile")}
                            />
                          </div>
                        </div>
                        <div>
                          <button onClick={() => this.sendMessage('pc', '', 'sendMessageFile')} className="btn-raised color_default fix-btn">
                            送信
                          </button>
                          <RestWordFileCount show={this.state.fee} length={restlength} device='' />
                        </div>
                      </div>
                    </Dialog>
                    <Snackbar
                      open={this.state.errorFile}
                      message={'ご利用の形式のファイルは送信できません。'}
                      autoHideDuration={2500}
                      onRequestClose={e => this.handleErrorFileClose(e)}
                    />
                    <Snackbar
                      open={this.state.errorShortPoint}
                      message={'お客様が画像を閲覧できるポイントを所持していません。'}
                      autoHideDuration={2500}
                      onRequestClose={e => this.handleErrorFileClose(e)}
                    />
                    <input multiple type="file" name="filePictures" accept="image/*" style={{ 'display': 'none' }} onChange={(e) => this.handleDrop(e)} />
                  </form>
                </dt>
                <dd className="chat-form-custom-definition__data container-template-scroll" style={{ maxHeight: '33px' }}>
                  <div className="template-scroll" style={style.templateScroll}>
                    <ul className="list list-template-scroll">
                      {TemplateList}
                    </ul>
                  </div>
                </dd>
              </dl>
              <RestWordCount show={this.state.fee} length={restlength} />
            </div>

            <div className="chat-form-main">
              <div className="chat-form-wrap">
                <div className="chat-form">
                  <textarea className="chat-form__input" rows="3"
                    placeholder={this.submitToString()}
                    value={this.state.message}
                    onKeyDown={e => this.handleSubmit(e)}
                    onChange={e => this.handleChange(e)}
                    onBlur={e => this.stopTyping()}
                  />
                </div>
              </div>
              <div className="chat-form-btn">
                <Checkbox
                  id="checkbox-1"
                  label="Enterで送信"
                  onCheck={e => this.handleChangeSubmit(e)}
                  checked={this.state.submitEnter}
                  className="chat-form-btn__checkbox"
                />
                <div className="btn-wrap">
                  <button className="btn-raised color_default spread_width" onClick={() => this.sendMessage('pc')}>送信</button>
                </div>
              </div>
            </div>
            <div className="chat-form-custom-definition__data_sp">
              <ul className="list">
                {TemplateList}
              </ul>
            </div>
            <div className="chat-form-main_sp">
              <div className="chat-form-wrap">
                <div className="chat-form">
                  <textarea className="chat-form__input" rows="3"
                    placeholder="メッセージを入力してください"
                    value={this.state.messageFile}
                    onKeyDown={e => this.handleSubmitFile(e)}
                    onChange={e => this.handleChange(e, 'sendMessageFile')}
                  />
                </div>
              </div>
              <div className="chat-form-btn">
                <div className="btn-wrap">
                  <button onClick={() => this.sendMessage('sp', '', 'sendMessageFile')} style={{ width: 40, height: 40, background: 'none', outline: 0, border: 0 }} >
                    <img src="/img/ic-send-message.png" style={{ width: 40 }} />
                  </button>
                </div>
              </div>
            </div>

            <div className="send-pictures-sp">
              <ul className="list-pictures-sp">
                {pictureSp}
              </ul>
            </div>

            <div className="chat-form-custom_sp" style={{ boxSizing: 'content-box' }}>
              <div className="chat-form-custom__pay">
                <label className="checkbox-toggle" htmlFor="chat_form_toggle_pay">
                  <input
                    disabled={!this.state.canToll}
                    checked={this.state.feeSendTemplate ? !this.state.feeSendTemplate : this.state.fee}
                    onChange={this.state.feeSendTemplate ? null : e => this.handleChangeFee(e)}
                    className="checkbox-toggle__input"
                    type="checkbox"
                    id="chat_form_toggle_pay" />
                  <div className="checkbox-toggle-text">
                    <div className="checkbox-toggle-text__track">
                      <div className="checkbox-toggle-text__off">{this.feeToString()}</div>
                      <div className="checkbox-toggle-text__on">{this.feeToString()}</div>
                    </div>
                    <div className="checkbox-toggle-text__thumb">
                      <div className="checkbox-toggle-text__helper"></div>
                    </div>
                  </div>
                </label>
              </div>
              <dl className="chat-form-custom-definition">
                <dt className="chat-form-custom-definition__title">テンプレ</dt>
                <dd className="chat-form-custom-definition__data">
                  <ul className="list">
                    {TemplateList}
                  </ul>
                </dd>
              </dl>
              <div className="show_template_sp" onClick={this.showTemplates} >
                <button className="btn_show_template" >テンプレ</button>
              </div>
              <div className="hide_template_sp" onClick={this.hideTemplates} >
                <button className="btn_hide_template" >×テンプレを閉じる</button>
              </div>
              {/* mobile */}
              <form name="sendPicturesSp" style={{ float: 'left' }}>
                <button className="btn-file-sp" onClick={(e) => this.handleBtnClick(e, 'sp')}  >
                  <i className="material-icons">attach_file</i>
                </button>
                <input multiple type="file" name="filePicturesSp" accept="image/*" style={{ display: 'none' }} onChange={(e) => this.handleDrop(e, 'sp')} />
                <Snackbar
                  open={this.state.errorShortPoint}
                  message={'お客様が画像を閲覧できるポイントを所持していません。'}
                  autoHideDuration={2500}
                  onRequestClose={e => this.handleErrorFileClose(e)}
                />
              </form>
              <RestWordFileCount show={this.state.feeSendTemplate ? '' : this.state.fee} length={restlengthSp} device='sp' />
            </div>
          </div>
        </div>
        <DialogMessageOffline show={this.state.isShowDialogMessageOffline} submitted={this.submittedDialogMessageOffline} close={this.close} />
        {
          this.state.showDialogConfirmSendTemplate
            ? <DialogConfirmSendTemplate
              show
              close={this.close}
              data={this.state.dataTemplate}
              sendMessage={(device, templateMsg, typeSend, submitted) => this.sendMessage(device, templateMsg, typeSend, submitted)}
            />
            : null
        }
        {/* <DialogNotifi ref='dialog' /> */}
      </div>
    )
  }
}

/**
 * @param {number} length 入力可能残り文字数
 * @param {boolean} show 表示:true
 */
const RestWordCount = ({ show, length }) => {
  if (!show) {
    return ''
  }
  const notice = length ? (
    ''
  ) : (
    <dd className="chat-form-custom-count__notice">残文字数が0です</dd>
  )
  return (
    <div>
      <dl className="chat-form-custom-count">
        <dt className="chat-form-custom-count__title">残文字数</dt>
        <dd
          className={
            !length
              ? 'chat-form-custom-count__data color_notice'
              : 'chat-form-custom-count__data'
          }
        >
          <strong>{length}</strong>文字
        </dd>
        {notice}
      </dl>
    </div>
  )
}

const RestWordFileCount = ({ show, length, device }) => {
  if (!show) {
    return ''
  }
  const notice = length ? (
    ''
  ) : (
    <span className="chat-form-custom-file-count__notice">
      残文字数が0です
    </span>
  )
  return (
    <div
      className={
        !device ? 'chat-form-custom-file' : 'chat-form-custom-file-sp'
      }
    >
      <span className="chat-form-custom-file-count">残文字数 :</span>
      <span
        className={
          length < 1
            ? 'chat-form-custom-file-count__data color_notice'
            : 'chat-form-custom-file-count__data'
        }
      >
        <strong>{length}</strong>文字
      </span>
      {notice}
    </div>
  )
}

const Typing = ({ show, UserInfo }) => {
  if (!show) {
    return ''
  }
  return (
    <div className="chat-box-form__typing">
      {UserInfo.userName}さんが入力中…
    </div>
  )
}

const TemplateSelecter = ({ data, clickFunc }) => {
  return (
    <li className="list__item">
      <button
        className="btn btn-round"
        key={data.templateId}
        onClick={(e) => clickFunc()}
      >
        {data.templateTitle}
      </button>
    </li>
  )
}

const mapStateToProps = (state) => {
  return {
    MyProfile: state.MyProfile,
    Template: state.Template,
    ChatRequest: state.ChatRequest,
    ChatRequestUnRead: state.ChatRequestUnRead,
    ChatRequestRead: state.ChatRequestRead,
    RequestHistoryUnRead: state.RequestHistoryUnRead,
    RequestHistoryRead: state.RequestHistoryRead,
    ChatNumberTotal: state.ChatNumberTotal,
    ChatDraftData: state.ChatDraft,
    ChatPin: state.RequestHistoryPin,
    UserInfoData: state.UserInfo
  }
}

export default connect(mapStateToProps)(ChatContent)

const style = {
  loadingIcon: {
    margin: 'auto',
    position: 'relative',
    top: '40%',
    fill: 'rgb(17,173,157)',
    width: 66,
    height: 66,
  },
  textDropPicture: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 15,
  },
  templateScroll: {
    width: `${window.innerWidth - 835}px`,
  },
}
