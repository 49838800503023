/**
 * HOME画面のメインの表示部分
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { HomeNews } from './HomeNews'
import HomeNextLogin from './HomeNextLogin'
import HomeBanner from './HomeBanner/index'
import HomeStaffBlog from './HomeStaffBlog'
import HomeMission from './HomeMission'
import * as NewsActions from '../actions/NewsActions'
import * as StaffBlogActions from '../actions/StaffBlogActions'
import * as MissionActions from '../actions/MissionActions'
import * as ContactActions from '../actions/ContactActions'
import * as Fetch from '../util/Fetch'
import HomeTellerIntroductionTeller from './homeTellerIntroductionTeller/HomeTellerIntroductionTeller'
import { FireMsg } from '../util/Firebase'
import * as MyProfileActions from '../actions/MyProfileActions'
import DialogFirstLogin from './DialogFirstLogin'
import {
	FlexDiv,
	StyledContainerMainContent,
} from './Home/Styled/StyledHomeComponents'
import { getPermissionForMicrophone } from '../helper/helpFunction'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import * as CategoryBlogActions from './../actions/CategoryBlogAction'
import { isMobile } from 'react-device-detect'

class Home extends Component {
	componentWillMount() {
		const { dispatch, News, StaffBlog, activeItem } = this.props
		activeItem(1)

		if (!News.loaded) {
			dispatch(NewsActions.fetchData(0, 10))
		}

		if (!StaffBlog.loaded) {
			dispatch(StaffBlogActions.fetchData(1, 10))
		}

		{ isMobile ? dispatch(MissionActions.getMissions()) : dispatch(MissionActions.getMissions_v2()) }
	}

	createUrlQuery() {
		const { MyProfile } = this.props
		let params = [
			`userId=${MyProfile.data.fortuneTellerId}`,
			'size=99',
			`page=0`,
			`readflg=0`,
		]
		return `?${params.join('&')}`
	}

	componentDidMount() {
		const { dispatch, MyProfile } = this.props
		dispatch(CategoryBlogActions.getCategoryBlog())
		if (!MyProfile.loaded) {
			dispatch(MyProfileActions.fetch('profile'))
		}

		dispatch(ContactActions.getContactUnread(this.createUrlQuery()))

		if (FireMsg) {
			FireMsg.onMessage((payload) => {
				console.log('%c [STELLA] payload: ', 'color: red', payload)
				const pushData = JSON.parse(payload.data.data)
				const pushAlert = pushData.alert
				if (pushAlert['loc-key'] === 'push_denied_teller_avatar') {
					dispatch(MyProfileActions.fetch('profile'))
				} else if (pushAlert['loc-key'] === 'push_like_teller_answer') {
					localStorage.setItem(
						'lastNotificationLikeAnswer',
						pushData.data.questionId
					)
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				} else if (pushAlert['loc-key'] === 'push_pick_best_answer') {
					const numberUnreadBestAnswer = localStorage.getItem(
						'numberUnreadBestAnswer'
					)
						? parseInt(
							localStorage.getItem('numberUnreadBestAnswer')
						)
						: 0
					localStorage.setItem(
						'numberUnreadBestAnswer',
						numberUnreadBestAnswer + 1
					)
					localStorage.setItem('showPopUpBestAnswer', 'true')
					dispatch(
						AnswerManagementActions.add(
							numberUnreadBestAnswer + 1,
							true
						)
					)
				} else if (pushAlert['loc-key'] === 'push_teller_introduce_user') {
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				}
			})
		}
		if (global.showDialogFirstLogin) {
			global.showDialogFirstLogin = false
			this.dialogFirstLogin.openDialog()
		}
	}

	noticeStatusUploadAvatar = () => {
		const { MyProfile } = this.props
		const reasonDeny = MyProfile.data.reasonDenyAvatarType
		let element
		if (reasonDeny !== 0) {
			element = (
				<Link className="bar take_notice" to="/home/setting">
					<i className="material-icons bar__icons">info_outline</i>
					プロフール画像が否認されました。こちらから再登録してください。
				</Link>
			)
		}
		return element
	}

	noticeContactUnread = () => {
		if (
			!this.props.Contact.unread.list ||
			this.props.Contact.unread.total === 0
		) {
			return
		}

		const idDetail = this.props.Contact.unread.list[0].id

		return (
			<Link
				className="bar take_notice"
				to={`/home/contact/detail/${idDetail}`}
			>
				<i className="material-icons bar__icons">info_outline</i>
				ステラ運営事務局からメールが届いています。
			</Link>
		)
	}

	render() {
		const { News, MyProfile, StaffBlog, Mission } = this.props
		return (
			<StyledContainerMainContent>
				{this.noticeContactUnread()}
				{Fetch.isAgeVerify(MyProfile.data.verifyAge) ? (
					''
				) : (
					<Link
						className="bar take_notice"
						to="/home/setting/ageauth"
					>
						<i className="material-icons bar__icons">
							info_outline
						</i>
						年齢認証がお済みでないため、お仕事を開始できません。こちらから年齢認証を行ってください。
					</Link>
				)}
				{this.noticeStatusUploadAvatar()}
				<HomeBanner />
				<HomeNextLogin />
				<HomeTellerIntroductionTeller />
				<HomeNews data={News} />
				<FlexDiv>
					<HomeStaffBlog data={StaffBlog} />
					<HomeMission data={Mission} />
				</FlexDiv>
				<DialogFirstLogin
					ref={(dialog) => (this.dialogFirstLogin = dialog)}
					history={this.props.history}
				/>
			</StyledContainerMainContent>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		StaffBlog: state.StaffBlog,
		News: state.News,
		Mission: state.Mission,
		Contact: state.Contact,
	}
}

export default withRouter(connect(mapStateToProps)(Home))
